import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../components/DeleteModal";
import ModalContainer from "../../../components/ModalContainer";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { deleteIcon, editIcon } from "../../../config/variables";

const BookingsTable = ({ tableHeaders, data, noDataMessage, setRefetchData, service }: any) => {
    const navigate = useNavigate()
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const { t, i18n } = useTranslation()
    return (
        <div className={`table_container`}>
            <div className="table_header">
                {tableHeaders?.map((header: any, index: number) => (
                    <span className={`head ${header.customClass}`} key={index}>{header.label}</span>
                ))}
            </div>
            <div className={`table_data ${(!data || data?.length == 0) && "no_data"}`}>
                {data?.length > 0 ?
                    <>
                        {data?.map((item: any, index: number) => (
                            <>
                                <div className="item clickable has_logo" key={index}
                                    onClick={() => {
                                        const contentContainer = document.querySelector(".layout_content");
                                        contentContainer?.scrollTo({ top: 0, behavior: "smooth" });
                                        navigate(`/applications/${item.id}`)
                                    }}
                                >
                                    <div className="column pro_id_col">{item?.id}</div>

                                    <div className="column registration_date_col text-center" >{item?.vehicle}</div>
                                    {!service && <div className="column capitalize text-center">{item?.distributor?.[i18n.language]}</div>}
                                    <div className="column registration_date_col text-center">{item?.service_provider?.[i18n.language]}</div>
                                    <div className="column registration_date_col text-center">{item?.step?.[i18n.language]}</div>
                                    <div className="column registration_date_col text-center">{item?.status}</div>
                                    <div className="column registration_date_col text-center">{item?.owner?.[i18n.language]}</div>


                                    <div className="column actions actions_col ">
                                        <div className="action_btn edit" onClick={(e) => { e.stopPropagation(); navigate(`/products/create-product/${item.id}`) }}>
                                            {editIcon}                                     </div>
                                        <div className="action_btn delete" onClick={(e) => { e.stopPropagation(); setDeleteModalOpen(item.id) }}>
                                            {deleteIcon}                                      </div>
                                    </div>
                                </div>
                            </>
                        ))}
                    </>
                    :
                    <h6>{noDataMessage}</h6>
                }
            </div>
            {deleteModalOpen && (
                <ModalContainer small>
                    <DeleteModal id={deleteModalOpen} setModal={setDeleteModalOpen} setRefetchData={setRefetchData} route={"/products"} successMsg={t("deletedServiceProvider")} warningMsg={t("deleteConfirmationMessageServiceProvider")} />
                </ModalContainer>
            )}
        </div>
    );
}

export default BookingsTable;