import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { generalGet } from '../../../API/api';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setBreadCrumbsData } from '../../../store/redux/breadCrumbsData';
import { useTranslation } from 'react-i18next';
import { INationality, IWorkSector } from '../../../modules/products/types/interfaces';
import CreateProductForm from '../../../modules/products/components/productForm/CreateProductForm';
import FormSkeleton from '../../../components/loaders/FormSkeleton';

const CreateProduct = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [productData, setProductData] = useState<any | null>(null);
  const [workSectors, setWorkSectors] = useState<any>(null);
  const [nationalities, setNationalities] = useState<any>(null);
  const { data, isSuccess, isLoading } = useQuery({
    queryKey: [id],
    queryFn: () => id && generalGet(`/products/${id}`),
    refetchOnWindowFocus: false,
    gcTime: 0,
    enabled: !!id
  });

  const {
    data: workSectorsData,
    isSuccess: workSectorsSuccess,
    isLoading: workSectorsLoading
  } = useQuery({
    queryKey: ['work sectors', i18n.language],
    queryFn: () => generalGet(`/work-sectors`),
    refetchOnWindowFocus: false
  });

  const {
    data: nationalitiesData,
    isSuccess: nationalitiesSuccess,
    isLoading: nationalitiesLoading
  } = useQuery({
    queryKey: ['nationalities', i18n.language],
    queryFn: () => generalGet(`/nationalities`),
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    const reqData = workSectorsData?.data.data;
    workSectorsSuccess &&
      setWorkSectors([
        ...reqData?.map((sector: IWorkSector) => {
          return { label: sector.name, value: sector.id.toString() };
        })
      ]);
  }, [workSectorsSuccess, workSectorsData]);

  useEffect(() => {
    const reqData = nationalitiesData?.data.data;
    nationalitiesSuccess &&
      setNationalities([
        ...reqData?.map((nationality: INationality) => {
          return { label: nationality.name, value: nationality.id.toString() };
        })
      ]);
  }, [nationalitiesSuccess, nationalitiesData]);

  useEffect(() => {
    if (isSuccess && id && data) {
      const reqData = data?.data?.data;
      setProductData(() => {
        reqData?.conditions?.map((item: any) => {
          item.work_sectors = item.work_sectors.map((sector: IWorkSector) => {
            return sector.id;
          });
          item.nationalities = item.nationalities.map((nationality: INationality) => {
            return nationality.id;
          });
          return item;
        });
        return reqData;
      });
      dispatch(
        setBreadCrumbsData({
          links: [
            { label: t('links.products'), path: '/products' },
            { label: reqData?.service_provider?.name[i18n.language], path: `/products/${id}` }
          ],
          page_title: t('links.create_product')
        })
      );
    } else if (!id) {
      dispatch(
        setBreadCrumbsData({
          links: [
            { label: t('links.products'), path: '/products' },
            { label: t('links.create_product'), path: `/products/create-product` }
          ],
          page_title: t('create_product')
        })
      );
    }
  }, [isSuccess, data, id, t, i18n]);

  //Reset data when navigating from edit product to create product
  useEffect(() => {
    if (!id) {
      setProductData(null);
    }
  }, [id]);

  if (isLoading || nationalitiesLoading)
    return (
      <div>
        <FormSkeleton featuredSections={1} noImage />
      </div>
    );
  return (
    <div className="page_content product_details_page">
      <CreateProductForm productData={productData} workSectors={workSectors} nationalities={nationalities} id={id} />
    </div>
  );
};
export default CreateProduct;
