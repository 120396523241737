import { useDispatch } from 'react-redux';
import { setBreadCrumbsData } from '../../../store/redux/breadCrumbsData';
import { useContext, useEffect, useState, useTransition } from 'react';
import { useQuery } from '@tanstack/react-query';
import { generalGet } from '../../../API/api';
import TableSkeleton from '../../../components/loaders/TableSkeleton';
import ServicesTableContainer from '../../../modules/serviceProvider/components/ServicesTableContainer';
import ListHeader from '../../../components/ListHeader';
import SearchInputField from '../../../components/SearchInputField';
import { handleSorting, hasPermission, searchFilterLogic } from '../../../utils/HelperFunctions';
import { useTranslation } from 'react-i18next';
import { authContext } from '../../../store/context/authContext';
import { customStyles, paginationControlStyles, sortStyles } from '../../../utils/SelectStyles';
import Select, { GroupBase, OptionsOrGroups } from 'react-select';
import { TOptions } from 'i18next';
import { IDistributor } from '../../../modules/distributors/types/interfaces';
import DistributorTableContainer from '../../../modules/distributors/components/DistributorTableContainer';
import { Pagination, Stack } from '@mui/material';
import { IMeta } from '../../../modules/applications/types/interfaces';

const Distributors = () => {
  const { catchError } = useContext(authContext);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  dispatch(
    setBreadCrumbsData({
      links: [{ label: t('links.distributors'), path: '/distributors' }],
      page_title: t('links.distributors')
    })
  );
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [isPending, startTransition] = useTransition();
  const [refetch, setRefetch] = useState(false);
  const [shownList, setShownList] = useState<IDistributor[]>([]);
  const [distributors, setDistributors] = useState<IDistributor[]>([]);
  const [metaData, setMetaData] = useState<IMeta>();
  const [currentRoute, setCurrentRoute] = useState('/distributors?page=1&limit=25');
  const [perPageOptions, setPerPageOptions] = useState([
    { value: '10', label: '10' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' }
  ]);
  const [selectedPerPage, setSelectedPerPage] = useState({ value: '25', label: '25' });
  const { data, isSuccess, isLoading, error } = useQuery({
    queryKey: ['distributors', refetch, i18n, currentRoute],
    queryFn: () => generalGet(currentRoute),
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    error && catchError(error);
  }, [error]);

  useEffect(() => {
    const reqData = data?.data.data;
    isSuccess && setDistributors(reqData?.map((item: IDistributor) => ({ ...item, distributorName: item.name[i18n.language as keyof typeof item.name] })));
    setMetaData(data?.data?.meta);
  }, [isSuccess, data]);

  const tableHeaders = [
    { label: t('name'), customClass: 'logo' },
    { label: t('address') },
    { label: t('phone') },
    { label: t('status'), customClass: 'status_col' },
    hasPermission(['distributors.edit']) && { label: t('actions'), customClass: 'actions_col' }
  ];

  useEffect(() => {
    setSelectedOption(null);
    setShownList(distributors);
    // if (searchInput) {
    //   const results = searchFilterLogic({ searchInput: searchInput, listOfData: distributors, keys: ['id', 'distributorName'] });
    //   startTransition(() => {
    //     setShownList(results);
    //   });
    // }
  }, [distributors, searchInput]);

  const options: OptionsOrGroups<TOptions, GroupBase<TOptions>> = [
    {
      label: t('sort_by_status'),
      value: 'is_active'
    },
    {
      label: t('created_at'),
      value: 'id'
    }
  ];

  const applySorting = (e: any) => {
    setSelectedOption(e);
    handleSorting({ setState: setShownList, list: shownList, value: e.value, startTransition });
  };

  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setCurrentRoute(`/distributors?page=${value}&limit=${selectedPerPage.value}&q=${searchInput}`);
  };

  useEffect(() => {
    setCurrentRoute(`/distributors?page=1&limit=${selectedPerPage.value}&q=${searchInput}`);
  }, [selectedPerPage, searchInput]);

  if (isLoading && !searchInput) return <TableSkeleton columns={6} withoutButton />;

  return (
    <div className="distributors-page-container">
      <ListHeader>
        <div className="filter_container">
          <SearchInputField placeholder={t('search_by_id_Name')} setSearchInput={setSearchInput} searchInput={searchInput} />
          <Select
            value={selectedOption}
            placeholder={t('sort_by')}
            isSearchable={false}
            isClearable={false}
            isMulti={false}
            options={options}
            className={`select-drop-down `}
            noOptionsMessage={() => t('no_options')}
            classNamePrefix="react-select"
            styles={sortStyles as any}
            onChange={applySorting}
          />
        </div>
      </ListHeader>
      <DistributorTableContainer tableHeaders={tableHeaders} data={shownList} noDataMessage={t('No_distributor_found')} setRefetchData={setRefetch} lessColumns />
      {metaData && (
        <Stack spacing={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '1rem' }}>
          <div className="" style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            {t('rowsPerPage')}{' '}
            <Select
              value={selectedPerPage}
              placeholder={t('perPage')}
              isSearchable={false}
              isClearable={false}
              isMulti={false}
              options={perPageOptions}
              className={`select-drop-down `}
              noOptionsMessage={() => t('no_options')}
              classNamePrefix="react-select"
              styles={{ ...sortStyles, ...paginationControlStyles }}
              onChange={(e) => {
                e && setSelectedPerPage(e);
                setCurrentRoute(`/distributors?page=${metaData?.current_page}&limit=${e?.value}`);
              }}
            />
          </div>
          {Number(metaData?.last_page) > 1 && <Pagination count={Number(metaData?.last_page)} page={page} onChange={handleChange} />}{' '}
        </Stack>
      )}
    </div>
  );
};

export default Distributors;
