import { useContext, useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import "react-phone-number-input/style.css";
import { toast } from "react-toastify";
import FieldWrapper from "../../../components/formInputs/FieldWrapper";
import Button from "../../../components/buttons/Button";
import { generalCreate } from "../../../API/api";
import { authContext } from "../../../store/context/authContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SectionHeader from "../../../components/SectionHeader";
import { scrollToError } from "../../../utils/HelperFunctions";
import { permissionNames } from "../../../config/FixedData";
import { useSelector } from "react-redux";

const CreateRoleForm = ({ id, roleData }: any) => {
    const [loading, setLoading] = useState(false);
    const formRef = useRef(null)
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { catchError } = useContext(authContext)
    const { permissions } = useSelector((store: any) => store.rolesData)
    const [permissionsValues, setPermissionsValues] = useState<string[]>([])

    const validationSchema = Yup.object({
        name: Yup.string()
            .required(t("required"))
            .matches(/^[^ ]\S*/, t("remove_space")),
    });

    useEffect(() => {
        if (roleData && id) {
            setPermissionsValues(roleData?.permissions?.map(((item: { name: string }) => item.name)))
        }
    }
        , [id, roleData])


    return (
        <div className={'form_section'} ref={formRef}>
            <Formik
                enableReinitialize
                validateOnMount
                validationSchema={validationSchema}
                initialValues={{
                    name: roleData?.name || "",
                }}
                onSubmit={(values) => {
                    const permissions = permissionsValues.map((item: string) => ({ name: item }))
                    setLoading(true);
                    generalCreate({
                        route: id ? `admin/roles/${id}/permissions` : "/admin/roles",
                        values: id ? { permissions, _method: "PUT" } : { ...values, permissions },
                    }).then((res) => {
                        setLoading(false)
                        toast.success(`${t("successfully")}`)
                        navigate("/roles")
                    }
                    ).catch(error => {
                        catchError(error, setLoading)
                    })
                }}
            >
                {(formik) => (
                    <>
                        <Form>
                            <SectionHeader title="Role Name">
                                <div className="form_inputs">
                                    <div className="inputs_group">
                                        <FieldWrapper
                                            title={t("name")}
                                            inputName={"name"}
                                            inputPlaceholder={t("name")}
                                            inputError={formik.errors.name as string}
                                            inputTouched={formik.touched.name as boolean}
                                            input
                                            disabled={id}
                                        />
                                        <FieldWrapper noPadding />
                                    </div>
                                </div>
                            </SectionHeader>
                            <SectionHeader customStyle="last" title="Role Permissions">
                                <div className="permissions-data">
                                    {permissionNames?.map((item: any, i: number) => (
                                        <FieldWrapper title={item} key={i}>
                                            <div className="data">

                                                {permissions?.map((name: any, index: number) => (
                                                    name?.includes(item) &&
                                                    <div className="checkboxes_wrapper" key={index}>
                                                        <label className="pointer">
                                                            <input
                                                                id={`${name}`}
                                                                type="checkbox"
                                                                checked={permissionsValues?.includes(name)}
                                                                onChange={(e: any) => { permissionsValues?.includes(name) ? setPermissionsValues(permissionsValues.filter(i => i != name)) : setPermissionsValues(prev => [...prev, name]) }}
                                                            />
                                                            {name}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </FieldWrapper>
                                    ))}
                                </div>
                            </SectionHeader>
                            <div className="form_button reverse">
                                <Button
                                    type={loading ? "button" : "submit"}
                                    loading={loading}
                                    onClick={() => { scrollToError(!formik.isValid, formRef) }}
                                >
                                    <span className="bold">{t("save")}</span>
                                </Button>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>

        </div >
    );
};

export default CreateRoleForm;
