import { useDispatch } from 'react-redux';
import { setBreadCrumbsData } from '../../../store/redux/breadCrumbsData';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, Fragment } from 'react';
import { INotification, INotificationsCategory } from '../../../modules/notifications/types/interfaces';
import moment from 'moment';
import NotificationsList from '../../../modules/notifications/components/NotificationsList';
import NotificationsHeader from './NotificationsHeader';
import { generalGet, generalPut } from '../../../API/api';
import { useQuery } from '@tanstack/react-query';
import NotificationsSkeleton from '../../../modules/notifications/components/NotificationsSkeleton';
import { IMeta } from '../../../modules/applications/types/interfaces';
import { Pagination, Stack } from '@mui/material';
import Select from 'react-select';
import { paginationControlStyles, sortStyles } from '../../../utils/SelectStyles';
import { getFilterQuery } from '../../../utils/HelperFunctions';
import { setReadNotifications, setUnReadNotificationsCount } from '../../../store/redux/notificationsData';

export default function Notifications() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  dispatch(
    setBreadCrumbsData({
      links: [{ label: t('notifications'), path: '/notifications' }],
      page_title: t('notifications')
    })
  );
  const [notifications, setNotifications] = useState([]);
  const [metaData, setMetaData] = useState<IMeta>();
  const [currentRoute, setCurrentRoute] = useState('/notifications?page=1&limit=10');
  const [perPageOptions] = useState([
    { value: '10', label: '10' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' }
  ]);
  const [selectedPerPage, setSelectedPerPage] = useState({ value: '10', label: '10' });
  const [page, setPage] = useState(1);
  const [firstLoaderDone, setFirstLoaderDone] = useState(false);

  const [filters, setFilters] = useState<{is_seen: string}>({is_seen: ""})

  const { data, isSuccess, isLoading } = useQuery({
    queryKey: ['notifications', i18n, currentRoute, filters],
    queryFn: () => generalGet(`${currentRoute}&${getFilterQuery(filters)}`),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const reqData = data?.data?.data?.notifications;
    isSuccess && setNotifications(reqData);
    isSuccess && setMetaData(data.data.meta);
    isSuccess && setFirstLoaderDone(true);
  }, [data]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setCurrentRoute(`/notifications?page=${value}&limit=${selectedPerPage.value}`);
  };

  const [shownData, setShownData] = useState<INotificationsCategory[]>([]);

  useEffect(() => {
    if (isLoading || !notifications) return;
    const today = moment().format('YYYY-MM-DD');
    const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');

    const dates: { [key: string]: INotification[] } = {};
    notifications?.forEach((notification: INotification) => {
      const date = new Date(notification.created_at).toDateString();
      if (!dates[date]) {
        dates[date] = [];
      }
      dates[date].push(notification);
    });
    const finalData: INotificationsCategory[] = [];
    for (let key in dates) {
      const dateFormatted = moment(key).format('YYYY-MM-DD');
      const currDate = dateFormatted === today ? t('today') : dateFormatted === yesterday ? t('yesterday') : moment(dateFormatted).format('dddd D MMMM');
      finalData.push({ date: currDate, items: dates[key] });
    }
    setShownData(finalData);
  }, [notifications, isLoading]);

  const [markAllLoading, setMarkAllLoading] = useState(false);

  function handleReadAllMutation() {
    setMarkAllLoading(true);
    generalPut(`/notifications/mark-all-as-read`, {})
      .then(() => {
        dispatch(setReadNotifications((notifications as INotification[]).map(ele => ele.id)));
        dispatch(setUnReadNotificationsCount(0));
      })
      .finally(() => {
        setMarkAllLoading(false);
      });
  }

  if (isLoading && !firstLoaderDone) return <NotificationsSkeleton />;

  return (
    <div className="notifications">
      <NotificationsHeader setFilters={setFilters} handleReadAllMutation={handleReadAllMutation} markAllLoading={markAllLoading} setMarkAllLoading={setMarkAllLoading}/>
      {firstLoaderDone && isLoading ? (
        <NotificationsSkeleton noHeader/>
      ) : (
        <Fragment>
          <NotificationsList notifications={shownData} />
          {metaData && !!shownData?.length && (
            <Stack spacing={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '1rem' }}>
              <div className="" style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                {t('rowsPerPage')}{' '}
                <Select
                  value={selectedPerPage}
                  placeholder={t('perPage')}
                  isSearchable={false}
                  isClearable={false}
                  isMulti={false}
                  options={perPageOptions}
                  className={`select-drop-down `}
                  noOptionsMessage={() => t('no_options')}
                  classNamePrefix="react-select"
                  styles={{ ...sortStyles, ...paginationControlStyles }}
                  onChange={(e) => {
                    e && setSelectedPerPage(e);
                    setCurrentRoute(`/notifications?page=${metaData?.current_page}&limit=${e?.value}`);
                  }}
                />
              </div>
              {Number(metaData?.last_page) > 1 && <Pagination count={Number(metaData?.last_page)} page={page} onChange={handleChange} />}{' '}
            </Stack>
          )}
        </Fragment>
      )}
    </div>
  );
}
