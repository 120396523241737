
import { Link, useLocation } from "react-router-dom";
import { IMenuLinks } from "../../../types/Interfaces";


interface IProps {
    links: {
        header: string,
        headerIcon: JSX.Element[],
        // baseRoute: string
        nestedLinks:
        {
            label: string,
            link: string,
            // icon: JSX.Element[],
        }
    }
}
const SingleNavLinks = ({ links }: IProps) => {
    const { pathname } = useLocation()
  const isActive = links?.nestedLinks?.link === pathname;
  // const isActive = pathname?.includes(links?.nestedLinks?.link);

  return ( 
      <li className={`toggler`} >
        <Link to={links?.nestedLinks?.link}>
          <div className={`toggler_header ${(isActive) && "active"}`} >
            {links?.headerIcon}
            <span className="label">{links?.header}</span>
          </div> 
        </Link>
      </li> 
  );
}

export default SingleNavLinks;