import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setBreadCrumbsData } from '../../../store/redux/breadCrumbsData';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { generalGet } from '../../../API/api';
import { useContext, useEffect, useState } from 'react';
import { authContext } from '../../../store/context/authContext';
import BasicInfoSkeleton from '../../../components/loaders/BasicInfoSkeleton';
import CommonHighlights from '../../../modules/serviceProvider/components/CommonHighlights';
import ListHeader from '../../../components/ListHeader';
import Button from '../../../components/buttons/Button';
import TableSkeleton from '../../../components/loaders/TableSkeleton';
import OffersTableContainer from '../../../modules/products/components/OffersTableContainer';
import CasesTable from '../../../modules/products/components/CasesTable';
import BalloonTable from '../../../modules/products/components/BalloonTable';
import { hasPermission } from '../../../utils/HelperFunctions';
import { useMediaQuery } from 'react-responsive';

const ProductView = () => {
  const { id } = useParams();
  const { catchError } = useContext(authContext);
  const { t, i18n } = useTranslation();
  const [productDetails, setProductDetails] = useState<any>();
  const [offersDetails, setOffersDetails] = useState<any>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [refetch, setRefetch] = useState(false);
  const [profitRateHighlights, setProfitRateHighlights] = useState([]);
  const [activeOffers, setActiveOffers] = useState([]);
  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });

  const { data, isSuccess, isLoading, error } = useQuery({
    queryKey: ['Product Details', id, i18n.language, refetch],
    queryFn: () => generalGet(`/products/${id}`),
    refetchOnWindowFocus: false
  });

  const {
    data: offersData,
    isSuccess: offersSuccess,
    isLoading: offersLoading,
    error: offersError
  } = useQuery({
    queryKey: ['Product offers', id, i18n.language, refetch],
    queryFn: () => hasPermission(['offers.show']) && generalGet(`/products/${id}/offers`),
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    const reqData = data?.data.data;
    if (isSuccess) {
      setProductDetails(reqData);
      setProfitRateHighlights(
        reqData?.profit_rate?.map((item: any, index: number) => {
          const highlight = { title: `${t('year')} ${index + 1}`, number: item + '%' };
          return highlight;
        })
      );
      dispatch(
        setBreadCrumbsData({
          links: [
            { label: t('products'), path: '/products' },
            { label: reqData?.service_provider?.name[i18n.language], path: `/services/${reqData?.service_provider_id}` },
            { label: id, path: `/products/${id}` }
          ],
          page_title: reqData?.service_provider?.name[i18n.language],
          editBtn: hasPermission(['products.edit']) && { name: 'editProduct', path: `/products/create-product/${id}` }
        })
      );
    }
  }, [isSuccess, data, i18n, id]);

  const [offersTableHeaders, setOfferTableHeaders] = useState<{ label: string; customClass?: string }[]>([]);
  const [casesTableHeaders, setCasesTableHeaders] = useState<{ label: string; customClass?: string }[]>([]);

  useEffect(() => {
    if (hasPermission(['offers.show'])) {
      const reqData = offersData?.data.data;

      if (offersSuccess) {
        setOffersDetails(reqData?.sort((a: any, b: any) => b.is_active - a.is_active));
        setOfferTableHeaders([
          { label: t('id'), customClass: 'status_col' },
          { label: t('status'), customClass: 'status_col' },
          { label: t('start_date'), customClass: 'text-center' },
          { label: t('end_date'), customClass: 'text-center' },
          hasPermission(['offers.edit']) ? { label: t('actions'), customClass: 'actions_col' } : { label: '' }
        ]);
        setCasesTableHeaders([
          { label: t('nationally'), customClass: 'text-center' },
          { label: t('service_period'), customClass: 'text-center' },
          { label: t('minimum_salary') + ' ' + t('sar'), customClass: 'text-center' },
          { label: t('salary_transferred_to'), customClass: 'text-center' },
          { label: t('workSectors'), customClass: 'text-center' }
        ]);

        const activeOffers = reqData?.filter((object: { is_active: boolean }) => {
          return object.is_active;
        });

        setActiveOffers(activeOffers);
      }
    }
  }, [offersSuccess, offersData, i18n, id]);

  useEffect(() => {
    catchError(error || offersError);
  }, [error, offersLoading]);

  const highlights = [
    {
      title: t('activeOffers'),
      number: activeOffers?.length
    },

    {
      title: t('adminFees'),
      number: productDetails?.admin_fee + ' %'
    },
    {
      title: t('insurance_fee'),
      number: productDetails?.insurance_fee + ' %'
    },
    {
      title: t('downPayment'),
      number: productDetails?.down_payment + ' %'
    },
    {
      title: t('duration_to'),
      number: productDetails?.duration_to + t('year')
    },
    {
      title: t('customer_min_age'),
      number: productDetails?.minimum_age
    },
    {
      title: t('customer_max_age'),
      number: productDetails?.maximum_age
    }
  ];

  const balloonTable = [
    // { label: t("logo"), customClass: "logo" },
    { label: t('vehicle_make'), customClass: 'logo balloon-header' },
    // { label: t('vehicle_model') },
    { label: t('balloon') + ' (%)' },
    { label: t('salary_transferred_to') }
  ];

  if (isLoading || offersLoading)
    return (
      <div>
        <BasicInfoSkeleton />
        <BasicInfoSkeleton />
        <TableSkeleton columns={5} />
      </div>
    );

  return (
    <div className="page_content service_details_page">
      <ListHeader customClass="row noMargin">
        <h2>{t('basicDetails')}</h2>
        {hasPermission(['products.edit']) && !isDesktop && (
          <Button text={t('editProduct')} onClick={() => navigate(`/products/create-product/${id}`)} customClass="generalFixedButton" />
        )}
      </ListHeader>
      <CommonHighlights data={highlights} />

      <ListHeader customClass="row noMargin">
        <h2>{t('profitRates')}</h2>
      </ListHeader>
      <CommonHighlights data={profitRateHighlights} />

      {hasPermission(['offers.show']) && (
        <div className="table-wrapper">
          <ListHeader customClass="row">
            <h2>{t('offers')}</h2>
            {hasPermission(['offers.edit']) && <Button text={t('create_offer')} onClick={() => navigate(`/products/${id}/create-offer`)} />}
          </ListHeader>
          <OffersTableContainer
            tableHeaders={offersTableHeaders}
            data={offersDetails}
            noDataMessage={t('no_offers_found')}
            setRefetchData={setRefetch}
            activeOffers={activeOffers}
            lessColumns
          />
        </div>
      )}
      <div className="table-wrapper">
        <ListHeader customClass="row">
          <h2>{t('useCases')}</h2>
        </ListHeader>
        <CasesTable tableHeaders={casesTableHeaders} data={productDetails?.conditions} noDataMessage={t('No_cases_found')} setRefetchData={setRefetch} lessColumns />
      </div>
      <div className="table-wrapper">
        <ListHeader customClass="row">
          <h2>{t('balloon_conditions')}</h2>
        </ListHeader>
        <BalloonTable tableHeaders={balloonTable} data={productDetails?.balloon_conditions} noDataMessage={t('No_ballon_found')} lessColumns />
      </div>
    </div>
  );
};

export default ProductView;
