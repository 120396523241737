import { Form, Formik } from "formik";

import * as Yup from "yup";
import { useState } from "react";
import { toast } from "react-toastify";
import FieldWrapper from "../../../components/formInputs/FieldWrapper";
import Button from "../../../components/buttons/Button";
import { useNavigate } from "react-router-dom";
import { passwordPostRequest } from "../API/api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/redux/store";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { handleUnAuthenticated } from "../store/redux/authData";

const ChangePasswordForm = () => {
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authentication = useSelector((state : RootState) => state.authData);

    const handleLogout = () => {
        const cookies = Cookies.get();
        if (Cookies.get("token")) {
            for (let cookie in cookies) {
                Cookies.remove(cookie); // Remove each cookie
            }
            dispatch(handleUnAuthenticated());
        }
        const contentContainer = document.querySelector(".layout_content");
        contentContainer?.scrollTo({ top: 0, behavior: "smooth" });
        navigate("/auth/login");

    }
    


    const validationSchema = Yup.object({
        // old_password: Yup.string().required('required').matches(/^[^ ]\S*/, "Please remove spaces"),
        password: Yup.string().required('required').matches(/^[^ ]\S*/, "Please remove spaces").min(8, "password must be at least 8 characters."),
        password_confirmation: Yup.string()
            .required('required')
            .oneOf([Yup.ref("password")], t('password_must_match'))
    });

    return (
        <div className="login_form change_password" >
            <Formik validateOnMount
                validationSchema={validationSchema}
                initialValues={{
                    password: "",
                    // old_password: "",
                    password_confirmation: "", 
                }}
                onSubmit={(values) => {
                    setLoading(true)
                    passwordPostRequest({route: `/users/${authentication.userData.id}/edit`, values: {...values, _method: "PUT"}}).then((res) => {
                        setLoading(false)
                        if (res.code === 200) {
                            toast.success(t("passwordUpdatedSuccessfully"))
                          handleLogout()
                        }else {
                            toast.error(t("somethingWrong"))
                        }
                    })
                }}
            >
                {(formik) => (
                    <>
                        <Form>
                            {/* <FieldWrapper
                                inputName={"old_password"}
                                inputPlaceholder={t("currentPassword")}
                                inputError={formik.errors.old_password}
                                inputTouched={formik.touched.old_password}
                                input
                                type={'password'}
                            /> */}
                            {/* <div className='input_group'> */}
                                <FieldWrapper
                                    inputName={"password"}
                                    inputPlaceholder={t("newPassword")}
                                    inputError={formik.errors.password}
                                    inputTouched={formik.touched.password}
                                    input
                                    type={'password'}
                                />
                                <FieldWrapper
                                    inputName={"password_confirmation"}
                                    inputPlaceholder={t("confirmPassword")}
                                    inputError={formik.errors.password_confirmation}
                                    inputTouched={formik.touched.password_confirmation}
                                    input
                                    type={'password'}
                                />
                                        {/* </div> */}
                                <div className="form_button double">
                                    <Button type={loading ? "button" : 'submit'} loading={loading}>
                                        <span className="bold">{t("submit")}</span>
                                    </Button>
                                </div>
                        </Form>
                    </>
                )}
            </Formik>
        </div >
    );
}

export default ChangePasswordForm;