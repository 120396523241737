import React, { useEffect } from 'react';
import Button from '../../../components/buttons/Button';
import gsap from 'gsap';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosInstance } from '../../../config/axiosConfig';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function RemoveCommentAndReplayPopup({
  activeDelete,
  setActiveDelete,
  FieldId
}: {
  activeDelete: boolean;
  setActiveDelete: (value: boolean) => void;
  FieldId: number;
}) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const deleteCommentMutation = useMutation({
    mutationFn: async () =>
      await axiosInstance(`comments/${FieldId}`, {
        method: 'DELETE'
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['comments'] });
      toast.success(`${t('deleted')} ${t('successfully')}`);
      setActiveDelete(false);
    }
  });
  useEffect(() => {
    const cancelBtn = document.querySelector('.cancel-btn') as HTMLElement;
    const tl: gsap.core.Timeline = gsap.timeline();
    cancelBtn.addEventListener('click', () => {
      tl.to('.RemoveCommentAndReplayPopup-container', {
        autoAlpha: 0,
        duration: 0.4
      })
        .to('.RemoveCommentAndReplayPopup', {
          autoAlpha: 0,
          duration: 0.3
        })
        .call(() => {
          setActiveDelete(false);
        });
    });
  }, []);
  useEffect(() => {
    const tl: gsap.core.Timeline = gsap.timeline();
    if (activeDelete) {
      tl.to('.RemoveCommentAndReplayPopup', {
        autoAlpha: 1,
        duration: 0.4
      }).to('.RemoveCommentAndReplayPopup-container', {
        autoAlpha: 1,
        duration: 0.3
      });
    } else {
      tl.to('.RemoveCommentAndReplayPopup-container', {
        autoAlpha: 0,
        duration: 0.4
      })
        .to('.RemoveCommentAndReplayPopup', {
          autoAlpha: 0,
          duration: 0.3
        })
        .call(() => {
          setActiveDelete(false);
        });
    }
  }, [activeDelete]);
  return (
    <div className="RemoveCommentAndReplayPopup">
      <div className="RemoveCommentAndReplayPopup-container">
        <p>{t('deleteCommentMessage')}</p>
        <div className="RemoveCommentAndReplayPopup-btn">
          <Button
            text={t('delete')}
            loading={deleteCommentMutation?.isPending}
            commentBtn
            customClass="delete-btn"
            onClick={() => {
              deleteCommentMutation.mutate();
            }}
          />
          <Button text={t('cancel')} customClass="cancel-btn" />
        </div>
      </div>
    </div>
  );
}
