import { Form, Formik } from 'formik';
import FieldWrapper from '../../../components/formInputs/FieldWrapper';
import Button from '../../../components/buttons/Button';
import { sendIcon } from '../../../config/variables';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosInstance } from '../../../config/axiosConfig';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
export default function ApplicationReplyAdd({ commentable_id, comment_id }: { commentable_id: number; comment_id: number }) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { id } = useParams();
  const replayMutation = useMutation({
    mutationFn: async (values: FormValues) =>
      await axiosInstance(`entity/${commentable_id}/comments`, {
        data: { ...values, comment_id: comment_id, application_product_id: id },
        method: 'POST'
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['comments', commentable_id] });
      toast.success(`${t('addedComment')} ${t('successfully')}`);
    }
  });
  interface FormValues {
    body: string;
  }
  const initialValues: FormValues = { body: '' };
  const validationSchema = Yup.object({
    body: Yup.string().min(3).max(100).required(t('required'))
  });
  const onSubmit = (values: FormValues) => {
    replayMutation.mutate(values);
  };
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(formik) => {
        return (
          <Form style={{ marginBottom: '1rem' }}>
            <div className="add-reply">
              <FieldWrapper
                inputName={'body'}
                inputPlaceholder={t('addReply')}
                inputError={formik.errors.body as string}
                inputTouched={formik.touched.body as boolean}
                input
                customPadding
              />
              <Button
                text={sendIcon}
                customClass="icon-only"
                loading={replayMutation.isPending ? true : false}
                disabled={formik.values.body.length > 2 ? false : true}
                commentBtn
                onClick={function () {
                  formik.submitForm();
                  setTimeout(() => {
                    formik.resetForm();
                  }, 500);
                }}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
