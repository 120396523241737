import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setBreadCrumbsData } from '../../../store/redux/breadCrumbsData';
import TableSkeleton from '../../../components/loaders/TableSkeleton';
import { useQuery } from '@tanstack/react-query';
import { authContext } from '../../../store/context/authContext';
import { useContext, useEffect, useState, useTransition } from 'react';
import { ISales } from '../../../modules/sales/types/interfaces';
import { generalGet } from '../../../API/api';
import ListHeader from '../../../components/ListHeader';
import SearchInputField from '../../../components/SearchInputField';
import UsersTableContainer from '../../../modules/sales/components/UsersTableContainer';
import { Pagination, Stack } from '@mui/material';
import { IMeta } from '../../../modules/applications/types/interfaces';
import Select, { GroupBase, OptionsOrGroups } from 'react-select';
import { paginationControlStyles, sortStyles } from '../../../utils/SelectStyles';
import { hasPermission } from '../../../utils/HelperFunctions';

const SalesList = () => {
  const { t, i18n } = useTranslation();
  const { catchError } = useContext(authContext);
  const dispatch = useDispatch();
  const [isPending, startTransition] = useTransition();
  const [refetch, setRefetch] = useState(false);
  const [users, setUsers] = useState<ISales[]>([]);
  const [shownList, setShownList] = useState<ISales[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const [metaData, setMetaData] = useState<IMeta>();
  const [currentRoute, setCurrentRoute] = useState('/admin/users?role_name=mynm-sales&page=1&limit=25');
  const [perPageOptions, setPerPageOptions] = useState([
    { value: '10', label: '10' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' }
  ]);
  const [selectedPerPage, setSelectedPerPage] = useState({ value: '25', label: '25' });

  dispatch(
    setBreadCrumbsData({
      links: [{ label: t('sales'), path: '/sales' }],
      page_title: t('sales')
    })
  );
  const { data, isSuccess, isLoading, error } = useQuery({
    queryKey: ['Sales Executives', refetch, i18n, currentRoute],
    queryFn: () => generalGet(currentRoute),
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    const reqData = data?.data.data;
    isSuccess && setUsers(reqData?.map((item: ISales) => item));
    isSuccess && setMetaData(data?.data?.meta);
  }, [isSuccess, data]);

  useEffect(() => {
    error && catchError(error);
  }, [error]);

  const usersTableHeaders = [
    { label: t('first_name') },
    { label: t('email') },
    { label: t('phone') },
    { label: t('role') },
    { label: t('status'), customClass: 'status_col' },
    hasPermission(['users.edit', 'owned-users.edit']) && { label: t('actions'), customClass: 'actions_col' }
  ];

  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setCurrentRoute(`/admin/users?role_name=mynm-sales&page=${value}&limit=${selectedPerPage.value}&q=${searchInput}`);
  };

  useEffect(() => {
    setCurrentRoute(`/admin/users?role_name=mynm-sales&page=1&limit=${selectedPerPage.value}&q=${searchInput}`);
  }, [selectedPerPage, searchInput]);

  if (isLoading && !searchInput) return <TableSkeleton columns={6} withoutButton />;

  return (
    <div className="distributors-page-container">
      <ListHeader>
        <div className="filter_container">
          <SearchInputField placeholder={t('search_by_id_Name')} setSearchInput={setSearchInput} searchInput={searchInput} />
        </div>
      </ListHeader>
      <UsersTableContainer tableHeaders={usersTableHeaders} data={users} noDataMessage={t('No_users_found')} border setRefetchData={setRefetch} />

      {metaData && (
        <Stack spacing={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '1rem' }}>
          <div className="" style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            {t('rowsPerPage')}{' '}
            <Select
              value={selectedPerPage}
              placeholder={t('perPage')}
              isSearchable={false}
              isClearable={false}
              isMulti={false}
              options={perPageOptions}
              className={`select-drop-down `}
              noOptionsMessage={() => t('no_options')}
              classNamePrefix="react-select"
              styles={{ ...sortStyles, ...paginationControlStyles }}
              onChange={(e) => {
                e && setSelectedPerPage(e);
                setCurrentRoute(`/admin/users?role_name=mynm-sales&page=${metaData?.current_page}&limit=${e?.value}`);
              }}
            />
          </div>
          {Number(metaData?.last_page) > 1 && <Pagination count={Number(metaData?.last_page)} page={page} onChange={handleChange} />}
        </Stack>
      )}
    </div>
  );
};

export default SalesList;
