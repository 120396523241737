import { Link } from 'react-router-dom';
import { IErrorCard } from '../../types/Interfaces';

const ErrorCard = ({ title, description, buttonText = '', buttonUrl = '' }: IErrorCard) => {
  return (
    <div className="error-card">
      <div className="error-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M12 16h.01" />
          <path d="M12 8v4" />
          <path d="M15.312 2a2 2 0 0 1 1.414.586l4.688 4.688A2 2 0 0 1 22 8.688v6.624a2 2 0 0 1-.586 1.414l-4.688 4.688a2 2 0 0 1-1.414.586H8.688a2 2 0 0 1-1.414-.586l-4.688-4.688A2 2 0 0 1 2 15.312V8.688a2 2 0 0 1 .586-1.414l4.688-4.688A2 2 0 0 1 8.688 2z" />
        </svg>
      </div>
      <h4>{title}</h4>
      <div className="paragraph-button-container">
        <p>{description}</p>
        {buttonText && buttonUrl && <Link to={buttonUrl}>{buttonText}</Link>}
      </div>
    </div>
  );
};

export default ErrorCard;
