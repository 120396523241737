import { useDispatch } from 'react-redux';
import { setBreadCrumbsData } from '../../../store/redux/breadCrumbsData';
import { useTranslation } from 'react-i18next';
import StepsCreationForm from '../../../modules/steps/components/StepsCreationForm';

const StepsCreation = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  dispatch(
    setBreadCrumbsData({
      links: [{ label: t('steps'), path: '/steps-creation' }],
      page_title: t('stepCreation')
    })
  );
  return (
    <div className="page_content product_details_page">
      <StepsCreationForm />
    </div>
  );
};

export default StepsCreation;
