import { ReactNode } from "react";
import { IServiceInfo } from "../types/interfaces";


const CommonHighlights = ({ data, children, noBorder }: { noBorder?: boolean, data: IServiceInfo[], children?: ReactNode }) => {

	return (
		<div className={`common-highlight ${noBorder ? "noBorder" : ""}`}>
			<div className="cards-container">
				{data?.map((item: IServiceInfo, index: number) => (
					<div className={`card ${children && "hasChildren"}`} key={index}>
						<h6>{item.title}</h6>
						<p>{item.number}</p>
					</div>
				))}
				{children}
			</div>
		</div>
	);
}

export default CommonHighlights;