import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalContainer from '../../../components/ModalContainer';
import DeleteModal from '../../../components/DeleteModal';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { deleteIcon, editIcon } from '../../../config/variables';
import { hasPermission } from '../../../utils/HelperFunctions';

const OffersTableContainer = ({ tableHeaders, data, noDataMessage, setRefetchData, productId, activeOffers, lessColumns }: any) => {
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { t } = useTranslation();
  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });

  return (
    <div className={`table_container ${lessColumns && isDesktop && 'lessColumns'}`}>
      <div className="table_header">
        {tableHeaders?.map(
          (header: any, index: number) =>
            header.label && (
              <span className={`head ${header.customClass}`} key={index}>
                {header.label}
              </span>
            )
        )}
      </div>
      <div className={`table_data ${(!data || data?.length == 0) && 'no_data'}`}>
        {data?.length > 0 ? (
          <>
            {data?.map((item: any, index: number) => (
              <div className="item has_logo offers" key={index}>
                <div className="column text-center">{item?.id || '-'}</div>
                <div className="column status_col capitalize">
                  <div className={`${activeOffers?.includes(item) ? 'active' : ''}`}>{`${activeOffers?.includes(item) ? t('active') : t('inactive')}`}</div>
                </div>
                <div className="column text-center">{moment(item?.start_date).format('MMM Do YYYY') || '-'}</div>
                <div className="column text-center">{moment(item?.end_date).format('MMM Do YYYY') || '-'}</div>
                {hasPermission(['offers.edit']) && (
                  <div className="column actions actions_col">
                    <div
                      className="action_btn edit"
                      onClick={(e) => {
                        const contentContainer = document.querySelector('.layout_content');
                        contentContainer?.scrollTo({ top: 0, behavior: 'smooth' });
                        e.stopPropagation();
                        navigate(`/products/${item.product_id}/create-offer/${item.id}`);
                      }}
                    >
                      {editIcon}
                    </div>
                    <div
                      className="action_btn delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteModalOpen(item.id);
                      }}
                    >
                      {deleteIcon}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </>
        ) : (
          <h6>{noDataMessage}</h6>
        )}
      </div>
      {deleteModalOpen && (
        <ModalContainer small>
          <DeleteModal
            id={deleteModalOpen}
            setModal={setDeleteModalOpen}
            setRefetchData={setRefetchData}
            route={'/admin/offers'}
            successMsg={t('deletedOffer')}
            warningMsg={t('deleteConfirmationMessageOffer')}
          />
        </ModalContainer>
      )}
    </div>
  );
};

export default OffersTableContainer;
