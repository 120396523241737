import '../styles/app.scss';
import '../styles/app-rtl.scss';
import Layout from '../components/layout/Layout';
import { BrowserRouter as Router, Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import Providers from './Providers';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Login from './pages/auth/Login';
import ProtectedRoutes from '../utils/ProtectedRoutes';
import ProtectedAuth from '../utils/ProtectedAuth';
import Dashboard from './pages/Dashboard';
import NotFound from './pages/NotFound';
import ChangePasswordPageComponents from './pages/auth/ChangePassword';
import CreateService from './pages/servicesProviders/CreateService';
import ServiceProviders from './pages/servicesProviders/ServiceProviders';
import ServiceDetails from './pages/servicesProviders/ServiceDetails';
import Products from './pages/products/Products';
import CreateOffer from './pages/products/CreateOffer';
import Distributors from './pages/distributors/Distributors';
import CreateDistributor from './pages/distributors/CreateDistributor';
import DistributorDetails from './pages/distributors/DistributorDetails';
import ProductView from './pages/products/ProductView';
import CreateSales from './pages/sales/CreateSales';
import SalesList from './pages/sales/SalesList';
import Applications from './pages/applications/Applications';
import FindDeal from './pages/applications/FindDeal';
import CreateProduct from './pages/products/CreateProduct';
import ApplicationDetails from './pages/applications/ApplicationDetails';
import SalesDetails from './pages/sales/SalesDetails';
import Users from './pages/users/Users';
import CreateUser from './pages/users/CreateUser';
import PermittedRoutes from '../utils/PermittedRoutes';
import RolesList from './pages/roles/RolesList';
import CreateRole from './pages/roles/CreateRole';
import StepsCreation from './pages/steps/StepsCreation';
import Steps from './pages/steps/Steps';
import Notifications from './pages/Notifications/Notifications';
import ProductSteps from './pages/applications/ProductSteps';
import VerifyAdmin from './pages/VerifyAdmin';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

const App: React.FC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.documentElement.dir = i18n.dir();
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <BrowserRouter>
      <Providers>
        <Layout>
          <Routes>
            <Route path="/" element={<ProtectedRoutes />}>
              <Route path="/" element={<Navigate to={'/dashboard'} />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/auth/change-password" element={<ChangePasswordPageComponents />} />

              <Route element={<PermittedRoutes permissions={['service_providers.show']} />}>
                <Route path="/services" element={<ServiceProviders />} />
                <Route path="/services/:id" element={<ServiceDetails />} />
              </Route>

              <Route element={<PermittedRoutes permissions={['service_providers.crud']} />}>
                <Route path="/services/create-service" element={<CreateService />} />
                <Route path="/services/create-service/:id" element={<CreateService />} />
              </Route>

              <Route element={<PermittedRoutes permissions={['products.show']} />}>
                <Route path="/products" element={<Products />} />
                <Route path="/products/:id" element={<ProductView />} />
              </Route>

              <Route element={<PermittedRoutes permissions={['products.edit']} />}>
                <Route path="/products/create-product" element={<CreateProduct />} />
                <Route path="/products/create-product/:id" element={<CreateProduct />} />
              </Route>

              <Route element={<PermittedRoutes permissions={['distributors.show']} />}>
                <Route path="/distributors" element={<Distributors />} />
                <Route path="/distributors/:id" element={<DistributorDetails />} />
              </Route>

              <Route element={<PermittedRoutes permissions={['distributors.edit']} />}>
                <Route path="/distributors/create-distributor" element={<CreateDistributor />} />
                <Route path="/distributors/create-distributor/:id" element={<CreateDistributor />} />
              </Route>

              <Route element={<PermittedRoutes permissions={['users.show']} />}>
                <Route path="/sales" element={<SalesList />} />
                <Route path="/sales/:id" element={<SalesDetails />} />
              </Route>

              <Route element={<PermittedRoutes permissions={['users.edit']} />}>
                <Route path="/sales/create-sales-executive" element={<CreateSales />} />
                <Route path="/sales/create-sales-executive/:id" element={<CreateSales />} />
              </Route>

              <Route element={<PermittedRoutes permissions={['offers.edit']} />}>
                <Route path="/products/:id/create-offer" element={<CreateOffer />} />
                <Route path="/products/:id/create-offer/:offerId" element={<CreateOffer />} />
              </Route>

              <Route element={<PermittedRoutes permissions={['applications.get']} />}>
                <Route path="/applications" element={<Applications />} />
                <Route path="/applications/:id" element={<ApplicationDetails />} />
                <Route path="/application-products/:id" element={<ProductSteps />} />
              </Route>

              <Route element={<PermittedRoutes permissions={['applications.get']} />}>
                <Route path="/applications/create/find-deal" element={<FindDeal />} />
              </Route>

              <Route element={<PermittedRoutes permissions={['roles.show']} />}>
                <Route path="/roles" element={<RolesList />} />
              </Route>

              <Route element={<PermittedRoutes permissions={['roles.edit']} />}>
                <Route path="/roles/create-role" element={<CreateRole />} />
                <Route path="/roles/create-role/:id" element={<CreateRole />} />
              </Route>

              <Route element={<PermittedRoutes permissions={['roles.edit']} />}>
                <Route path="/steps" element={<Steps />} />
                <Route path="/steps/create-step" element={<StepsCreation />} />
                <Route path="/steps/create-step/:id" element={<StepsCreation />} />
              </Route>

              <Route element={<PermittedRoutes permissions={['users.show', 'owned-users.show']} />}>
                <Route path="/users" element={<Users />} />
              </Route>
              <Route element={<PermittedRoutes permissions={['users.edit', 'owned-users.edit']} />}>
                <Route path="/users/create-user" element={<CreateUser />} />
                <Route path="/users/create-user/:id" element={<CreateUser />} />
              </Route>

              {/* <Route element={<PermittedRoutes permissions={['notifications.edit']} />}> */}
              <Route path="/notifications" element={<Notifications />} />
              {/* </Route> */}

              <Route path="/not-found" element={<NotFound />} />
            </Route>

            <Route element={<ProtectedAuth />}>
              <Route path="/auth/login" element={<Login />} />
            </Route>

            <Route path="*" element={<Navigate to="/not-found" replace />} />
            <Route path='/auth/forgot-password/' element={<ForgotPassword />} />
            <Route path='/auth/reset-password/' element={<ResetPassword />} />
            <Route path='/verify-admin/' element={<VerifyAdmin />} />
          </Routes>
        </Layout>
      </Providers>
    </BrowserRouter>
  );
};

export default App;
