import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

const ApplicationsTableContainer = ({ tableHeaders, data, noDataMessage, service }: any) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });

  return (
    <div className={`table_container  ${isDesktop && 'user_table'} hasPagination`}>
      <div className="table_header">
        {tableHeaders?.map((header: any, index: number) => (
          <span className={`head app ${header.customClass}`} key={index}>
            {header.label}
          </span>
        ))}
      </div>
      <div className={`table_data ${(!data || data?.length == 0) && 'no_data'}`}>
        {data?.length > 0 ? (
          <>
            {data?.map((item: any, index: number) => (
              <>
                <div
                  className="item clickable has_logo"
                  key={index}
                  onClick={() => {
                    const contentContainer = document.querySelector('.layout_content');
                    contentContainer?.scrollTo({ top: 0, behavior: 'smooth' });
                    navigate(`/applications/${item.id}`);
                  }}
                >
                  <div className="column app">{item?.id}</div>
                  <div className="column app">{`${item?.vehicle?.variant_id?.vehicle_model?.vehicle_make}, ${item?.vehicle?.variant_id?.vehicle_model?.name}`}</div>
                  {!service && <div className="column app">{item?.service_provider?.[i18n.language]}</div>}
                  <div className="column app">{item?.distributor}</div>
                  <div className="column app">{item?.current_step?.name || '-'}</div>
                  <div className="column r app">{item?.status?.lang}</div>
                  <div className="column r app">{item?.user?.first_name}</div>
                  <div className="column app"> {moment(item?.updated_at).format('DD-MM-YYYY')}</div>
                </div>
              </>
            ))}
          </>
        ) : (
          <h6>{noDataMessage}</h6>
        )}
      </div>
    </div>
  );
};

export default ApplicationsTableContainer;
