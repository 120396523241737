import { Box } from "@mui/material";
import Checkbox from "../../../../components/formInputs/Checkbox";
import { ErrorMessage } from "formik";

const FilterCheckBox = ({ filterAvg, formik, objKey, header }: {
    filterAvg: any,
    formik: any,
    objKey: string,
    header: string
}) => {
    return (
        <Box sx={{ padding: "1rem 0 1.5rem", borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
            <span className='range-header'>{header}</span>
            <div className="checkboxes_wrapper row">
                {filterAvg[`${objKey}`]?.map((item: any, i: number) => (
                    <Checkbox
                        name={`filters.${objKey}`}
                        key={i}
                        item={{ label: `${item[objKey]} % (${item.product_count})`, value: item[`${objKey}`] }}
                        value={formik?.values?.filters[`${objKey}`]?.some((checkedItem: string) => checkedItem == item[`${objKey}`])}
                        checked={formik?.values?.filters[`${objKey}`]}
                        formik={formik}
                    />
                ))}
            </div>
            <p className="error check">
                <ErrorMessage name={`filters.${objKey}`} />
            </p>
        </Box>
    );
}

export default FilterCheckBox;