import { BarChart } from "@mui/x-charts";
import ListHeader from "../../../components/ListHeader";
import TopData from "./TopData";
import { useEffect, useMemo, useState } from "react";
import { getChartOptions } from "../utils/HelperFunctions";
import { generalGet } from "../../../API/api";
import { useQuery } from "@tanstack/react-query";
import ChartDirection from "./ChartDirection";
import { useTranslation } from "react-i18next";

const CustomersData = ({ customerData }: any) => {

    const { t } = useTranslation()
    const { data: workSectorsData, isSuccess: workSectorsSuccess } = useQuery({
        queryKey: ["work sectors", customerData],
        queryFn: () => generalGet(`/work-sectors`),
        refetchOnWindowFocus: false
    });
    const [workSectors, setWorkSectors] = useState<{ x: string, value: string }[]>([])

    useEffect(() => {
        if (workSectorsSuccess) {
            setWorkSectors([...workSectorsData.data.data?.map((item: any) => (
                {
                    x: item.name,
                    value: customerData?.work_sectors[item.name] || 0
                }
            ))])
        }
    }, [workSectorsSuccess, workSectorsData, customerData])


    const topNationalityData = useMemo(() => {
        const data = [
            {
                title: t("dashboardCharts.saudi"),
                number: `${customerData?.nationalities.saudi}` || "0"
            },
            {
                title: t("dashboardCharts.non_saudi"),
                number: `${customerData?.nationalities["non-saudi"]}` || '0'
            }
        ]

        return data
    }, [customerData])

    const topCitesData = useMemo(() => {
        const data = []
        for (let key in customerData?.cities) {
            data.push({ title: key, number: customerData?.cities[key] })
        }
        return data
    }, [customerData])

    const dataSet = useMemo(() => {
        const data = []
        for (let key in customerData?.age_range) {
            data.push({ x: key, value: customerData?.age_range[key] })
        }
        return data
    }, [customerData])


    return (
        <>
            <ListHeader customClass="row noMargin">
                <h2>{t("dashboardCharts.customers")}</h2>
            </ListHeader>
            <div className={`dashboard-data`}>
                <div className="charts ">
                    <ChartDirection>
                        <BarChart
                            dataset={dataSet}
                            xAxis={[
                                {
                                    scaleType: 'band', dataKey: 'x', tickPlacement: "middle",
                                    colorMap: {
                                        type: 'piecewise',
                                        thresholds: [new Date(2021, 1, 1), new Date(2023, 1, 1)],
                                        colors: ['#000'],
                                    }
                                },
                            ]}
                            {...getChartOptions(t("dashboardCharts.no_of_customers"), t("dashboardCharts.age_range"))}
                        />
                    </ChartDirection>
                    {workSectors?.length > 0 &&
                        <ChartDirection>
                            <BarChart
                                dataset={workSectors}
                                xAxis={[
                                    {
                                        scaleType: 'band', dataKey: 'x', tickPlacement: "middle",

                                        colorMap: {
                                            type: 'piecewise',
                                            thresholds: [new Date(2021, 1, 1), new Date(2023, 1, 1)],
                                            colors: ['#3498db'],
                                        }
                                    },
                                ]}
                                {...getChartOptions(t("dashboardCharts.no_of_customers"), t("dashboardCharts.value"), "#3498db")}
                            />
                        </ChartDirection>
                    }
                </div>

                <div className="top-dashboard-data" >
                    <>
                        {topNationalityData?.length > 0 &&
                            <TopData
                                data={topNationalityData}
                                header={"Nationality"}
                            />
                        }
                        {topCitesData?.length > 0 &&
                            <TopData
                                data={topCitesData}
                                header={"Cities"}
                            />
                        }
                    </>
                </div>
            </div>
        </>
    );
}

export default CustomersData;