import { useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import 'react-phone-number-input/style.css';
import { toast } from 'react-toastify';
import FieldWrapper from '../../../components/formInputs/FieldWrapper';
import Button from '../../../components/buttons/Button';
import { generalCreate, generalGet } from '../../../API/api';
import { authContext } from '../../../store/context/authContext';
import { TOptions } from '../../../types/types';
import { customStyles } from '../../../utils/SelectStyles';
import { IUser } from '../types/interfaces';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import FormSkeleton from '../../../components/loaders/FormSkeleton';

const CreateUserForm = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { catchError } = useContext(authContext);
  const [userData, setUserData] = useState<IUser | null>();
  const { t } = useTranslation();
  const { roles } = useSelector((store: any) => store.rolesData);
  const navigate = useNavigate();

  const { data, isSuccess, isLoading } = useQuery({
    queryKey: ['distributor data', id],
    queryFn: () => generalGet(`/admin/users/${id}`),
    enabled: !!id
  });

  useEffect(() => {
    const reqData = data?.data.data;
    if (isSuccess) {
      setUserData(reqData);
    }
  }, [isSuccess, data, id]);

  const handleResendVerification = async () => {
    generalCreate({
      route: `/resend-verification/${userData?.email}`
    })
      .then((res) => {
        setLoading(false);
        toast.success(res?.data?.message);
      })
      .catch((error) => {
        catchError(error, setLoading);
      });
  };
  const validationSchema = Yup.object({
    first_name: Yup.string()
      .required(t('required'))
      .matches(/^[^ ]\S*/, t('remove_space')),
    last_name: Yup.string()
      .required(t('required'))
      .matches(/^[^ ]\S*/, t('remove_space')),
    city: Yup.string()
      .required(t('required'))
      .matches(/^[^ ]\S*/, t('remove_space')),
    profession: Yup.string()
      .required(t('required'))
      .matches(/^[^ ]\S*/, t('remove_space')),
    role_name: Yup.string().required(t('required')),
    email: Yup.string()
      .email(t('email_format'))
      .required(t('required'))
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t('email_format')),
    mobile: Yup.string()
      .required(t('required'))
      .matches(/^05[0-9]{8}$/, t('wrong_number'))
    // ...(id == null ? {
    //   password: Yup.string()
    //     .required(t("required"))
    //     .matches(/^[^ ]\S*$/, t("remove_space")) // No spaces allowed
    //     .min(8, t("password_must")) // Minimum length of 8 characters
    //     .matches(/[A-Z]/, t("must_contain_uppercase")) // At least one uppercase letter
    //     .matches(/[a-z]/, t("must_contain_lowercase")) // At least one lowercase letter
    //     .matches(/\d/, t("must_contain_number")) // At least one number
    //     .matches(/[@$!%*?&]/, t("must_contain_special_char")),
    //   password_confirmation: Yup.string()
    //     .required(t("required"))
    //     .oneOf([Yup.ref("password")], t("passwords_mismatch")),
    // } : {}),
  });

  if (isLoading) return <FormSkeleton noImage />;

  return (
    <div className="create_user_form modal_content">
      <Formik
        enableReinitialize
        validateOnMount
        validationSchema={validationSchema}
        initialValues={{
          first_name: userData?.first_name || '',
          last_name: userData?.last_name || '',
          email: userData?.email || '',
          mobile: userData?.mobile || '',
          city: userData?.address ? userData?.address?.city : '',
          profession: userData?.profession || '',
          street: userData?.address ? userData?.address?.street : '',
          role_name: userData?.roles?.[0] || ''
        }}
        onSubmit={(values) => {
          setLoading(true);
          let createValues = { ...values, mobile: values?.mobile.toString() };
          // if (id && values.password === "") {
          //   delete (values as Partial<IUser>).password;
          //   delete (values as Partial<IUser>).password_confirmation;
          // }
          let updateValues = { ...values, mobile: values?.mobile.toString(), _method: 'PUT' };
          let route = !id ? `/admin/users` : `/users/${id}/edit`;
          generalCreate({
            route: route,
            values: id ? { ...updateValues } : { ...createValues }
          })
            .then(() => {
              setLoading(false);
              toast.success(`${t('user')} ${!id ? t('created') : t('updated')} ${t('successfully')}`);
              navigate('/users');
            })
            .catch((error) => {
              catchError(error, setLoading);
            });
        }}
      >
        {(formik) => (
          <>
            <Form>
              <div className="form_inputs">
                <div className="inputs_group">
                  <FieldWrapper
                    title={t('first_name')}
                    inputName={'first_name'}
                    inputPlaceholder={t('first_name')}
                    inputError={formik.errors.first_name as string}
                    inputTouched={formik.touched.first_name as boolean}
                    input
                  />
                  <FieldWrapper
                    title={t('last_name')}
                    inputName={'last_name'}
                    inputPlaceholder={t('last_name')}
                    inputError={formik.errors.last_name as string}
                    inputTouched={formik.touched.last_name as boolean}
                    input
                  />
                </div>
                <div className="inputs_group">
                  <FieldWrapper
                    title={t('email')}
                    inputName={'email'}
                    inputPlaceholder={t('email')}
                    inputError={formik.errors.email as string}
                    inputTouched={formik.touched.email as boolean}
                    input
                  />
                  <FieldWrapper
                    title={t('phone')}
                    inputName={'mobile'}
                    inputPlaceholder={`${t('ex')}: 0511111111`}
                    inputError={formik.errors.mobile as string}
                    inputTouched={formik.touched.mobile as boolean}
                    input
                  />
                </div>

                <div className="inputs_group">
                  <FieldWrapper
                    title={t('profession')}
                    inputName={'profession'}
                    inputPlaceholder={t('profession')}
                    inputError={formik.errors.profession as string}
                    inputTouched={formik.touched.profession as boolean}
                    input
                  />
                  <FieldWrapper
                    title={t('city')}
                    inputName={'city'}
                    inputPlaceholder={t('city')}
                    inputError={formik.errors.city as string}
                    inputTouched={formik.touched.city as boolean}
                    input
                  />
                </div>
                <div className="inputs_group">
                  <FieldWrapper
                    title={t('street')}
                    inputName={'street'}
                    inputPlaceholder={t('street')}
                    inputError={formik.errors.street as string}
                    inputTouched={formik.touched.street as boolean}
                    input
                  />
                </div>
                <div className="inputs_group">
                  <FieldWrapper
                    customPadding
                    title={t('user_role')}
                    inputPlaceholder={t('user_role')}
                    inputName={'role_name'}
                    inputError={formik.errors.role_name as string}
                    inputTouched={formik.touched.role_name as boolean}
                    options={roles}
                    onChange={(e) => {
                      formik.setFieldValue('role_name', (e as TOptions).label);
                    }}
                    key={userData?.roles?.[0] || 'role_name'}
                    value={formik.values.role_name && roles.find((item: TOptions) => item.label === formik.values.role_name)}
                    selectStyle={customStyles}
                    select
                  />
                  <FieldWrapper />
                </div>
              </div>
              <div className="form_footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button type={loading ? 'button' : 'submit'} loading={loading}>
                  <span className="bold">{t('save')}</span>
                </Button>
                {!userData?.is_verified && userData && (
                  <Button type={'button'} loading={loading} onClick={handleResendVerification}>
                    <span className="bold">{t('resendVerification')}</span>
                  </Button>
                )}
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default CreateUserForm;
