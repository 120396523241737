import { useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { addDays } from 'date-fns';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/buttons/Button';
import CloseButton from '../../../components/buttons/CloseButton';
import { Locale } from "date-fns";
import { arSA, enUS } from 'date-fns/locale';

const CustomizeDateRange = ({ setDateRange, dateRange, setActiveTab, setOpenModal }: any) => {

    const { t, i18n } = useTranslation()
    const localeMap: { [key: string]: Locale } = {
        en: enUS,
        ar: arSA
    };
    const selectedLocale = localeMap[i18n.language] || enUS;
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: addDays(new Date(), -7),
        key: 'selection'
    });

    const handleDateRangeChange = (ranges: any) => {
        setSelectionRange(ranges.selection);
    };

    return (
        <div className="common-modal date dashboard-date-range">
            <div className='header'>
                <h4>{t("dashboardCharts.customized_duration")}</h4>
                <span>{t("dashboardCharts.duration_must_be")}</span>
            </div>
            <CloseButton handleClose={() => setOpenModal(false)} />
            <div className={`date_range_input`}  >
                <div>
                    <span className="last val">
                        {dateRange ? (
                            <div className="dates">
                                <span> {moment(dateRange?.startDate).format('DD-MM-YYYY')}</span>
                                <span> {moment(dateRange?.endDate).format('DD-MM-YYYY')}</span>
                            </div>
                        ) : (
                            <span className='placeholder'>{t("dashboardCharts.select_duration_dates")}</span>
                        )}
                    </span>
                </div>
                <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                        <path d="M20 20h-4v-4h4v4zm-6-10h-4v4h4v-4zm6 0h-4v4h4v-4zm-12 6h-4v4h4v-4zm6 0h-4v4h4v-4zm-6-6h-4v4h4v-4zm16-8v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2z" />
                    </svg>
                </div>
            </div>
            <div>
                <DateRange
                    locale={selectedLocale}
                    ranges={[selectionRange]}
                    onChange={(ranges) => handleDateRangeChange(ranges)}
                    showMonthAndYearPickers={false}
                    showPreview={false}
                    maxDate={addDays(new Date(), 0)}
                    moveRangeOnFirstSelection={false}

                />
            </div>
            <Button text={"Apply"}
                onClick={() => {
                    if (Math.ceil((selectionRange.endDate.getTime() - selectionRange.startDate.getTime()) / (1000 * 3600 * 24)) >= 7) {
                        setDateRange(selectionRange)
                        setActiveTab("customize")
                        setOpenModal(false)
                    }
                }}
                disabled={Math.ceil((selectionRange.endDate.getTime() - selectionRange.startDate.getTime()) / (1000 * 3600 * 24)) < 7}
            />
        </div>
    );
};

export default CustomizeDateRange;
