import { ErrorMessage, FormikValues } from 'formik';
import FieldWrapper from '../../../components/formInputs/FieldWrapper';
import { useTranslation } from 'react-i18next';
import { customStyles } from '../../../utils/SelectStyles';
import Checkbox from '../../../components/formInputs/Checkbox';
import { IStepsData } from '../types/interfaces';
import { useEffect, useState } from 'react';

interface IProps {
  formik: FormikValues;
  fieldIndex: number;
  entityIndex: number;
}

const validationDataCheckBoxes = [
  { name: 'required', id: '1', label: 'required', value: 'required' },
  {
    name: 'numeric',
    id: '2',
    label: 'numeric',
    value: 'numeric'
  },
  {
    name: 'email',
    id: '3',
    label: 'email',
    value: 'email'
  },

  {
    name: 'unique',
    id: '4',
    label: 'unique',
    value: 'unique'
  }
];
const FieldInputs = ({ formik, fieldIndex, entityIndex }: IProps) => {
  const { t, i18n } = useTranslation();
  const [defaultType, setDefaultType] = useState<any>();

  useEffect(() => {
    if (formik?.values?.entities?.[entityIndex]?.fields?.[fieldIndex]?.type) {
      setDefaultType({ label: formik?.values?.entities?.[entityIndex]?.fields?.[fieldIndex]?.type, value: formik?.values?.entities?.[entityIndex]?.fields?.[fieldIndex]?.type });
    }
  }, [formik]);

  return (
    <div className="fields-container">
      <div className="inputs_group">
        <FieldWrapper
          title={t('name')}
          inputName={`entities[${entityIndex}].fields[${fieldIndex}].name`}
          inputPlaceholder={`${t('ex')} ${t('uploadingDocuments')}`}
          inputError={formik?.errors?.entities?.[entityIndex]?.fields?.[fieldIndex]?.name as string}
          inputTouched={formik?.touched?.entities?.[entityIndex]?.fields?.[fieldIndex]?.name as boolean}
          input
          customPadding
          // type="number"
        />

        <FieldWrapper
          customPadding
          title={t('type')}
          inputName={`entities[${entityIndex}].fields[${fieldIndex}].type`}
          inputError={formik?.errors?.entities?.[entityIndex]?.fields?.[fieldIndex]?.type as string}
          inputTouched={formik?.touched?.entities?.[entityIndex]?.fields?.[fieldIndex]?.type as boolean}
          inputPlaceholder={t('selectType')}
          // selectRef={modelRef}
          // ref={inputRef}
          options={[
            { label: t('text'), value: 'text' },
            { label: t('file'), value: 'file' }
          ]}
          onChange={(e: any) => {
            if (e?.value === 'file') {
              const existingValidation = formik.values.entities[entityIndex].fields[fieldIndex].validation;
              // const filteredArr = existingValidation?.filter((item: string[]) => !item.includes("min:") && !item.includes("max:"));

              formik.setFieldValue(`entities[${entityIndex}].fields[${fieldIndex}].type`, e?.value);
              const newValidation = [...existingValidation, 'file'];
              formik.setFieldValue(`entities[${entityIndex}].fields[${fieldIndex}].validation`, newValidation);
            } else if (e?.value === 'text') {
              const existingValidation = formik.values.entities[entityIndex].fields[fieldIndex].validation;
              const newValidation = existingValidation.filter((item: string) => item !== 'file');
              formik.setFieldValue(`entities[${entityIndex}].fields[${fieldIndex}].validation`, newValidation);
              formik.setFieldValue(`entities[${entityIndex}].fields[${fieldIndex}].type`, e?.value);
            }
          }}
          selectStyle={customStyles}
          select
          defaultValue={defaultType}
          key={defaultType?.value}
        />
      </div>
      <div className="inputs_group">
        <FieldWrapper
          title={t('labelEn')}
          inputName={`entities[${entityIndex}].fields[${fieldIndex}].label[en]`}
          inputPlaceholder={`${t('ex')} input 1`}
          inputError={formik?.errors?.entities?.[entityIndex]?.fields?.[fieldIndex]?.label?.en as string}
          inputTouched={formik?.touched?.entities?.[entityIndex]?.fields?.[fieldIndex]?.label?.en as boolean}
          input
          // value={formik?.values?.minimum_age}
          customPadding
          // type="number"
        />
        <FieldWrapper
          title={t('labelAr')}
          inputName={`entities[${entityIndex}].fields[${fieldIndex}].label[ar]`}
          inputPlaceholder={`${t('ex')} الخانه الاوله`}
          inputError={formik?.errors?.entities?.[entityIndex]?.fields?.[fieldIndex]?.label?.ar as string}
          inputTouched={formik?.touched?.entities?.[entityIndex]?.fields?.[fieldIndex]?.label?.ar as boolean}
          input
          // value={formik?.values?.minimum_age}
          customPadding
          // type="number"
        />
      </div>
      <div className="inputs_group">
        <div className="inputs_group">
          <FieldWrapper title={t('validation')}>
            <div className="checkboxes_wrapper">
              {validationDataCheckBoxes?.map((item: any, i: number) => (
                <Checkbox
                  workSectors={validationDataCheckBoxes}
                  name={`entities[${entityIndex}].fields[${fieldIndex}].validation`}
                  key={i}
                  item={item}
                  // value={formik?.values?.conditions[index]?.work_sectors?.some((checkedItem: string) => checkedItem == item?.value)}
                  // checked={formik?.values?.conditions[index]?.work_sectors}
                  value={formik?.values?.entities?.[entityIndex]?.fields[fieldIndex]?.validation?.some((checkedItem: string) => checkedItem == item?.value)}
                  checked={formik?.values?.entities?.[entityIndex]?.fields[fieldIndex]?.validation}
                  formik={formik}
                />
              ))}
              {/* <label>
                            <input
                                checked={formik?.values?.entities?.[entityIndex]?.fields[fieldIndex]?.type == "file" ? true : false}
                                id={`all`}
                                type="checkbox"
                                value={"file"}
                                disabled
                                onChange={(e) => {
                                    const existingValidation = formik.values.entities[entityIndex].fields[fieldIndex].validation;
                                    const newValidation = [...existingValidation, "file"];
                                    formik.setFieldValue(`entities[${entityIndex}].fields[${fieldIndex}].validation`, newValidation);                                   
                                }}
                            />
                            {t("file")}
                        </label> */}
            </div>
          </FieldWrapper>
        </div>
        {
          <div className="inputs_group">
            <FieldWrapper
              title={t('minimumSize')}
              inputName={`entities[${entityIndex}].fields[${fieldIndex}].min`}
              inputPlaceholder={`${t('minimumSize')}`}
              inputError={formik.errors.maximum_age as string}
              inputTouched={formik.touched.minimum_age as boolean}
              value={formik.values.entities[entityIndex].fields[fieldIndex].min}
              input
              customPadding
              disabled={formik?.values?.entities[entityIndex]?.fields[fieldIndex]?.type == 'file' ? false : true}
              type="number"
            />
            <FieldWrapper
              title={t('maximumSize')}
              inputName={`entities[${entityIndex}].fields[${fieldIndex}].max`}
              inputPlaceholder={`${t('maximumSize')}`}
              inputError={formik.errors.maximum_age as string}
              inputTouched={formik.touched.minimum_age as boolean}
              input
              // value={formik?.values?.minimum_age}
              customPadding
              disabled={formik?.values?.entities[entityIndex]?.fields[fieldIndex]?.type == 'file' ? false : true}
              type="number"
            />
          </div>
        }
      </div>
    </div>
  );
};

export default FieldInputs;
