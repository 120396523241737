import { axisClasses, BarChart, LineChart } from "@mui/x-charts";
import ListHeader from "../../../components/ListHeader";
import TopData from "./TopData";
import { useMemo } from "react";
import { getChartOptions } from "../utils/HelperFunctions";
import ChartDirection from "./ChartDirection";
import { useTranslation } from "react-i18next";

const ProvidersData = ({ selectedProvider, providersData, setSelectedProvider, singleProviderDataSet }: any) => {

    const { t } = useTranslation()
    const topData = useMemo(() => {
        return providersData?.map((item: any) => (
            {
                title: item.name,
                number: `${item?.total_value.toLocaleString('en-US')}`,
                id: item.id
            }
        ))
    }, [providersData])

    const dataSet = useMemo(() => {
        const rateData: { x: string, value: string }[] = []
        providersData?.map((item: {
            name: string,
            approval_rate: string
        }) => {
            rateData.push({ x: item.name, value: item?.approval_rate })
        })

        const cellingData: { x: string, totalValue: number, limitValue: number }[] = []
        providersData?.map((item: {
            name: string,
            total_limit: number,
            limit_used: number
        }) => {
            cellingData.push({
                x: item.name,
                totalValue: item?.total_limit - item?.limit_used,
                limitValue: item?.limit_used

            })
        })
        return { rateData, cellingData }
    }, [providersData])

    return (
        <>
            <ListHeader customClass="row noMargin">
                <h2>{t("dashboardCharts.service_providers")}</h2>
            </ListHeader>
            <div className={`dashboard-data no-border`}>
                <div className="charts">
                    <ChartDirection>
                        <BarChart
                            dataset={dataSet.cellingData}
                            xAxis={[
                                {
                                    scaleType: 'band', dataKey: 'x', tickPlacement: "middle",
                                },
                            ]}
                            series={[
                                {
                                    dataKey: 'limitValue',
                                    label: t("dashboardCharts.limit_used"),
                                    color: '#3498db',
                                    stack: 'limitStack',
                                    valueFormatter: (value: any) => `${value.toLocaleString('en-US')}${t("sar")}`
                                },
                                {
                                    dataKey: 'totalValue',
                                    label: t("dashboardCharts.limit_remaining"),
                                    color: '#000000',
                                    stack: 'limitStack',
                                    valueFormatter: (value: any) => `${value.toLocaleString('en-US')}${t("sar")}`
                                },
                            ]}
                            height={350}
                            margin={{
                                right: 20,
                                left: 80,
                            }}
                        />
                        <BarChart
                            dataset={dataSet?.rateData}
                            xAxis={[
                                {
                                    scaleType: 'band', dataKey: 'x', tickPlacement: "middle",
                                    colorMap: {
                                        type: 'piecewise',
                                        thresholds: [new Date(2021, 1, 1), new Date(2023, 1, 1)],
                                        colors: ['#000'],
                                    }
                                },
                            ]}
                            {...getChartOptions(t("dashboardCharts.approval_rate"), t("dashboardCharts.rate"))}
                        />
                    </ChartDirection>

                </div>
            </div>
            <div className="dashboard-data">

                <div className="top-dashboard-data" >
                    <TopData
                        setSelectedProvider={setSelectedProvider}
                        data={topData}
                        header={t("dashboardCharts.top_providers")}
                        selectedProvider={selectedProvider}
                    />
                </div>
                <div className="charts">
                    {selectedProvider.title &&
                        <ChartDirection>
                            <LineChart
                                dataset={singleProviderDataSet}
                                xAxis={[
                                    {
                                        scaleType: 'band', dataKey: 'x', tickPlacement: "middle",

                                    },
                                ]}
                                yAxis={[
                                    { label: selectedProvider?.title + t("dashboardCharts.payments") }
                                ]}
                                series={[
                                    {
                                        dataKey: 'value',
                                        label: t("dashboardCharts.applications_value"),
                                        color: '#000000',
                                        valueFormatter: (value: any) => `${value?.toLocaleString('en-US')}${t("sar")}`
                                    },
                                    {
                                        dataKey: 'other_value',
                                        label: t("dashboardCharts.paid_value"),
                                        color: '#3498db',
                                        valueFormatter: (value: any) => `${value?.toLocaleString('en-US')}${t("sar")}`
                                    },
                                ]}
                                height={350}
                                margin={{
                                    right: 20,
                                    left: 80,
                                }}
                                sx={
                                    {
                                        [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
                                            transform: 'translateX(-25px)',
                                        },
                                    }
                                }
                            />
                        </ChartDirection>
                    }

                </div>
            </div>
        </>
    );
}

export default ProvidersData;