import { useSelector } from "react-redux";
import ProductCard from "./ProductCard";
import Button from "../../../../components/buttons/Button";
import { useTranslation } from "react-i18next";
import ListHeader from "../../../../components/ListHeader";
import FilterModal from "./FilterModal";
import { useState } from "react";

interface IProps {
    setActiveStep: React.Dispatch<React.SetStateAction<number>>
}
const SearchResults = ({ setActiveStep }: IProps) => {

    const { searchResult } = useSelector((store: any) => store.findDeal)
    const { selectedProducts } = useSelector((store: any) => store.findDeal)
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)

    return (
        <div className="search-results">
            {searchResult.length == 0 ? <div className="no-data">
                <p>{t("sorry")}</p>
                <span>{t("we_could't_find_result")} <span className="link" onClick={() => setActiveStep(0)}>{t("search_criteria")}</span></span>
            </div>
                :
                <>
                    <ListHeader customClass="row">
                        <h4>{t("upTo3Products")}</h4>
                        <span onClick={() => setOpen(true)} className="filter">{t("filter_results")}
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4 3h16a1 1 0 0 1 1 1v1.586a1 1 0 0 1-.293.707l-6.414 6.414a1 1 0 0 0-.293.707v6.305a1 1 0 0 1-1.243.97l-2-.5a1 1 0 0 1-.757-.97v-5.805a1 1 0 0 0-.293-.707L3.293 6.293A1 1 0 0 1 3 5.586V4a1 1 0 0 1 1-1" /></svg>

                        </span>

                    </ListHeader>
                    <div className="products-container">
                        {searchResult?.map((item: any, index: number) => (
                            <ProductCard key={index} item={item} />
                        )
                        )}
                        <form >
                            <div className="form_button reverse">
                                <Button
                                    disabled={selectedProducts.length == 0}
                                    onClick={() => { selectedProducts.length > 0 && setActiveStep(2) }}
                                    text={t("next")} type={'button'} ></Button>
                                <Button
                                    onClick={() => { setActiveStep(0) }}
                                    text={t("prev")} type={'button'} ></Button>
                            </div>
                        </form>
                    </div>
                    <div className={`filter-menu ${open ? "open" : ""}`}>
                        <FilterModal
                            open={open}
                            setOpen={setOpen}
                        />
                    </div>
                </>
            }

        </div>
    );
}

export default SearchResults;