import React, { useContext, useState } from 'react';
import Button from './buttons/Button';
import { generalCreate, generalDelete } from '../API/api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { authContext } from '../store/context/authContext';

export default function DeleteModal({ id, setModal, setRefetchData, route, successMsg, warningMsg, post, user }: any) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { catchError } = useContext(authContext);

  function handleDelete() {
    setLoading(true);

    post
      ? generalCreate({
          route: `${route}`,
          values: {}
        })
          .then((res) => {
            setLoading(false);
            setRefetchData(`deleted_${Date.now()}`);
            setModal(false);
            toast.success(successMsg);
          })
          .catch((error) => {
            catchError(error, setLoading);
          })
      : generalDelete(user ? `${route}` : `${route}/${id}`)
          .then((res) => {
            setLoading(false);
            setRefetchData(`deleted_${Date.now()}`);
            setModal(false);
            toast.success(successMsg);
          })
          .catch((error) => {
            catchError(error, setLoading);
          });
  }

  return (
    <div className="common-modal delete">
      <h4>{warningMsg}</h4>
      <div className="buttons">
        <Button loading={loading} onClick={handleDelete}>
          <span className="bold">Confirm</span>
        </Button>
        <Button type={'submit'} onClick={() => setModal(false)}>
          Cancel
        </Button>
      </div>
    </div>
  );
}
