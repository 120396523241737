import { useEffect, useRef, useState } from "react";
import AdminNotificationsDropDown from "./AdminNotificationsDropDown";
import { useSelector } from "react-redux";

const AdminNotificationsBtn = () => {
    const [dropDownToggler, setDropDownToggler] = useState(false);
    const el = useRef<HTMLDivElement>(null);
    const { notificationsData } = useSelector((store: any) => store)

    const handleOutsideClick = (e: MouseEvent) => {
        if (el.current && !el.current.contains(e.target as Node)) {
            setDropDownToggler(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <div className="admin_notifications_btn admin_btn" ref={el}>
            <div className="icon_container" onClick={() => setDropDownToggler(!dropDownToggler)}>
                <svg fill="#1C2E45" fillOpacity="0.6" width="22" height="22" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M440.08,341.31c-1.66-2-3.29-4-4.89-5.93-22-26.61-35.31-42.67-35.31-118,0-39-9.33-71-27.72-95-13.56-17.73-31.89-31.18-56.05-41.12a3,3,0,0,1-.82-.67C306.6,51.49,282.82,32,256,32s-50.59,19.49-59.28,48.56a3.13,3.13,0,0,1-.81.65c-56.38,23.21-83.78,67.74-83.78,136.14,0,75.36-13.29,91.42-35.31,118-1.6,1.93-3.23,3.89-4.89,5.93a35.16,35.16,0,0,0-4.65,37.62c6.17,13,19.32,21.07,34.33,21.07H410.5c14.94,0,28-8.06,34.19-21A35.17,35.17,0,0,0,440.08,341.31Z"/>
                    <path d="M256,480a80.06,80.06,0,0,0,70.44-42.13,4,4,0,0,0-3.54-5.87H189.12a4,4,0,0,0-3.55,5.87A80.06,80.06,0,0,0,256,480Z"/>
                </svg>
                {!!notificationsData?.unReadNotificationsCount && <div className="count_number">{notificationsData?.unReadNotificationsCount}</div>}
            </div>
            <AdminNotificationsDropDown dropDownToggler={dropDownToggler} setDropDownToggler={setDropDownToggler}/>
        </div>
    );
}

export default AdminNotificationsBtn;