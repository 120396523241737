import { useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import 'react-phone-number-input/style.css';
import { toast } from 'react-toastify';
import CloseButton from '../../../components/buttons/CloseButton';
import FieldWrapper from '../../../components/formInputs/FieldWrapper';
import Button from '../../../components/buttons/Button';
import { generalCreate } from '../../../API/api';
import { authContext } from '../../../store/context/authContext';
import { TOptions } from '../../../types/types';
import { customStyles } from '../../../utils/SelectStyles';
import { IServiceUser } from '../types/interfaces';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const CreateUserForm = ({ setOpen, setRefetch, serviceProviderId, setUserData, userData, userId, setUserId, distributorId, distributor }: any) => {
  const [loading, setLoading] = useState(false);
  const [loadingVerification, setLoadingVerification] = useState(false);
  const { catchError } = useContext(authContext);
  const { t } = useTranslation();
  const { roles } = useSelector((store: any) => store.rolesData);

  const getRouteDependingOnUserType = () => {
    let route = '';
    if (!userId && serviceProviderId) {
      route = `/service-providers/${serviceProviderId}/add-user`;
    } else if (!userId && distributorId) {
      route = `/distributors/${distributorId}/add-user`;
    } else {
      route = `/users/${userId}/edit`;
    }
    return route;
  };

  const handleResendVerification = async () => {
    setLoadingVerification(true)
    generalCreate({
      route: `/resend-verification/${userData?.email}`
    })
      .then((res) => {
        setLoadingVerification(false);
        toast.success(res?.data?.message);
      })
      .catch((error) => {
        setLoadingVerification(false);
        catchError(error, setLoadingVerification);
      });
  };

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .required(t('required'))
      .matches(/^[^ ]\S*/, t('remove_space')),
    last_name: Yup.string()
      .required(t('required'))
      .matches(/^[^ ]\S*/, t('remove_space')),
    city: Yup.string()
      .required(t('required'))
      .matches(/^[^ ]\S*/, t('remove_space')),
    profession: Yup.string()
      .required(t('required'))
      .matches(/^[^ ]\S*/, t('remove_space')),
    role_id: Yup.string().required(t('required')),
    email: Yup.string()
      .email(t('email_format'))
      .required(t('required'))
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t('email_format')),
    mobile: Yup.string()
      .required(t('required'))
      .matches(/^05[0-9]{8}$/, t('wrong_number'))
    // ...(userId == null
    //   ? {
    //       password: Yup.string()
    //         .required(t('required'))
    //         .matches(/^[^ ]\S*$/, t('remove_space')) // No spaces allowed
    //         .min(8, t('password_must')) // Minimum length of 8 characters
    //         .matches(/[A-Z]/, t('must_contain_uppercase')) // At least one uppercase letter
    //         .matches(/[a-z]/, t('must_contain_lowercase')) // At least one lowercase letter
    //         .matches(/\d/, t('must_contain_number')) // At least one number
    //         .matches(/[@$!%*?&]/, t('must_contain_special_char')),
    //       password_confirmation: Yup.string()
    //         .required(t('required'))
    //         .oneOf([Yup.ref('password')], t('passwords_mismatch'))
    //     }
    //   : {})
  });

  return (
    <div className="create_user_form modal_content">
      <Formik
        enableReinitialize
        validateOnMount
        validationSchema={validationSchema}
        initialValues={{
          first_name: userData?.first_name || '',
          last_name: userData?.last_name || '',
          email: userData?.email || '',
          mobile: userData?.mobile || '',
          city: 'Cairo',
          profession: userData?.profession || '',
          role_id: userData?.role_id || ''
        }}
        onSubmit={(values) => {
          setLoading(true);
          let createValues = { users: [{ ...values, mobile: values?.mobile.toString() }], role_id: values?.role_id };
          // if (userId && values.password === '') {
          //   delete (values as Partial<IServiceUser>).password;
          //   delete (values as Partial<IServiceUser>).password_confirmation;
          // }
          let updateValues = { ...values, mobile: values?.mobile.toString(), _method: 'PUT' };
          // let route = !userId ? `/service-providers/${serviceProviderId}/add-user` : `/users/${userId}/edit`;
          generalCreate({
            route: getRouteDependingOnUserType(),
            values: userId ? { ...updateValues } : { ...createValues }
          })
            .then((res) => {
              setLoading(false);
              setOpen(false);
              setUserData(null);
              setUserId(null);
              toast.success(`${t('user')} ${!userId ? t('created') : t('updated')} ${t('successfully')}`);
              setRefetch(new Date());
            })
            .catch((error) => {
              catchError(error, setLoading);
            });
        }}
      >
        {(formik) => (
          <>
            <Form>
              <div className="form_header">
                <h4 className="header">{t('user_information')}</h4>
                <CloseButton
                  handleClose={() => {
                    setOpen(false);
                    setUserData(null);
                    setUserId(null);
                  }}
                />
              </div>
              <div className="form_inputs">
                <div className="inputs_group">
                  <FieldWrapper
                    title={t('first_name')}
                    inputName={'first_name'}
                    inputPlaceholder={t('first_name')}
                    inputError={formik.errors.first_name as string}
                    inputTouched={formik.touched.first_name as boolean}
                    input
                  />
                  <FieldWrapper
                    title={t('last_name')}
                    inputName={'last_name'}
                    inputPlaceholder={t('last_name')}
                    inputError={formik.errors.last_name as string}
                    inputTouched={formik.touched.last_name as boolean}
                    input
                  />
                </div>
                <div className="inputs_group">
                  <FieldWrapper
                    title={t('email')}
                    inputName={'email'}
                    inputPlaceholder={t('email')}
                    inputError={formik.errors.email as string}
                    inputTouched={formik.touched.email as boolean}
                    input
                  />
                  <FieldWrapper
                    title={t('phone')}
                    inputName={'mobile'}
                    inputPlaceholder={`${t('ex')}: 0511111111`}
                    inputError={formik.errors.mobile as string}
                    inputTouched={formik.touched.mobile as boolean}
                    input
                  />
                </div>

                <div className="inputs_group">
                  {/* <FieldWrapper
                    title={t("city")}
                    inputName={"city"}
                    inputPlaceholder={t("city")}
                    inputError={formik.errors.city as string}
                    inputTouched={formik.touched.city as boolean}
                    input
                  /> */}
                  <FieldWrapper
                    title={t('profession')}
                    inputName={'profession'}
                    inputPlaceholder={t('profession')}
                    inputError={formik.errors.profession as string}
                    inputTouched={formik.touched.profession as boolean}
                    input
                  />
                  <FieldWrapper noPadding />
                </div>
                <div className="inputs_group">
                  <FieldWrapper
                    customPadding
                    title={t('user_role')}
                    inputPlaceholder={t('user_role')}
                    inputName={'role_id'}
                    inputError={formik.errors.role_id as string}
                    inputTouched={formik.touched.role_id as boolean}
                    options={roles.filter((role: any) => role.label.includes(distributor ? 'distributor' : 'provider'))}
                    onChange={(e) => {
                      formik.setFieldValue('role_id', (e as TOptions).value?.toString());
                    }}
                    key={userData?.role_id || 'role_id'}
                    defaultValue={userData && roles.find((item: TOptions) => Number(item.value) == userData?.role_id)}
                    selectStyle={customStyles}
                    select
                  />
                  <FieldWrapper noPadding />
                </div>
              </div>
              <div className="form_footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button type={loading ? 'button' : 'submit'} loading={loading}>
                  <span className="bold">{t('save')}</span>
                </Button>
                {userData && !userData?.is_verified && (
                  <Button type={'button'} loading={loadingVerification} onClick={handleResendVerification}>
                    <span className="bold">{t('resendVerification')}</span>
                  </Button>
                )}
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default CreateUserForm;
