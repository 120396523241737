import { useContext, useEffect, useState } from 'react';
import { authContext } from '../../../store/context/authContext';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setBreadCrumbsData } from '../../../store/redux/breadCrumbsData';
import FormSteps from '../../../components/FormSteps';
import { generalGet } from '../../../API/api';
import { useQuery } from '@tanstack/react-query';
import SingleStep from '../../../modules/applications/components/SingleStep';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/buttons/Button';
import ModalContainer from '../../../components/ModalContainer';
import { approvedStepIcon, pendingStepIcon, rejectedStepIcon } from '../../../config/variables';
import InitialLoader from '../../../components/loaders/InitialLoader';
import ApplicationStatusModal from '../../../modules/applications/components/ApplicationStatusModal';
import ProductFormSteps from '../../../modules/applications/components/ProductFormSteps';
import { set } from 'date-fns';
import { ICurrentStep } from '../../../modules/applications/types/interfaces';
import Loader from '../../../modules/dashboard/components/Loader';
import { is } from 'date-fns/locale';
import PoQuotation from './PoQuotation';
import AuditTable from '../../../modules/applications/components/AuditTable';
import ListHeader from '../../../components/ListHeader';

const ProductSteps = () => {
  const { catchError } = useContext(authContext);
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [currentStep, setCurrentStep] = useState<ICurrentStep>();
  const [activeStep, setActiveStep] = useState<string>('');
  const [singleStepData, setSingleStepData] = useState<any>();
  const [openModal, setOpenModal] = useState<boolean | number>(true);
  const [openStatusModal, setOpenStatusModal] = useState<boolean>(false);
  const [refetch, setRefetch] = useState(false);
  const [steps, setSteps] = useState<{ name: { en: string; ar: string }; id: string; order: number; is_current_step?: boolean; status?: { value: number } }[]>([]);
  const [nextStep, setNextStep] = useState<string>('');
  const [unauthorized, setUnauthorized] = useState(false);
  const [isApplicationAccepted, setIsApplicationAccepted] = useState(false);
  const dispatch = useDispatch();
  const [refetchAfterSubmit, setRefetchAfterSubmit] = useState<Date | null>(null);

  const auditTableHeaders = [
    { label: t('user'), customClass: 'text-center' },
    { label: t('date'), customClass: 'text-center small' },
    { label: t('time'), customClass: 'text-center small' },
    { label: t('action'), customClass: 'text-center extra-width' }
  ];

  console.log(singleStepData?.audits, 'sss');

  dispatch(
    setBreadCrumbsData({
      page_title: t('steps')
    })
  );

  const isApplicationAcceptedHandler = () => {
    const stepsLength = steps?.length;
    const lastStep = steps?.find((step) => Number(step.order) == stepsLength);

    if (lastStep?.is_current_step && lastStep?.status?.value === 2) {
      setIsApplicationAccepted(true);
    } else {
      setIsApplicationAccepted(false);
    }
  };

  const { data, isSuccess, isLoading, error } = useQuery({
    queryKey: ['product steps', i18n, refetch, id, refetchAfterSubmit],
    queryFn: () => generalGet('/application-products/' + id + '/steps'),
    refetchOnWindowFocus: false
  });
  const {
    data: stepData,
    isSuccess: isSuccessStepData,
    isLoading: isLoadingStepData,
    error: errorStepData
  } = useQuery({
    queryKey: ['stepData', i18n, refetch, activeStep, id, refetchAfterSubmit],
    queryFn: () => generalGet('/application-products/' + id + '/steps' + '/' + activeStep),
    refetchOnWindowFocus: false,
    enabled: activeStep !== 'po' && !!activeStep
  });
  const {
    data: ApplicationProductData,
    isSuccess: isSuccessApplicationProductData,
    isLoading: isLoadingApplicationProductData,
    error: errorApplicationProductData
  } = useQuery({
    queryKey: ['ApplicationProductData', i18n, refetch, activeStep, id, refetchAfterSubmit],
    queryFn: () => generalGet('/application-products/' + id),
    refetchOnWindowFocus: false,
    enabled: !!activeStep
  });

  useEffect(() => {
    error && catchError(error);
    errorStepData && catchError(errorStepData);
    errorApplicationProductData && catchError(errorApplicationProductData);
  }, [error, errorStepData, errorApplicationProductData]);

  useEffect(() => {
    const reqData = data?.data?.data;
    const sortedSteps = reqData?.sort((a: any, b: any) => a.order - b.order);

    setSteps(sortedSteps);

    const currentStepInfo = sortedSteps?.filter((step: { is_current_step?: boolean }) => {
      return step?.is_current_step;
    });

    currentStepInfo && setCurrentStep(currentStepInfo[0]);
  }, [isSuccess, data]);

  useEffect(() => {
    const reqData = stepData?.data?.data;

    setSingleStepData(reqData);
  }, [isSuccessStepData, stepData]);
  useEffect(() => {
    currentStep && setActiveStep(currentStep?.id);
  }, [currentStep]);

  useEffect(() => {
    const findNextStep = steps?.slice(steps?.findIndex((step) => step.id === activeStep) + 1);
    setNextStep(findNextStep[0]?.id);
  }, [activeStep]);

  useEffect(() => {
    const findNextStep = steps?.slice(steps?.findIndex((step) => step.id === activeStep) + 1);
    setNextStep(findNextStep[0]?.id);
  }, [activeStep]);

  useEffect(() => {
    isApplicationAcceptedHandler();
  }, [steps]);

  if (isLoadingStepData || !activeStep) return <Loader />;
  return (
    <div className="page_content">
      <div className="form-steps-content">
        <ProductFormSteps steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} currentStep={currentStep} setCurrentStep={setCurrentStep} />
        <div className={`form `}>
          {/* {activeStep == 0 && <SearchForDeal setActiveStep={setActiveStep} />} */}
          {singleStepData && currentStep ? (
            <SingleStep
              // key={index}
              data={singleStepData}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              openModal={setOpenStatusModal}
              nextStepId={nextStep}
              isCurrentStep={currentStep?.id === singleStepData?.id}
              appId={currentStep?.application_request_id}
              setUnauthorized={setUnauthorized}
              isApplicationAccepted={isApplicationAccepted}
              setRefetchAfterSubmit={setRefetchAfterSubmit}
            />
          ) : (
            <PoQuotation poUrl={ApplicationProductData?.data?.data?.po} quotationUrl={ApplicationProductData?.data?.data?.quotation} />
          )}
        </div>
      </div>
      {/* {!isApplicationAccepted && openModal && currentStep?.id === activeStep && (
        <ModalContainer small>
          <div className="common-modal status">
            <div className="statusIcon">
              {currentStep?.status?.value === 1 && pendingStepIcon}
              {currentStep?.status?.value === 2 && approvedStepIcon}
              {currentStep?.status?.value === 3 && rejectedStepIcon}
            </div>
            <p>{currentStep?.message?.[i18n.language as 'en' | 'ar']} </p>
            <div className="buttons">
              <Button
                onClick={() => {
                  setOpenModal(false);
                  window.scroll(0, 0);
                  // navigate(`/applications`)
                }}
              >
                <span className="bold">{t('close')}</span>
              </Button>
            </div>
          </div>
        </ModalContainer>
      )} */}
      {openStatusModal && singleStepData && (
        <ModalContainer small overflow>
          <ApplicationStatusModal
            setRefetchData={setRefetch}
            setModal={setOpenStatusModal}
            successMsg={t('statusChanged')}
            warningMsg={t('changeAppStatus')}
            pId={id}
            currentStatus={currentStep?.status?.value}
            application_ID={currentStep?.application_request_id}
            // isLastStep={productStepsData?.current_step?.id === productStepsData?.steps[productStepsData?.steps.length - 1]?.id}
          />
        </ModalContainer>
      )}
      {singleStepData && (
        <div className="table-wrapper" style={{paddingTop:"2rem"}}>
          <ListHeader customClass="row">
            <h2>{t('auditTrail')}</h2>
          </ListHeader>
          <AuditTable tableHeaders={auditTableHeaders} data={singleStepData.audits} noDataMessage={t('noDataFound')} Audit  lessColumns/>
        </div>
      )}
      {/* {unauthorized && (
        <ModalContainer small>
          <div className="common-modal status">
            <h4>{`${t('unauthorizedMsg')}`}</h4>

            <div className="buttons">
              <Button
                onClick={() => {
                  setUnauthorized(false);
                  window.scroll(0, 0);
                  // navigate(`/applications`)
                }}
              >
                <span className="bold">{t('close')}</span>
              </Button>
            </div>
          </div>
        </ModalContainer>
      )} */}
    </div>
  );
};

export default ProductSteps;
