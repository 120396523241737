import { configureStore } from "@reduxjs/toolkit"
import breadCrumbsData from "./breadCrumbsData"
import notificationsData from "./notificationsData"
import rolesData from "./rolesData"
import authData from "../../modules/auth/store/redux/authData"
import findDeal from "../../modules/findDeal/store/redux"

export const store = configureStore({
	reducer: {
		breadCrumbsData,
		authData,
		rolesData,
		notificationsData,
		findDeal,
	},
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
