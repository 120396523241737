import { IButtonProps } from '../../types/Interfaces';

const Button = ({ text, customClass, type, children, onClick, disabled, noScroll, loading, noScrollUp, commentBtn }: IButtonProps) => {
  return (
    <button
      className={`button_container ${customClass && customClass} ${disabled && 'disabled'} ${loading && 'loading'}`}
      type={type && type}
      onClick={(e) => {
        commentBtn && e.stopPropagation();
        // e.preventDefault()
        const contentContainer = document.querySelector('.layout_content');
        !noScrollUp && contentContainer?.scrollTo({ top: 0, behavior: 'smooth' });
        commentBtn && onClick && e.preventDefault();
        onClick && onClick();
        // !noScroll && window.scroll(0, 0)
      }}
      disabled={disabled}
    >
      {loading ? (
        <div className="loader-page login">
          <div className="submit_loading_container"></div>
        </div>
      ) : (
        <>
          {text && <span className="bold">{text}</span>}
          {children}
        </>
      )}
    </button>
  );
};

export default Button;
