const Loader = () => {
    return (
        <div className="page_content service_details_page">
            <div className={`dashboard`} id="loader">
                <div className="loader-container">
                    <div className="spinner-loader"></div>
                </div>
            </div>
        </div>
    );
}

export default Loader;