import { createSlice } from '@reduxjs/toolkit';
import { IFormData, ISearchProduct } from '../types/interface';
import { IProductData } from '../../products/types/interfaces';

export interface IState {
  searchData: ISearchProduct;
  selectedProducts: IProductData[];
  searchResult: IProductData[];
  formData: IFormData;
  filterAvg: [];
  count: number | null;
}

const initialState: IState = {
  searchData: {
    vehicle_model_id: '',
    variant_id: '',
    price: '',
    work_sector_id: '',
    nationality_id: '',
    salary_transfer_to: '',
    age: '',
    salary: '',
    down_payment: '',
    finance_duration: '',
    service_period: '',
    balloon_percentage: '0'
    // price: "10000",
    // work_sector_id: "9",
    // nationality: "saudi",
    // salary_transfer_to: "5",
    // age: "27",
    // salary: "30000",
    // down_payment: "10",
    // finance_duration: "1",
    // service_period: "10",
    // balloon_percentage: "0",
    // car_make: "4",
    // vehicle_model_id: "22",
    // variant_id: "50",
  },
  formData: {},
  selectedProducts: [],
  searchResult: [],
  filterAvg: [],
  count: null
};
const dealSlice = createSlice({
  name: 'findDeal',
  initialState,
  reducers: {
    setSearchData: (state, action) => {
      state.searchData = action.payload;
    },
    setFilterAvg: (state, action) => {
      state.filterAvg = action.payload;
    },
    setSelectedProducts: (state, action) => {
      if (action.payload.length == 0) {
        state.selectedProducts = [];
        return;
      }
      const { id, duration_to } = action.payload;
      let exist = state.selectedProducts.find((item: any) => item.duration_to === duration_to && item.id === id);
      if (exist) {
        state.selectedProducts = state.selectedProducts.filter((item: any) => {
          return item.duration_to !== duration_to || item.id !== id;
        });
      } else {
        state.selectedProducts.push(action.payload);
      }
    },
    setSearchResult: (state, action) => {
      state.searchResult = action.payload;
    },
    moveSelectedProduct: (state, action) => {
      const { fromIndex, toIndex } = action.payload;
      const product = state.selectedProducts[fromIndex];
      state.selectedProducts.splice(fromIndex, 1);
      state.selectedProducts.splice(toIndex, 0, product);
    },
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    resetFormData: (state, action) => {
      state.formData = {};
    }
  }
});

export const { 
	moveSelectedProduct, 
	setFilterAvg,
	 setSearchResult, 
	 setSearchData, 
	 setFormData, 
	 resetFormData,
	 setSelectedProducts, 
	setCount } = dealSlice.actions;
export default dealSlice.reducer;
