import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setBreadCrumbsData } from "../../../store/redux/breadCrumbsData";
import { useQuery } from "@tanstack/react-query";
import { generalGet } from "../../../API/api";
import TableSkeleton from "../../../components/loaders/TableSkeleton";
import { useContext, useEffect, useState } from "react";
import { authContext } from "../../../store/context/authContext";
import RolesTableContainer from "../../../modules/roles/components/RolesTableContainer";

const RolesList = () => {

    const dispatch = useDispatch()
    const { t, i18n } = useTranslation()
    const [refetch, setRefetch] = useState(false)
    const [rolesData, setRolesData] = useState([])
    const { catchError } = useContext(authContext)

    dispatch(setBreadCrumbsData({
        links: [{ label: "Roles", path: "/roles" }],
        page_title: "Roles",
    }))

    const { data, isSuccess, isLoading, error } = useQuery({
        queryKey: ["roles list", refetch, i18n],
        queryFn: () => generalGet(`/admin/roles`),
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        isSuccess && setRolesData(data.data.data)
    }, [isSuccess, data])

    useEffect(() => {
        error && catchError(error)
    }, [error])


    const tableHeaders = [
        { label: t("name") },
        { label: t("actions"), customClass: "actions_col" },
    ];

    if (isLoading) return <TableSkeleton columns={6} withoutButton withoutHeader />

    return (
        <div className="distributors-page-container">
            <RolesTableContainer
                tableHeaders={tableHeaders}
                data={rolesData}
                noDataMessage={t("No_users_found")}
                border
                setRefetchData={setRefetch}
            />
        </div>
    );
}

export default RolesList;