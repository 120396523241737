import { useTranslation } from 'react-i18next';
import FieldWrapper from '../../../../components/formInputs/FieldWrapper';
import SectionHeader from '../../../../components/SectionHeader';
import { Dispatch, FocusEvent, useEffect, useRef, useState } from 'react';
import { TOptions } from '../../../../types/types';
import { ICarData, ICarModel, ICarVariant } from '../../../products/types/interfaces';
import { customStyles } from '../../../../utils/SelectStyles';
import { FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { setCount, setFormData } from '../../store/redux';
import { generalGet } from '../../../../API/api';

interface IProps {
  formik: FormikProps<any>;
  carData: ICarData[];
  carMakeOptions: TOptions[];
  setLoading: Dispatch<React.SetStateAction<boolean>>;
}
interface IModelOptions {
  label: string | null;
  value: string | null;
  variants: ICarVariant[];
}
const CarDetailsForm = ({ formik, carData, carMakeOptions, setLoading }: IProps) => {
  const { t } = useTranslation();
  const modelRef = useRef<any>(null);
  const variantRef = useRef<any>(null);
  const [carModelOptions, setCarModelOptions] = useState<IModelOptions[]>([]);
  const [carVariantOptions, setCarVariantOptions] = useState<TOptions[]>([]);
  const { searchData } = useSelector((store: any) => store.findDeal);
  const { formData } = useSelector((store: any) => store.findDeal);
  const debounceTimeoutRef = useRef<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchData?.car_make) {
      const car = carData?.find((car: ICarData) => car.id == searchData?.car_make);
      const data: IModelOptions[] = [];
      car?.models?.map((car: ICarModel) => {
        data.push({ label: car.name, value: car.id, variants: car.variants });
      });
      setCarModelOptions(data);
    }
  }, [carData, searchData]);

  useEffect(() => {
    if (searchData?.car_make) {
      const variants: TOptions[] = [];
      const selectedModel = carModelOptions?.find((car: TOptions) => car.value == searchData.vehicle_model_id);
      selectedModel?.variants?.map((car: ICarVariant) => {
        variants.push({ label: car.display_name, value: car.id });
      });
      setCarVariantOptions(variants);
    }
  }, [carModelOptions, searchData]);

  const handleBlur = async (name: string, value: string) => {
    if (value != '') {
      let values = { ...formData, [`filters[${name}]`]: value };
      const data = Object.entries(values)
        .map(([key, value]) => `${key}=${value as string}`)
        .join('&');
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
      debounceTimeoutRef.current = setTimeout(async () => {
        dispatch(
          setFormData({
            [`filters[${name}]`]: value
          })
        );
        generalGet(`/search/products/count?${data}`)
          .then((res) => {
            dispatch(setCount(res.data.data.products_count));
          })
          .catch((err) => console.log(err));
      }, 250);
    }
  };

  return (
    <SectionHeader title={t('car_details')}>
      <div className="inputs_group">
        <FieldWrapper
          customPadding
          title={t('carMake')}
          inputPlaceholder={t('selectCarMake')}
          inputName={`car_make`}
          options={carMakeOptions}
          onChange={(e) => {
            if (modelRef || variantRef) {
              modelRef?.current?.clearValue();
              variantRef?.current?.clearValue();
            }
            formik.setFieldValue('car_make', e?.value);
            const selectedCarMake = carData?.find((car: ICarData) => car.id == e?.value);
            const data: any = [];
            selectedCarMake?.models?.map((car: ICarModel) => {
              data.push({ label: car.name, value: car.id, variants: car.variants });
            });
            setCarModelOptions(data);
          }}
          selectStyle={customStyles}
          select
          defaultValue={(searchData?.car_make as any) && carMakeOptions?.find((option) => option.value == searchData?.car_make)}
          key={(searchData?.car_make as any) && carMakeOptions?.find((option) => option.value == searchData?.car_make)}
        />
        <FieldWrapper
          customPadding
          title={t('carModel')}
          inputName={`vehicle_model_id`}
          inputError={formik?.errors?.vehicle_model_id as string}
          inputTouched={formik?.touched?.vehicle_model_id as boolean}
          inputPlaceholder={t('selectCarModel')}
          selectRef={modelRef}
          options={carModelOptions}
          onChange={(e: any) => {
            variantRef?.current?.clearValue();
            const data: any = [];
            formik.setFieldValue(`vehicle_model_id`, e?.value);
            const selectedModel = carModelOptions?.find((car: TOptions) => car.value == e?.value);
            selectedModel?.variants?.map((car: ICarVariant) => {
              data.push({ label: car.display_name, value: car.id });
            });
            setCarVariantOptions(data);
            handleBlur('vehicle_model_id', e?.value)
          }}
          selectStyle={customStyles}
          select
          defaultValue={searchData?.vehicle_model_id && carModelOptions?.find((option) => option.value == searchData?.vehicle_model_id)}
          key={carModelOptions?.find((option) => option.value == searchData?.vehicle_model_id)?.label}
        />
        <FieldWrapper
          selectRef={variantRef}
          customPadding
          title={t('car_variant')}
          inputName={`variant_id`}
          inputError={formik?.errors?.variant_id as string}
          inputTouched={formik?.touched?.variant_id as boolean}
          inputPlaceholder={t('select_car_variant')}
          options={carVariantOptions}
          onChange={(e: any) => formik.setFieldValue(`variant_id`, e?.value)}
          selectStyle={customStyles}
          select
          defaultValue={(searchData?.variant_id as any) && carVariantOptions?.find((option) => option.value == searchData?.variant_id)}
          key={(searchData?.variant_id as any) && carVariantOptions?.find((option) => option.value == searchData?.variant_id)}
        />
      </div>
      <div className="inputs_group">
        <FieldWrapper
          title={t('car_price')}
          inputName={`price`}
          inputError={formik?.errors?.price as string}
          inputTouched={formik?.touched?.price as boolean}
          // inputPlaceholder={`${t("ex")} 100000`}
          input
          type="number"
          showUnit
          unit={t('sar')}
          onBlur={(e: FocusEvent<HTMLInputElement>) => handleBlur(e?.target?.name, e?.target?.value)}
        />
        <FieldWrapper noPadding />
        <FieldWrapper noPadding />
      </div>
    </SectionHeader>
  );
};

export default CarDetailsForm;
