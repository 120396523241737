import { useTranslation } from "react-i18next";
import ListHeader from "../../../components/ListHeader";
import TopData from "./TopData";
import { useMemo } from "react";

const DistributorsData = ({ distData }: any) => {

    const { t } = useTranslation()
    const topDistData = useMemo(() => {
        const data = distData?.sort((a: { total_cars: number }, b: { total_cars: number }) => b.total_cars - a.total_cars)
            .map((item: any) =>
            (
                {
                    title: item?.name,
                    number: `${item?.total_cars}`
                }
            )
            )
        return data
    }, [distData])

    return (
        <>
            <ListHeader customClass="row noMargin">
                <h2>{t("dashboardCharts.distributors")}</h2>
            </ListHeader>
            <div className={`dashboard-data ${distData?.length == 0 && "no-dashboard-data"}`}>
                <div className="top-dashboard-data">
                    <>
                        {topDistData?.length > 0 &&
                            <TopData
                                data={topDistData}
                                header={t("dashboardCharts.top_distributors_cars")}
                            />
                        }
                    </>
                </div>
            </div>
        </>
    );
}

export default DistributorsData;