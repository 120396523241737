import { useTranslation } from 'react-i18next';
import Button from '../../../components/buttons/Button';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { IServiceContact } from '../../serviceProvider/types/interfaces';
import { hasPermission } from '../../../utils/HelperFunctions';
import { deleteIcon, editIcon } from '../../../config/variables';
import ModalContainer from '../../../components/ModalContainer';
import DeleteModal from '../../../components/DeleteModal';

const ContactInfo = ({ data, setOpenModel, setContactId, deleteRoute, setRefetchData }: any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [statusName, setStatusName] = useState<string>('');
  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    //TODO: REFACTOR

    <div className={`contact-info-card-wrapper`}>
      {/* {title && (
        <div className={`d-space ${id && 'margin'}`}>
          <h2>{title}</h2>
          {id && !isDesktop && <Button text={id?.text} customClass={btnCustomClass} onClick={() => navigate(id?.route)} />}
        </div>
      )} */}
      <div className="contact-actions">
        {hasPermission(['service_providers.crud']) && (
          <div className="action">
            <div
              className="action_btn edit"
              onClick={(e) => {
                const contentContainer = document.querySelector('.layout_content');
                contentContainer?.scrollTo({ top: 0, behavior: 'smooth' });
                e.stopPropagation();
                data?.id && setContactId(data.id);
                setOpenModel(1);
              }}
            >
              {editIcon}
            </div>
            <div
              className="action_btn delete"
              onClick={(e) => {
                e.stopPropagation();
                setDeleteModalOpen(data?.id);
              }}
            >
              {deleteIcon}
            </div>
          </div>
        )}
      </div>
      <div className="contact-info-card-container">
        <p className="name">{data?.name}</p>
        <p className="profession">{data?.profession}</p>
        <p className="email">{data?.email}</p>
        <p className="mobile">{data?.mobile}</p>
      </div>
      {deleteModalOpen && (
        <ModalContainer small>
          <DeleteModal
            id={deleteModalOpen}
            setModal={setDeleteModalOpen}
            setRefetchData={setRefetchData}
            route={deleteRoute}
            successMsg={'Contact deleted successfully'}
            warningMsg={'Are you sure you want to delete that contact?'}
          />
        </ModalContainer>
      )}
    </div>
  );
};

export default ContactInfo;
