import { useDispatch } from "react-redux";
import { setBreadCrumbsData } from "../../../store/redux/breadCrumbsData";
import { useTranslation } from "react-i18next";
import CreateUserForm from "../../../modules/users/components/CreateUserForm";
import { useParams } from "react-router-dom";

const CreateUser = () => {
    const {id} = useParams();
    const {t}=useTranslation()
    const dispatch=useDispatch()
    dispatch(setBreadCrumbsData({
        links: [{label: t("create_users"),path:"/users/create-user"}],
		page_title: t("create_users"),
        userId: id
    }))
    return (
        <div>
            <CreateUserForm/>
        </div>
    );
}

export default CreateUser;