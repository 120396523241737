import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setBreadCrumbsData } from "../../../store/redux/breadCrumbsData";
import CreateRoleForm from "../../../modules/roles/components/CreateRoleForm";
import { useQuery } from "@tanstack/react-query";
import { generalGet } from "../../../API/api";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { authContext } from "../../../store/context/authContext";
import FormSkeleton from "../../../components/loaders/FormSkeleton";

const CreateRole = () => {

    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { id } = useParams()

    dispatch(setBreadCrumbsData({
        links: [{ label: "Create Role", path: "/roles/create-role" }],
        page_title: "Create Role",
    }))

    const { data, isSuccess, isLoading, error } = useQuery({
        queryKey: ["single role data", id],
        queryFn: () => generalGet(`/admin/roles`),
        refetchOnWindowFocus: false,
        enabled: !!id
    });

    const [roleData, setRoleData] = useState([])
    const { catchError } = useContext(authContext)


    useEffect(() => {
        if (isSuccess) {
            setRoleData(data.data.data.find((item: any) => item.id == id))
        }
    }, [isSuccess, data, id])

    useEffect(() => {
        error && catchError(error)
    }, [error])

    if (isLoading) return <FormSkeleton featuredSections={1} offer role />

    return (
        <div>
            <CreateRoleForm
                id={id}
                roleData={roleData}
            />
        </div>
    );
}

export default CreateRole;