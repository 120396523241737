import { useTranslation } from 'react-i18next';
import FieldWrapper from '../../../components/formInputs/FieldWrapper';
import { FormikValues } from 'formik';
import Entities from './Entities';
import { IStepsData } from '../types/interfaces';
import { useQuery } from '@tanstack/react-query';
import { generalGet } from '../../../API/api';
import { useEffect, useState } from 'react';
import { MuiMultiSelectPaperPropsStyles, MuiMultiSelectControlStyles, MuiMultiSelectCheckboxStyles } from '../../../utils/SelectStyles';
import { ro } from 'date-fns/locale';
import { Checkbox, FormControl, FormHelperText, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { useMediaQuery } from 'react-responsive';

const CustomArrowIcon = ({ onClick }: { onClick: () => void }) => (
  <svg className="MuiSelect-iconCustom" height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" onClick={onClick}>
    <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
  </svg>
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MUIProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

interface IProps {
  formik: FormikValues;
}
const StepInputs = ({ formik }: IProps) => {
  const { t, i18n } = useTranslation();
  const [roles, setRoles] = useState<{ name: string; id?: string }[]>();
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedRolesName, setSelectedRolesName] = useState<string[]>([]);
  const [selectedResponsibilities, setSelectedResponsibilities] = useState<string[]>([]);
  const [selectedResponsibilitiesName, setSelectedResponsibilitiesName] = useState<string[]>([]);
  const [openRoles, setOpenRoles] = useState(false);
  const [openResponsibilities, setOpenResponsibilities] = useState(false);
  const isDesktopOrBigger = useMediaQuery({ query: '(min-width: 1024px)' });

  const handleRoleChange = (event: SelectChangeEvent<typeof selectedRoles>) => {
    const {
      target: { value }
    } = event;
    setSelectedRoles(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const handleResponsibilityChange = (event: SelectChangeEvent<typeof selectedResponsibilities>) => {
    const {
      target: { value }
    } = event;
    setSelectedResponsibilities(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const {
    data: rolesData,
    isSuccess: rolesIsSuccess,
    isLoading: rolesIsLoading
  } = useQuery({
    queryKey: ['roles'],
    queryFn: () => generalGet(`/admin/roles`)
    // enabled: !!id,
  });

  useEffect(() => {
    if (roles) {
      setSelectedRoles(formik?.values?.roles || []);

      setSelectedResponsibilities(formik?.values?.responsibilities || []);
    }
  }, [formik, roles]);

  useEffect(() => {
    const reqData = rolesData?.data?.data;

    if (rolesIsSuccess) {
      setRoles(reqData);
    }
  }, [rolesData, rolesIsSuccess]);

  useEffect(() => {
    const selectedRoleNames = selectedRoles?.map((id) => {
      const role = roles?.find((role) => role.id === id);
      return role ? role.name : '';
    });

    setSelectedRolesName(selectedRoleNames);
    formik?.setFieldValue('roles', selectedRoles);
  }, [selectedRoles]);
  useEffect(() => {
    const selectedResponsibilityNames = selectedResponsibilities?.map((id) => {
      const role = roles?.find((role) => role.id === id);
      return role ? role.name : '';
    });

    setSelectedResponsibilitiesName(selectedResponsibilityNames);
    formik?.setFieldValue('responsibilities', selectedResponsibilities);
  }, [selectedResponsibilities]);

  return (
    <div className="step-wrapper">
      <div className="inputs_group">
        <FieldWrapper
          title={t('stepNameEN')}
          inputName={`name[en]`}
          inputPlaceholder={`${t('ex')} ${t('uploadingDocuments')}`}
          inputError={formik?.errors?.name?.en as string}
          inputTouched={formik?.touched?.name?.en as boolean}
          input
          // value={formik?.values?.minimum_age}
          customPadding
          // type="number"
        />
        <FieldWrapper
          title={t('stepNameAR')}
          inputName={`name[ar]`}
          inputPlaceholder={`${t('ex')} ${t('uploadingDocuments')}`}
          inputError={formik?.errors?.name?.ar as string}
          inputTouched={formik?.touched?.name?.ar as boolean}
          input
          // value={formik?.values?.minimum_age}
          customPadding
          // type="number"
        />
        <FieldWrapper
          title={t('order')}
          inputName={`order`}
          inputPlaceholder={`${t('ex')} 1`}
          inputError={formik?.errors?.order as string}
          inputTouched={formik?.touched?.order as boolean}
          input
          // value={formik?.values?.minimum_age}
          customPadding
          type="number"
          // FWcustomStyles={{minWidth: '46.8%'}}
        />
      </div>
      <div className="inputs_group" style={{ justifyContent: 'space-between', gap: 0 }}>
        <div className="field_wrapper_container custom-padding" style={{ maxWidth: isDesktopOrBigger ? '47.9%' : '' }}>
          <div className="header">
            <h5 className="title">{t('roles')}</h5>
          </div>
          <div className="input_wrapper">
            <div className="field">
              <FormControl
                sx={{
                  ...MuiMultiSelectControlStyles,
                  borderColor: Boolean(formik.errors.roles) ? 'rgb(255, 0, 0) !important' : undefined,
                  '& .MuiOutlinedInput-root:hover': {
                    borderColor: formik.touched.roles && Boolean(formik.errors.roles) ? ' red !important' : undefined
                  },
                  '& .MuiOutlinedInput-root': {
                    borderColor: formik.touched.roles && Boolean(formik.errors.roles) ? ' red !important' : undefined,
                    borderRadius: '2.25rem'
                  },
                  '.MuiInputBase-root': { backgroundColor: formik.touched.roles && Boolean(formik.errors.roles) ? '#f7e7e9 !important' : '#fff' },
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: formik.touched.roles && Boolean(formik.errors.roles) ? 'red !important' : undefined,
                    borderWidth: formik.touched.roles && Boolean(formik.errors.roles) ? '1px' : undefined
                  },
                  '& fieldset': {
                    borderColor: '#e5e5e5 !important', // Border color remains static
                    borderWidth: '1px !important' // Default border width
                  },

                  '&:hover fieldset': {
                    borderColor: '#e5e5e5' // No change on hover
                  }
                }}
                error={formik.touched.roles && Boolean(formik.errors.roles)}
              >
                {selectedRoles?.length === 0 && <InputLabel id="demo-multiple-checkbox-label" shrink={false}></InputLabel>}
                <Select
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedRoles}
                  open={openRoles}
                  onOpen={() => setOpenRoles(true)}
                  onClose={() => setOpenRoles(false)}
                  IconComponent={() => <CustomArrowIcon onClick={() => setOpenRoles(!openRoles)} />}
                  onChange={handleRoleChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => selectedRolesName?.join(', ')}
                  // endAdornment={
                  //   <InputAdornment position="end">
                  //     <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg">
                  //       <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                  //     </svg>
                  //   </InputAdornment>
                  // }
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                        width: 150,
                        marginTop: '0.5rem',
                        borderRadius: '8px'
                      }
                    },
                    sx: (theme) => ({ ...MuiMultiSelectPaperPropsStyles })
                  }}
                  placeholder={'Test'}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {roles?.map((role: { name: string; id?: string | undefined }) => (
                    <MenuItem
                      key={role.id}
                      value={role?.id}
                      sx={{ '&.Mui-focusVisible': { backgroundColor: 'transparent' }, '&.Mui-selected': { backgroundColor: 'rgba(0,0,0,0.7) !important', color: '#fff' } }}
                    >
                      <Checkbox sx={MuiMultiSelectCheckboxStyles} checked={selectedRoles?.includes(role.id ?? '')} />
                      <ListItemText primary={role.name} />
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.roles && formik.errors.roles && (
                  <FormHelperText sx={{ color: 'rgb(255, 0, 0) !important', fontSize: '0.85rem', fontFamily: 'PlusJakartaSans-Medium, sans-serif' }}>
                    {formik.errors.roles}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          </div>
        </div>
        <div className="field_wrapper_container custom-padding" style={{ maxWidth: isDesktopOrBigger ? '47.9%' : '' }}>
          <div className="header">
            <h5 className="title">{t('responsibilities')}</h5>
          </div>
          <div className="input_wrapper">
            <div className="field">
              <FormControl
                sx={{
                  ...MuiMultiSelectControlStyles,
                  borderColor: Boolean(formik.errors.roles) ? 'rgb(255, 0, 0) !important' : undefined,
                  '& .MuiOutlinedInput-root:hover': {
                    borderColor: formik.touched.responsibilities && Boolean(formik.errors.responsibilities) ? ' red !important' : undefined
                  },
                  '& .MuiOutlinedInput-root': {
                    borderColor: formik.touched.responsibilities && Boolean(formik.errors.responsibilities) ? ' red !important' : undefined,
                    borderRadius: '2.25rem'
                  },
                  '.MuiInputBase-root': { backgroundColor: formik.touched.roles && Boolean(formik.errors.responsibilities) ? '#f7e7e9 !important' : '#fff' },
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: formik.touched.responsibilities && Boolean(formik.errors.responsibilities) ? 'red !important' : undefined,
                    borderWidth: formik.touched.responsibilities && Boolean(formik.errors.responsibilities) ? '1px' : undefined
                  },
                  '& fieldset': {
                    borderColor: '#e5e5e5 !important', // Border color remains static
                    borderWidth: '1px !important' // Default border width
                  },

                  '&:hover fieldset': {
                    borderColor: '#e5e5e5' // No change on hover
                  }
                }}
                error={formik.touched.roles && Boolean(formik.errors.roles)}
              >
                {selectedResponsibilities?.length === 0 && <InputLabel id="demo-multiple-checkbox-label" shrink={false}></InputLabel>}
                <Select
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedResponsibilities}
                  onChange={handleResponsibilityChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => selectedResponsibilitiesName?.join(', ')}
                  open={openResponsibilities}
                  onOpen={() => setOpenResponsibilities(true)}
                  onClose={() => setOpenResponsibilities(false)}
                  IconComponent={() => <CustomArrowIcon onClick={() => setOpenResponsibilities(!openResponsibilities)} />}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                        width: 150,
                        marginTop: '0.5rem',
                        borderRadius: '8px'
                      }
                    },
                    sx: (theme) => ({ ...MuiMultiSelectPaperPropsStyles })
                  }}
                  placeholder={'Test'}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {roles?.map((role: { name: string; id?: string | undefined }) => (
                    <MenuItem
                      key={role.id}
                      value={role.id}
                      sx={{ '&.Mui-focusVisible': { backgroundColor: 'transparent' }, '&.Mui-selected': { backgroundColor: 'rgba(0,0,0,0.7) !important', color: '#fff' } }}
                    >
                      <Checkbox sx={MuiMultiSelectCheckboxStyles} checked={selectedResponsibilities?.includes(role.id ?? '')} />
                      <ListItemText primary={role.name} />
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.responsibilities && formik.errors.responsibilities && (
                  <FormHelperText sx={{ color: 'rgb(255, 0, 0) !important', fontSize: '0.85rem', fontFamily: 'PlusJakartaSans-Medium, sans-serif' }}>
                    {formik.errors.responsibilities}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          </div>
        </div>
      </div>
      <div className="inputs_group">
        <FieldWrapper
          title={t('messageEn')}
          inputName={`message[en]`}
          inputPlaceholder={t('messageEnglish')}
          inputError={formik?.errors?.message?.en}
          inputTouched={formik?.touched?.message?.en}
          textArea
          maxLength={300}
          value={formik?.values?.message?.en}
          showMaxLengthHint
        />
        <FieldWrapper
          title={t('messageAr')}
          inputName={`message[ar]`}
          inputPlaceholder={t('messageArabic')}
          inputError={formik?.errors.message?.ar}
          inputTouched={formik?.touched?.message?.ar}
          textArea
          maxLength={300}
          value={formik?.values?.message?.ar}
          showMaxLengthHint
        />
      </div>
      <Entities formik={formik} />
    </div>
  );
};

export default StepInputs;
