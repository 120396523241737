import CloseButton from "../../../components/buttons/CloseButton";
import { permissionNames } from "../../../config/FixedData";
import PermissionsData from "./PermissionsData";

const ViewModel = ({ setModel, data }: any) => {
    return (
        <div className="permissions modal_content">
            <div className="header">
                <h4 >Permissions</h4>
                <CloseButton handleClose={() => { setModel(false) }} />
            </div>
            {data.permissions.length > 0 ?

                permissionNames.map((item: string, index: number) => (
                    <PermissionsData text={item} data={data} key={index} />
                ))
                :
                <p style={{padding:"0rem 2rem 1rem"}}>No Permissions for that role</p>
            }
        </div>
    );
}

export default ViewModel;