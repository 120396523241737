import { useState } from 'react';
import ModalContainer from '../../../components/ModalContainer';
import { editIcon, seeIcon } from '../../../config/variables';
import { hasPermission } from '../../../utils/HelperFunctions';
import ViewModel from './ViewModel';
import { useNavigate } from 'react-router-dom';

const RolesTableContainer = ({ tableHeaders, data, noDataMessage }: any) => {

    const [model, setModel] = useState(false);
    const navigate = useNavigate()


    return (
        <div className={`table_container  `}>
            <div className="table_header" style={{ justifyContent: "space-between",display:"flex" }}>
                {tableHeaders?.map((header: any, index: number) => (
                    <span className={`head  ${header.customClass}`} key={index}>{header.label}</span>
                ))}
            </div>
            <div className={`table_data ${(!data || data?.length == 0) && "no_data"}`}
            >
                {data?.length > 0 ?
                    <>
                        {data?.map((item: any, index: number) => (
                            item.id !== 1 &&
                            <div className="item" key={index} style={{ justifyContent: "space-between" }}>
                                <div className="column " >{item?.name || "-"}</div>
                                {hasPermission(['users.show']) &&
                                    <div className="column actions actions_col">
                                        <div className="action_btn edit" onClick={(e) => {
                                            const contentContainer = document.querySelector(".layout_content");
                                            contentContainer?.scrollTo({ top: 0, behavior: "smooth" });
                                            e.stopPropagation();
                                            setModel(item)
                                        }}>
                                            {seeIcon}
                                        </div>

                                        <div className="action_btn edit" onClick={(e) => { e.stopPropagation(); navigate(`/roles/create-role/${item.id}`) }}>
                                            {editIcon}
                                        </div>

                                        {/* {item?.canDelete &&
                                            <div className="action_btn delete" onClick={(e) => { e.stopPropagation(); setDeleteModalOpen(item.id) }}>
                                                {deleteIcon}
                                            </div>
                                        } */}
                                    </div>
                                }
                            </div>
                        ))}
                    </>
                    :
                    <h6>{noDataMessage}</h6>
                }
            </div>
            {model &&
                <ModalContainer small>
                    <ViewModel setModel={setModel} data={model} />
                </ModalContainer>
            }
        </div>
    );
}

export default RolesTableContainer;