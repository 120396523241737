import { useTranslation } from 'react-i18next';
import FieldWrapper from '../../../components/formInputs/FieldWrapper';
import Checkbox from '../../../components/formInputs/Checkbox';

const OfferSelectedModels = ({ formik, allModelsOptions }: any) => {
  const { t } = useTranslation();
  return (
    <>
      {formik?.values?.vehicle_model_ids?.length > 0 && allModelsOptions && (
        <div className="inputs_group">
          <FieldWrapper title={t('allSelectedModels')}>
            <div className="checkboxes_wrapper flexWrap row">
              {allModelsOptions
                ?.filter((model: any, i: number) => formik?.values?.vehicle_model_ids?.includes(model?.value))
                ?.map((item: any, i: number) => (
                  <div className="" key={i}>
                    <Checkbox
                      name={`vehicle_model_ids`}
                      key={i}
                      item={item}
                      value={formik?.values?.vehicle_model_ids?.some((checkedItem: string) => checkedItem == item?.value)}
                      checked={formik?.values?.vehicle_model_ids}
                      formik={formik}
                      //   label={item?.name}
                    />
                  </div>
                ))}
              {/* {NationalitiesCheck?.map((item: any, i: number) => (
                          <Checkbox
                            workSectors={NationalitiesCheck as any}
                            name={`conditions.${index}.nationality`}
                            key={i}
                            item={item}
                            value={formik?.values?.conditions[index]?.nationality?.some((checkedItem: string) => checkedItem == item?.value)}
                            checked={formik?.values?.conditions[index]?.nationality}
                            formik={formik}
                          />
                        ))} */}
            </div>
            {/* <p className="error check">
            <ErrorMessage name={`conditions.${index}.nationality`} />
          </p> */}
          </FieldWrapper>
        </div>
      )}
    </>
  );
};

export default OfferSelectedModels;
