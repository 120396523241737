import { useTranslation } from 'react-i18next';
import FieldWrapper from '../../../components/formInputs/FieldWrapper';
import { ISingleField } from '../types/interfaces';
import FormUpload from '../../../components/formInputs/FormUpload';
import { FormikProps } from 'formik';
import { useRef, useState } from 'react';

interface IProps {
  data: ISingleField;
  entityIndex: number;
  formik: any;
  fieldIndex: number;
  isCurrentStep: boolean;
  canEdit: boolean;
  setUnauthorized: React.Dispatch<React.SetStateAction<boolean>>;
}

const SingleField = ({ data, entityIndex, formik, fieldIndex, isCurrentStep, canEdit, setUnauthorized }: IProps) => {
  const { t, i18n } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [shownImage, setShownImage] = useState<string | null>();

  return (
    <div className={data?.type == 'file' ? 'file' : ''} onClick={() => !canEdit && setUnauthorized(true)}>
      {data?.type == 'text' && (
        <FieldWrapper
          title={data?.label[i18n.language as keyof typeof data.label]}
          inputName={`entity[${entityIndex}].values[${fieldIndex}].value`}
          inputPlaceholder={data?.label[i18n.language as keyof typeof data.label]}
          inputError={formik?.errors?.entity?.[entityIndex]?.values?.[fieldIndex]?.value as string}
          inputTouched={formik?.touched?.entity?.[entityIndex]?.values?.[fieldIndex]?.value as boolean}
          input
          disabled={!isCurrentStep || !canEdit}
          ref={inputRef}
        />
      )}
      {data?.type == 'file' && (
        <FormUpload
          //  errors={formik?.errors.logo as string}
          //  touched={formik?.touched?.logo as boolean}
          setShownImage={setShownImage}
          shownImage={shownImage!}
          formik={formik}
          name={`entity.${entityIndex}.values.${fieldIndex}.value`}
          fileName={data?.document?.name}
          fileUrl={data?.document?.url}
          isCurrentStep={isCurrentStep}
          label={data?.label[i18n.language as keyof typeof data.label]}
          productSteps
          canEdit={canEdit}
        />
      )}
    </div>
  );
};

export default SingleField;
