import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalContainer from '../../../components/ModalContainer';
import DeleteModal from '../../../components/DeleteModal';
import { useTranslation } from 'react-i18next';
import { hasPermission } from '../../../utils/HelperFunctions';
import { deleteIcon, editIcon } from '../../../config/variables';

const ServicesTableContainer = ({ tableHeaders, data, noDataMessage, setRefetchData }: any) => {
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { t, i18n } = useTranslation();

  return (
    <div className={`table_container serviceProviders hasPagination`}>
      <div className="table_header">
        {tableHeaders?.map(
          (header: any, index: number) =>
            header && (
              <span className={`head ${header.customClass}`} key={index}>
                {header.label}
              </span>
            )
        )}
      </div>
      <div className={`table_data ${(!data || data?.length == 0) && 'no_data'}`}>
        {data?.length > 0 ? (
          <>
            {data?.map((item: any, index: number) => (
              <div
                className="item clickable has_logo"
                key={index}
                onClick={() => {
                  const contentContainer = document.querySelector('.layout_content');
                  contentContainer?.scrollTo({ top: 0, behavior: 'smooth' });
                  navigate(`/services/${item.id}`);
                }}
              >
                <div className="column logo">
                  <div className="logo-container">{item?.logo_url && <img src={item?.logo_url} alt="" />}</div>
                  <div className="name-container sp">{item?.name[i18n.language] || '-'}</div>
                </div>
                <div className="column">{item?.address ? item?.address.city + ', ' + item?.address.street : '-'}</div>
                <div className="column">{item?.limit?.toLocaleString('en-US')}</div>
                <div className="column">{(Number(item?.limit) - Number(item?.limit_used)).toLocaleString('en-US') || 0}</div>
                <div className="column text-center">{item?.pending_payments}</div>
                <div className="column text-center">{item?.active_applications}</div>
                <div className="column status_col capitalize">
                  <div className={`${item?.is_active ? 'active' : ''}`}>{`${item?.is_active ? t('active') : t('inactive')}`}</div>
                </div>
                {hasPermission(['service_providers.crud']) && (
                  <div className="column actions actions_col">
                    <div
                      className="action_btn edit"
                      onClick={(e) => {
                        const contentContainer = document.querySelector('.layout_content');
                        contentContainer?.scrollTo({ top: 0, behavior: 'smooth' });
                        e.stopPropagation();
                        navigate(`/services/create-service/${item.id}`);
                      }}
                    >
                      {editIcon}
                    </div>
                    {item?.can_be_deleted && (
                      <div
                        className="action_btn delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteModalOpen(item.id);
                        }}
                      >
                        {deleteIcon}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </>
        ) : (
          <h6>{noDataMessage}</h6>
        )}
      </div>
      {deleteModalOpen && (
        <ModalContainer small>
          <DeleteModal
            id={deleteModalOpen}
            setModal={setDeleteModalOpen}
            setRefetchData={setRefetchData}
            route={'/service-providers'}
            successMsg={t('deletedServiceProvider')}
            warningMsg={t('deleteConfirmationMessageServiceProvider')}
          />
        </ModalContainer>
      )}
    </div>
  );
};

export default ServicesTableContainer;
