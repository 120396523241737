import { Form, Formik } from 'formik';
import FieldWrapper from '../../../components/formInputs/FieldWrapper';
import Button from '../../../components/buttons/Button';
import { sendIcon } from '../../../config/variables';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosInstance } from '../../../config/axiosConfig';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
export default function ApplicationAddComment({ entity_id }: { entity_id: string |number }) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { id } = useParams();  
  const commentMutation = useMutation({
    mutationFn: async (values: FormValues) =>
      await axiosInstance(`entity/${entity_id}/comments`, {
        data: { ...values, application_product_id: id },
        method: 'POST'
      }),
    onSuccess: () => {
      // queryClient.invalidateQueries({ queryKey: [`comments-${entity_id}`,] });
      queryClient.invalidateQueries({ queryKey: ['comments'] });
      toast.success(`${t('addedComment')} ${t('successfully')}`);
    }
  });
  interface FormValues {
    body: string;
  }
  const initialValues: FormValues = { body: '' };
  const validationSchema = Yup.object({
    body: Yup.string().min(3, t('CommentTooShort')).max(100, t('CommentTooLong'))
  });
  const onSubmit = (values: FormValues) => {
    commentMutation.mutate(values);
  };
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize validateOnMount validationSchema={validationSchema}>
      {(formik) => (
        <>
          <Form>
            <div className="add-comment">
              <FieldWrapper
                inputName={'body'}
                inputPlaceholder={t('addComment')}
                inputError={formik.errors.body as string}
                inputTouched={formik.touched.body as boolean}
                input
                customPadding
              />
              <Button
                text={sendIcon}
                customClass="icon-only"
                disabled={formik.values.body.length > 2 ? false : true}
                loading={commentMutation.isPending ? true : false}
                commentBtn
                onClick={function () {
                  formik.submitForm();
                  setTimeout(() => {
                    formik.resetForm();
                  }, 500);
                }}
                type="submit"
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}
