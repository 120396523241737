import ActiveSideMenuAccordionContextProvider from '../../../store/context/activeSideMenuAccordionContext';
import { hasPermission } from '../../../utils/HelperFunctions';
import TogglerNavLink from './TogglerNavLink';
import { applicationsIcon, serviceIcon, productIcon, distIcon, createIcon, listIcon, Sales, Deal, permissionsIcon, stepsIcon, dashboard } from '../../../config/variables';
import { useTranslation } from 'react-i18next';
import { IMenuLinks } from '../../../types/Interfaces';
import { Dispatch } from 'react';
import SingleNavLinks from './SingleNavLink';
import { useSelector } from 'react-redux';

const SideMenuLinks = ({ setOpenMenu }: { setOpenMenu?: Dispatch<React.SetStateAction<boolean>> }) => {
  const { t } = useTranslation();
  const { authData } = useSelector((store: any) => store);

  const canViewServiceProviders = () => {
    return (
      (hasPermission(['service_providers.edit']) || hasPermission(['service_providers.crud'])) && !authData?.userData.service_provider_id && !authData?.userData.distributor_id
    );
  };

  const canViewDistributors = () => {
    return (hasPermission(['distributors.edit']) || hasPermission(['distributors.edit'])) && !authData?.userData.service_provider_id && !authData?.userData.distributor_id;
  };

  const serviceProviderLinks = {
    header: t('links.services'),
    baseRoute: '/services',
    headerIcon: [serviceIcon],
    nestedLinks: [
      hasPermission(['service_providers.show']) && {
        label: t('links.services'),
        link: '/services',
        icon: [listIcon]
      },
      hasPermission(['service_providers.crud']) && {
        label: t('links.create_service'),
        link: '/services/create-service',
        icon: [createIcon]
      }
    ]
  };

  const productLinks = {
    header: t('links.products'),
    baseRoute: '/products',
    headerIcon: [productIcon],
    nestedLinks: [
      hasPermission(['products.show']) && {
        label: t('links.products'),
        link: '/products',
        icon: [listIcon]
      },
      hasPermission(['products.edit']) && {
        label: t('links.create_product'),
        link: '/products/create-product',
        icon: [createIcon]
      }
    ]
  };

  const distributorsLinks = {
    header: t('links.distributors'),
    baseRoute: '/distributors',
    headerIcon: [distIcon],
    nestedLinks: [
      hasPermission(['distributors.show']) && {
        label: t('links.distributors'),
        link: '/distributors',
        icon: [listIcon]
      },
      hasPermission(['distributors.edit']) && {
        label: t('links.create_distributor'),
        link: '/distributors/create-distributor',
        icon: [createIcon]
      }
    ]
  };

  const salesLinks = {
    header: t('sales'),
    baseRoute: '/sales',
    headerIcon: [Sales],
    nestedLinks: [
      hasPermission(['users.show']) && {
        label: t('sales'),
        link: '/sales',
        icon: [listIcon]
      },
      hasPermission(['users.edit']) && {
        label: t('create_sales'),
        link: '/sales/create-sales-executive',
        icon: [createIcon]
      }
    ]
  };
  const findDealLinks = {
    header: t('links.find_deal'),
    baseRoute: '/applications/create/find-deal',
    headerIcon: [Deal],
    nestedLinks: { link: '/applications/create/find-deal', label: t('find_deal') }

  };

  const applicationsLinks = {
    header: t('links.applications'),
    baseRoute: '/applications',
    headerIcon: [applicationsIcon],
    nestedLinks: {
      link: '/applications',
      label: t('links.applications'),
    }
  };

  const rolesLinks = {
    header: t('rolesAndPermissions'),
    baseRoute: '/roles',
    headerIcon: [permissionsIcon],
    nestedLinks: [
      hasPermission(['roles.show']) && {
        label: t('roles'),
        link: '/roles',
        icon: [listIcon]
      },
      hasPermission(['roles.edit']) && {
        label: t('createRole'),
        link: '/roles/create-role',
        icon: [createIcon]
      }
    ]
  };

  const steps = {
    header: t('stepCreation'),
    baseRoute: '/steps',
    headerIcon: [stepsIcon],
    nestedLinks: [
      hasPermission(['roles.show']) && {
        label: t('steps'),
        link: '/steps',
        icon: [listIcon]
      },
      hasPermission(['roles.show']) && {
        label: t('createStep'),
        link: '/steps/create-step',
        icon: [createIcon]
      }
    ]
  };

  const usersLinks = {
    header: t('links.users'),
    baseRoute: '/users',
    headerIcon: [Sales],
    nestedLinks: [
      hasPermission(['users.show', 'owned-users.show']) && {
        label: t('links.users'),
        link: '/users',
        icon: [listIcon]
      },
      hasPermission(['users.edit', 'owned-users.edit']) && {
        label: t('create_users'),
        link: '/users/create-user',
        icon: [createIcon]
      }
    ]
  };
  const dashboardLinks = {
    header: hasPermission(['dashboard.show']) ? t('dashboard') : t('home'),
    baseRoute: '/dashboard',
    headerIcon: [dashboard],
    nestedLinks: { link: '/dashboard', label: t('dashboard') }
  };

  return (
    <ul className="nav_links">
      <ActiveSideMenuAccordionContextProvider>
        <SingleNavLinks links={dashboardLinks} />
        {
          //TODO: Add Applications Create in the roles  and 
        }
        {hasPermission(['applications.get']) && (
          <SingleNavLinks links={findDealLinks} />
        )}
        {canViewServiceProviders() && (
          <TogglerNavLink key={'serviceProviderLinks'} keyName="serviceProviderLinks" links={serviceProviderLinks as IMenuLinks} setMenu={setOpenMenu} />
        )}
        {hasPermission(['products.edit']) && (
          <TogglerNavLink key={'productLinks'} keyName="productLinks" links={productLinks as IMenuLinks} setMenu={setOpenMenu} />
        )}
        {canViewDistributors() && <TogglerNavLink key={'distributorsLinks'} keyName="distributorsLinks" links={distributorsLinks as IMenuLinks} setMenu={setOpenMenu} />}
        {hasPermission(['users.edit',]) && <TogglerNavLink key={'salesLinks'} keyName="salesLinks" links={salesLinks as IMenuLinks} setMenu={setOpenMenu} />}
        {hasPermission(['roles.show', 'roles.edit']) && <TogglerNavLink key={'stepLinks'} keyName="stepLinks" links={steps as IMenuLinks} setMenu={setOpenMenu} />}
        {hasPermission(['applications.get']) && <SingleNavLinks   links={applicationsLinks} />}
        {hasPermission(['roles.get', 'roles.edit']) && <TogglerNavLink key={'rolesLinks'} keyName="rolesLinks" links={rolesLinks as IMenuLinks} setMenu={setOpenMenu} />}
        {hasPermission(['users.edit', 'owned-users.show', 'owned-users.edit']) && (
          <TogglerNavLink key={'usersLinks'} keyName="usersLinks" links={usersLinks as IMenuLinks} setMenu={setOpenMenu} />
        )}
      </ActiveSideMenuAccordionContextProvider>
    </ul>
  );
};

export default SideMenuLinks;
