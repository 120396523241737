import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandAccordion from "../../../modules/products/components/productForm/ExpandAccordion";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import FormUpload from "../../../components/formInputs/FormUpload";

interface IProps {
    poUrl: string;
    quotationUrl: string;
}

const PoQuotation = ({poUrl, quotationUrl} : IProps) => {
    const [expanded, setExpanded] = useState("");
    const {t} = useTranslation();

    return (
        <>
        <div className="field-array-container noPadding noMarginBottom">
        <div className="field-array-container">
        <div className="fields_container" style={{padding: "2rem"}}>
                <div className='file'
                //  onClick={() => !canEdit && setUnauthorized(true)}
                    >
    
        <FormUpload
       
        //   setShownImage={setShownImage}
        //   shownImage={shownImage!}
        //   formik={formik}
          name={`poFile`}
          fileName={t("poFile")}
          fileUrl={poUrl}
        //   isCurrentStep={isCurrentStep}
          label={t("po")}
          productSteps
          canEdit={true}
        />
   
    </div>
                <div className='file'
                //  onClick={() => !canEdit && setUnauthorized(true)}
                    >
    
        <FormUpload
       
        //   setShownImage={setShownImage}
        //   shownImage={shownImage!}
        //   formik={formik}
          name={`quotationFile`}
          fileName={t("quotationFile")}
          fileUrl={quotationUrl}
        //   isCurrentStep={isCurrentStep}
          label={t("quotation")}
          productSteps
          canEdit={true}
        />
   
    </div>
            </div>
      </div>
      </div>
        </>
    );
}

export default PoQuotation;