import { useEffect, useMemo, useState } from 'react';
import Tabs from '../../../components/Tabs';
import { useTranslation } from 'react-i18next';

export default function NotificationsHeader({
  setFilters,
  handleReadAllMutation,
  markAllLoading,
  setMarkAllLoading
}: {
  setFilters: React.Dispatch<React.SetStateAction<{ is_seen: string }>>;
  handleReadAllMutation: () => void;
  markAllLoading: boolean;
  setMarkAllLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<string>('');

  const tabsOptions = useMemo(() => {
    return [
      { title: t('all'), value: '' },
      { title: t('read'), value: '1' },
      { title: t('unread'), value: '0' }
    ];
  }, [t]);

  return (
    <div className="notifications-header">
      <Tabs
        tabs={tabsOptions}
        value={activeTab}
        setValue={(value: string) => {
          setActiveTab(value);
          setFilters({ is_seen: value });
        }}
      />
      <div
        className="notifications-mark"
        onClick={() => {
          if(!markAllLoading){
            setMarkAllLoading(true);
            handleReadAllMutation();
          }
        }}
      >
        {markAllLoading && (
          <div className="loader-page login">
            <div className="submit_loading_container"></div>
          </div>
        )}
        <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 52 52" enable-background="new 0 0 52 52" xmlSpace="preserve">
          <path
            d="M24,7l-1.7-1.7c-0.5-0.5-1.2-0.5-1.7,0L10,15.8l-4.3-4.2c-0.5-0.5-1.2-0.5-1.7,0l-1.7,1.7
            c-0.5,0.5-0.5,1.2,0,1.7l5.9,5.9c0.5,0.5,1.1,0.7,1.7,0.7c0.6,0,1.2-0.2,1.7-0.7L24,8.7C24.4,8.3,24.4,7.5,24,7z"
          />
          <path
            d="M48.4,18.4H27.5c-0.9,0-1.6-0.7-1.6-1.6v-3.2c0-0.9,0.7-1.6,1.6-1.6h20.9c0.9,0,1.6,0.7,1.6,1.6v3.2
            C50,17.7,49.3,18.4,48.4,18.4z"
          />
          <path
            d="M48.4,32.7H9.8c-0.9,0-1.6-0.7-1.6-1.6v-3.2c0-0.9,0.7-1.6,1.6-1.6h38.6c0.9,0,1.6,0.7,1.6,1.6v3.2
            C50,32,49.3,32.7,48.4,32.7z"
          />
          <path
            d="M48.4,47H9.8c-0.9,0-1.6-0.7-1.6-1.6v-3.2c0-0.9,0.7-1.6,1.6-1.6h38.6c0.9,0,1.6,0.7,1.6,1.6v3.2
            C50,46.3,49.3,47,48.4,47z"
          />
        </svg>
        <span>{t('markAllAsRead')}</span>
        {/* <Button text="Mark all as read"/> */}
      </div>
    </div>
  );
}
