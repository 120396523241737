import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalContainer from '../../../components/ModalContainer';
import DeleteModal from '../../../components/DeleteModal';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';

const AuditTable = ({ tableHeaders, data, noDataMessage, lessColumns, audit }: any) => {

    const navigate = useNavigate()
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const { t, i18n } = useTranslation()
    const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });
    const [tableContent, setTableContent] = useState([])
    const [itemFullHeight, setItemFullHeight] = useState(false)

    const mapAuditData = (data: any) => {
        const allData =  data?.map((item: any, index: number) => {
            let tableContent: any = {}
            tableContent['user'] = item?.created_by?.first_name
            tableContent['date'] = moment(item?.updated_at).format("DD-MM-YYYY")
            tableContent['time'] = moment(item?.updated_at).format("HH:mm")
            tableContent['service_provider_logo'] = item?.model?.product?.service_provider?.logo_url
            tableContent['service_provider_name'] = item?.model?.product?.service_provider?.name[i18n.language]
            if (item.action === "created") {
                tableContent['action'] = t("created")
            } else {
                tableContent["action"] = t("updated")
                Object.entries(item.changes).map(([key, value]) => {
                    let updates: any = {}
                    updates[key] = value
                    tableContent['updates'] = auditActionMapper(key,updates[key]['new_value'])
                    return tableContent
                  });
            }
            return tableContent
        })
        setTableContent(allData)
    }

    const auditActionMapper = (key: string, value: any) => {
        const statuses: any = {
            "1" : "Pending",
            "2": "In Progress",
            "3": "Accepted",
            "4": "Rejected",
            "5": "Rejected"
        }
        try {
            let parsed = JSON.parse(value);
            if (Array.isArray(parsed)) {
                key = "array_key"
                value = parsed
            }
            
          } catch (e) {
            console.log(e)
          }

        switch (key) {
            case "step_id":
                return "Moved to next step"
            case "status":
                return `Updated ${key} to ${statuses[value]}`
            case "quotation":
                return `Quotation is created`
            case "po":
                return `PO is created`
            case "array_key":
                setItemFullHeight(true)
                return value.map((value_2: any, index: number) => {
                        return <div style={{marginBottom: "1rem"}}>
                            Updated {value_2["key"].replace(/_/g, " ")}
                        </div> 
                    })
            default:
                return `Updated ${key} to ${value}`
        }
    }

    useEffect(() => {
      if (data)
        mapAuditData(data)
    }, [data])
    
    
    return (
        <div className={`table_container ${lessColumns && isDesktop && "lessColumns"}  audit`}>
            <div className="table_header">
                {tableHeaders?.map((header: any, index: number) => (
                    <span className={`head ${header.customClass} `} key={index}>{header.label}</span>
                ))}
            </div>
            <div className={`table_data ${(!data || data?.length == 0) && "no_data"}`}>
                {tableContent?.length > 0 ?
                    <>
                        {tableContent?.map((item: any, index: number) => {
                            return (
                                <div className={`${itemFullHeight && "full-height"} item clickable has_logo`} key={index}>   
                                {item.service_provider_logo &&
                                    <div className="column logo ">
                                        {/* <div>
                                            {item?.logo_url && <img src={item?.logo_url} alt="" />}
                                        </div> */}
                                        <div className='logo-container'>
                                            {item.service_provider_logo && <img src={item.service_provider_logo} alt="" />}
                                        </div>
                                        <div className='name-container'>
                                        {item.service_provider_name}
                                        </div>
                                    </div>      
                                }  
                                    {/* <div className="column text-center">{item?.vehicle_model.vehicle_make.name|| "-"}</div>              */}
                                    <div className="column text-center">{item.user}</div>
                                    <div className="column text-center small">{item.date}</div>
                                    <div className="column text-center small">{item.time}</div>
                                    <div className="column text-center extra-width">{item.updates? item.updates : item.action}</div>
                                </div>
                        )
                        })}
                    </>
                    :
                    <h6>{noDataMessage}</h6>
                }
            </div>
        </div>
    );
}

export default AuditTable;