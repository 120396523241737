import { PieChart } from "react-minimal-pie-chart";
import ListHeader from "../../../components/ListHeader";
import CommonHighlights from "../../serviceProvider/components/CommonHighlights";
import { IServiceInfo } from "../../serviceProvider/types/interfaces";
import TopData from "./TopData";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BarChart } from '@mui/x-charts/BarChart';
import { getChartOptions } from "../utils/HelperFunctions";
import ChartDirection from "./ChartDirection";

const SalesData = ({ salesData, dataSet }: any) => {

    const { t } = useTranslation()
    const salesBasicData = useMemo(() => {

        return [
            {
                title: t('total_sales'),
                number: salesData?.count || 0
            },
            {
                title: t("dashboardCharts.total_sales_value"),
                number: Number(salesData?.value).toLocaleString('en-US') || 0
            },
        ];

    }, [salesData, t])

    const topSalesData = useMemo(() => {
        const data = salesData?.top_sales
            .sort((a: { value: number }, b: { value: number }) => b.value - a.value)
            .map((item: any) =>
            (
                {
                    title: item?.name,
                    number: `${item?.value?.toLocaleString('en-US')}`
                }
            )

            )
        return data
    }, [salesData])

    const topSalesProductsData = useMemo(() => {
        const data = salesData?.top_selling_products
            .sort((a: { total_vehicle_price: number }, b: { total_vehicle_price: number }) => b.total_vehicle_price - a.total_vehicle_price)
            .map((item: any) =>
            (
                {
                    title: `#${item.product_id}`,
                    number: `${item.total_vehicle_price.toLocaleString('en-US')}`
                }
            )

            )
        return data
    }, [salesData])

    return (
        <>
            <ListHeader customClass="row noMargin">
                <h2>{t("dashboardCharts.sales")}</h2>
            </ListHeader>
            <div className={`dashboard-data`}>
                <div className="charts">
                    <CommonHighlights data={salesBasicData as IServiceInfo[]} noBorder>
                        {
                            (Number(salesData?.payment_status.paid) + Number(salesData?.payment_status.not_paid)) != 0 &&
                            <div className="card">
                                <h6>{t("dashboardCharts.payments_status")}</h6>
                                {salesData &&
                                    <>
                                        <PieChart
                                            data={[
                                                { title: t("dashboardCharts.paid"), value: salesData?.payment_status.paid, color: '#9c9b9b', label: `${salesData?.payment_status.paid}%` },
                                                { title: t("dashboardCharts.not_paid"), value: salesData?.payment_status.not_paid, color: '#e5e5e5', label: `${salesData?.payment_status.not_paid}%` },
                                            ]}
                                            radius={50}
                                            label={({ dataEntry }) => dataEntry.label}
                                            labelStyle={{
                                                fontSize: '.75rem',
                                                fill: '#000',
                                            }}
                                            labelPosition={60}
                                        />
                                        <span className="v1">{t("dashboardCharts.paid")}</span>
                                        <span className="v1 v2">{t("dashboardCharts.not_paid")}</span>
                                    </>
                                }

                            </div>
                        }
                    </CommonHighlights>
                    <ChartDirection>
                        <BarChart
                            dataset={dataSet}
                            xAxis={[
                                {
                                    scaleType: 'band', dataKey: 'x', tickPlacement: "middle",
                                    colorMap: {
                                        type: 'piecewise',
                                        thresholds: [new Date(2021, 1, 1), new Date(2023, 1, 1)],
                                        colors: ['#000'],
                                    }
                                },
                            ]}
                            {...getChartOptions(t("dashboardCharts.no_of_applications"), t("dashboardCharts.value"))}
                        />
                    </ChartDirection>
                </div>
                <div className="top-dashboard-data">
                    <>
                        <TopData
                            data={topSalesProductsData}
                            header={t("dashboardCharts.top_products")}
                        />
                        <TopData
                            data={topSalesData}
                            header={t("dashboardCharts.top_selling")}
                        />
                    </>
                </div>
            </div>
        </>
    );
}

export default SalesData;