import TopData from "./TopData";
import ListHeader from "../../../components/ListHeader";
import { LineChart } from "@mui/x-charts";
import { getChartOptions } from "../utils/HelperFunctions";
import ChartDirection from "./ChartDirection";
import { useTranslation } from "react-i18next";

const VehiclesData = ({ dataSet, vehiclesData }: any) => {

    const { t } = useTranslation()

    return (
        <>
            <ListHeader customClass="row noMargin">
                <h2>{t("dashboardCharts.vehicles")}</h2>
            </ListHeader>
            <div className={`dashboard-data vehicles`}>
                <div className="charts">
                    <ChartDirection>
                        <LineChart
                            dataset={dataSet}
                            xAxis={[
                                {
                                    scaleType: 'band',
                                    dataKey: 'x',
                                    label: t("dashboardCharts.vehicle_sales_progress")
                                },
                            ]}
                            {...getChartOptions("", t("dashboardCharts.sales_value"), "#3498db")}
                            height={400}
                            grid={{ horizontal: true }}
                        />
                    </ChartDirection>
                </div>
                <div className="top-dashboard-data" >
                    <>
                        <TopData
                            data={vehiclesData?.top_brands}
                            header={t("dashboardCharts.top_makes")}
                        />
                        <TopData
                            data={vehiclesData?.top_models}
                            header={t("dashboardCharts.top_models")}
                        />
                    </>
                </div>
            </div>
        </>
    );
}

export default VehiclesData;