import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { IFormUpload } from '../../types/Interfaces';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const FormUpload = ({ setShownImage, shownImage, errors, formik, name, touched, fileName, fileUrl, isCurrentStep, label, productSteps, canEdit }: IFormUpload) => {
  const [error, setError] = useState<string>();
  const { t } = useTranslation();
  const [singleFileType, setSingleFileType] = useState<string>('');
  const filesFormat = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  
  console.log(shownImage, "shownImage");
  
  
  const convertFilesToBase64 = (files: FileList) => {
    return new Promise((resolve, reject) => {
      const filePromises = Array.from(files).map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(file as Blob);
        });
      });

      Promise.all(filePromises)
        .then((base64Array) => resolve(base64Array))
        .catch((error) => reject(error));
    });
  };

  const onDrop = useCallback(async (acceptedFiles: any) => {
    if (!filesFormat.includes(acceptedFiles[0].type)) {
      setError('File type mismatch');
    } else if (acceptedFiles[0].size > 5000 * 10240) {
      setError('Image size must be less that or equal 500KB');
    } else {
      const acceptedTypes = acceptedFiles.filter((file: any) => filesFormat.includes(file.type));
      let files = await convertFilesToBase64(acceptedTypes);

      setShownImage((files as [{}])[0]);
      setSingleFileType(acceptedTypes[0].type);
      setError('');
      formik.setFieldValue(`${name}`, acceptedTypes[0]);
    }
  }, []);

  function formatFileName(name: string) {
    const maxLength = 10; // Maximum length of the displayed filename (adjust as needed)
    const fileType = name.substring(name.lastIndexOf('.')); // Get the file extension
    const baseName = name.substring(0, name.lastIndexOf('.')); // Get the filename without the extension

    if (baseName.length > maxLength) {
      return baseName.substring(0, 10) + '...' + fileType;
    }
    return name;
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop
  });

  return (
    <>
      {label && (
        <div className="header undefined" style={{ marginBottom: '1rem' }}>
          <h5 className="title">{label}</h5>
        </div>
      )}
      <div
        className={`drag-image-container ${fileUrl && 'hasFile'} ${shownImage && 'images-added build-form'}  ${errors && touched && 'drag-error-container'} ${productSteps && !fileUrl && !isCurrentStep && 'disabled normal-cursor'} ${!canEdit && productSteps && 'normal-cursor'}`}
      >
        {shownImage && (
          <>
            <div className="images-wrapper">
              {singleFileType === 'image/jpeg' || singleFileType === 'image/png' || singleFileType === 'image/jpg' || !productSteps ? (
                <div className="img-container ">
                  <img alt={'img'} src={shownImage} />
                </div>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ width: '4rem', height: '4rem' }}>
                    <path d="M14 4H6V20H18V8L14 4ZM13 9H17V19H7V5H13V9ZM14 8V5L17 8H14Z" fill="#000" />
                    <path d="M8 11H16V12H8V11Z" fill="#000" />
                    <path d="M8 13H16V14H8V13Z" fill="#000" />
                    <path d="M8 15H16V16H8V15Z" fill="#000" />
                  </svg>
                  <p>{t('fileUploaded')}</p>
                </div>
              )}
            </div>
            <div className="edit-icon" {...getRootProps()}>
              <input {...getInputProps()} />
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.5547 0.878906C11.2383 0.195312 12.3594 0.195312 13.043 0.878906L13.3711 1.20703C14.0547 1.89062 14.0547 3.01172 13.3711 3.69531L7.35547 9.71094C7.10938 9.95703 6.80859 10.0938 6.50781 10.1758L4.01953 10.75C3.88281 10.7773 3.71875 10.75 3.60938 10.6406C3.5 10.5312 3.47266 10.3672 3.5 10.2305L4.07422 7.74219C4.15625 7.44141 4.29297 7.14062 4.53906 6.89453L10.5547 0.878906ZM12.4141 1.50781C12.0859 1.15234 11.5117 1.15234 11.1836 1.50781L10.4453 2.21875L12.0312 3.80469L12.7422 3.06641C13.0977 2.73828 13.0977 2.16406 12.7422 1.83594L12.4141 1.50781ZM4.92188 7.93359L4.51172 9.73828L6.28906 9.32812C6.45312 9.30078 6.61719 9.21875 6.72656 9.08203L11.4023 4.40625L9.84375 2.84766L5.16797 7.52344C5.03125 7.63281 4.94922 7.79688 4.92188 7.93359ZM5.6875 2C5.90625 2 6.125 2.21875 6.125 2.4375C6.125 2.68359 5.90625 2.875 5.6875 2.875H2.1875C1.44922 2.875 0.875 3.47656 0.875 4.1875V12.0625C0.875 12.8008 1.44922 13.375 2.1875 13.375H10.0625C10.7734 13.375 11.375 12.8008 11.375 12.0625V8.5625C11.375 8.34375 11.5664 8.125 11.8125 8.125C12.0312 8.125 12.25 8.34375 12.25 8.5625V12.0625C12.25 13.293 11.2656 14.25 10.0625 14.25H2.1875C0.957031 14.25 0 13.293 0 12.0625V4.1875C0 2.98438 0.957031 2 2.1875 2H5.6875Z"
                  fill="#211D33"
                />
              </svg>
            </div>
          </>
        )}
        {!shownImage && fileUrl ? (
          <div className="drag-wrapper">
            {isCurrentStep && (
              <div className="uploadIcon" {...getRootProps()}>
                <input {...getInputProps()} />

                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M18.1 14.9C18.1 14.7 18.1 14.5 18.1 14.3C18.1 11.9 16.2 10 13.9 10C13.6 10 13.3 10 13 10.1V8H15L12 4L9 8H11V9.5C10.6 9.3 10.1 9.2 9.7 9.2C8.1 9.2 6.8 10.4 6.8 12C6.8 12.3 6.9 12.6 7 12.9C5.4 13.1 4 14.7 4 16.4C4 18.3 5.5 20 7.3 20H17.6C19 20 20 18.6 20 17.4C20 16.2 19.2 15.2 18.1 14.9ZM17.6 19H7.3C6.1 19 5 17.8 5 16.5C5 15.2 6.1 14 7.3 14C7.4 14 7.6 14 7.7 14L9 14.3L8.2 13.1C8 12.8 7.8 12.4 7.8 12C7.8 11 8.6 10.2 9.6 10.2C10.1 10.2 10.6 10.4 10.9 10.8V14H12.9V11.2C13.2 11.1 13.5 11.1 13.8 11.1C15.6 11.1 17 12.6 17 14.4C17 14.7 17 15 16.9 15.3L16.7 15.9H17.5C18.2 15.9 18.9 16.6 18.9 17.4C19 18.1 18.4 19 17.6 19V19Z"
                    fill="#000"
                  />
                </svg>
                <div className="tooltip-box">{<p className="desc">{t('reupload')}</p>}</div>
              </div>
            )}
            <a
              href={fileUrl && fileUrl}
              target="_blank"
              style={{ textAlign: 'center', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}
            >
              {fileName && (
                <div className="fileIcon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M14 4H6V20H18V8L14 4ZM13 9H17V19H7V5H13V9ZM14 8V5L17 8H14Z" fill="#000" />
                    <path d="M8 11H16V12H8V11Z" fill="#000" />
                    <path d="M8 13H16V14H8V13Z" fill="#000" />
                    <path d="M8 15H16V16H8V15Z" fill="#000" />
                  </svg>
                </div>
              )}
              <div className="">
                <p className="fileName">{fileName ? formatFileName(fileName) : t('drag')}</p>
              </div>
              {!fileName && (
                <>
                  <p className="types">JPG, PNG, JPEG</p>
                  <span className="types">{t('max_size')}</span>
                </>
              )}
            </a>
          </div>
        ) : (productSteps && !fileUrl && !isCurrentStep) || (!canEdit && productSteps) ? (
          <div className="drag-wrapper">
            {/* <input {...getInputProps()} /> */}

            <p className="fileName">{fileName ? formatFileName(fileName) : t('drag')}</p>

            <p className="types">JPG, PNG, JPEG</p>
            <span className="types">{t('max_size')}</span>
          </div>
        ) : (
          <div {...getRootProps()} className="drag-wrapper">
            <input {...getInputProps()} />

            <p className="fileName">{fileName ? formatFileName(fileName) : t('drag')}</p>

            <p className="types">JPG, PNG, JPEG</p>
            <span className="types">{t('max_size')}</span>
          </div>
        )}
      </div>
      <p className="drag-error builder">{error}</p>
    </>
  );
};
export default FormUpload;
