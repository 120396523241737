import React, { useState } from 'react';
import Button from '../../../components/buttons/Button';
import FieldWrapper from '../../../components/formInputs/FieldWrapper';
import { Form, Formik, FormikHelpers } from 'formik';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosInstance } from '../../../config/axiosConfig';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import moment from 'moment';
import { sendIcon } from '../../../config/variables';

interface IReplay {
  replay: {
    id: number;
    body: string;
    user: {
      first_name: string;
    };
    created_at: string;
  };
  setActiveDelete: (value: boolean) => void;
  setFieldId: (value: number) => void;
}
export default function ReplayComment({ replay, setActiveDelete, setFieldId }: IReplay) {
  const [updateFieldReplay, setUpdateFieldReplay] = useState(false);
  const [replayUpdateId, setReplayUpdateID] = useState(0);
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();

  interface FormValues {
    body: string;
  }
  const initialValues: FormValues = { body: replay?.body || '' };
  const validationSchema = Yup.object({
    body: Yup.string().min(3).max(150).required(t('required'))
  });
  const updateReplayMutation = useMutation({
    mutationFn: async (values: FormValues) =>
      await axiosInstance(`comments/${replay?.id}`, {
        data: values,
        method: 'PUT'
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['comments'] });
      toast.success(`${t('updateComment')} ${t('successfully')}`);
    }
  });
  function getTime(date: string) {
    const today = moment().format('YYYY-MM-DD');
    const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
    const currDate = moment(date).format('YYYY-MM-DD');

    if (currDate === today) {
      return `Today, ${moment(date).format('hh:mm A')}`;
    } else if (currDate === yesterday) {
      return `Yesterday, ${moment(date).format('hh:mm A')}`;
    } else {
      return moment(date).format('DD MMM YYYY, hh:mm A');
    }
  }
  const onSubmit = (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    updateReplayMutation.mutate(values);
  };
  return (
    <div className="comments-replies-list">
      <div className="comments-replies-item" key={`comment-replay-${replay.id}`}>
        <div className="comment-controls">
          <svg
            onClick={() => {
              setUpdateFieldReplay(true);
              setReplayUpdateID(replay.id);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-file-pen-line"
          >
            <path d="M18 5L15.586 2.586A2 2 0 0 0 14.172 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2" />
            <path d="M21.378 12.626a1 1 0 0 0-3.004-3.004l-4.01 4.012a2 2 0 0 0-.506.854l-.837 2.87a.5.5 0 0 0 .62.62l2.87-.837a2 2 0 0 0 .854-.506z" />
            <path d="M8 18h1" />
          </svg>
          {!updateFieldReplay && (
            <svg
              onClick={() => {
                setActiveDelete(true);
                setFieldId(replay?.id);
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-trash-2"
            >
              <path d="M3 6h18" />
              <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
              <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
              <line x1="10" x2="10" y1="11" y2="17" />
              <line x1="14" x2="14" y1="11" y2="17" />
            </svg>
          )}
          {updateFieldReplay && (
            <svg onClick={() => setUpdateFieldReplay(false)} className="delete-comment" width="15" height="15" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.3047 12.5938C13.0703 12.8281 12.6406 12.8281 12.4062 12.5938L7.25 7.39844L2.05469 12.5938C1.82031 12.8281 1.39062 12.8281 1.15625 12.5938C0.921875 12.3594 0.921875 11.9297 1.15625 11.6953L6.35156 6.5L1.15625 1.34375C0.921875 1.10938 0.921875 0.679688 1.15625 0.445312C1.39062 0.210938 1.82031 0.210938 2.05469 0.445312L7.25 5.64062L12.4062 0.445312C12.6406 0.210938 13.0703 0.210938 13.3047 0.445312C13.5391 0.679688 13.5391 1.10938 13.3047 1.34375L8.10938 6.5L13.3047 11.6953C13.5391 11.9297 13.5391 12.3594 13.3047 12.5938Z"
                fill="black"
              ></path>
            </svg>
          )}
        </div>
        <div className="comments-text">
          <div className="comments-text-top">
            <h4 className="comment-author">{replay?.user?.first_name}</h4>
            <div className="comments-replies-date">{getTime(replay.created_at)}</div>
          </div>
          {updateFieldReplay && replayUpdateId === replay.id ? (
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
              {(formik) => (
                <Form>
                  <div className="add-comment">
                    <FieldWrapper inputName="body" inputError={formik.errors.body as string} inputTouched={formik.touched.body as boolean} textArea customPadding maxLength={150} />
                    <Button
                      text={sendIcon}
                      customClass="icon-only"
                      commentBtn
                      loading={updateReplayMutation.isPending}
                      onClick={() => {
                        formik.submitForm();
                      }}
                      type="submit"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <p className="comment-body">{replay.body}</p>
          )}
        </div>
      </div>
    </div>
  );
}
