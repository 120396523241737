import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useEffect, useRef, useState } from 'react';

export default function PriceSlider({ header, min, max, label, minDistance, name, formik, reset }:
    {
        header: string,
        min: number,
        max: number,
        label: string,
        minDistance: number,
        name: string,
        formik: any
        reset: boolean
    }
) {
    function valuetext(value: number) {
        return `${label === "SAR" ? value.toLocaleString('en-US') : value}`;
    }

    const [value, setValue] = useState<number[]>([]);
    const sliderRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setValue([min, max])
    }, [min, max])

    useEffect(() => {
        if (reset) { setValue([min, max]) }
    }, [reset, min, max])

    const handleChange = (
        event: Event,
        newValue: number | number[],
        activeThumb: number,
    ) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
        } else {
            setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
        }
    };

    useEffect(() => {
        formik.setFieldValue(`${name}[min]`, value[0]);
        formik.setFieldValue(`${name}[max]`, value[1]);
    }, [value])

    return (
        min != max ?
            <Box sx={{ padding: "1rem 0 2.5rem", borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
                <span className='range-header'>{header}</span>
                <Box sx={{ width: 220, paddingTop: "2rem", margin: "auto" }} ref={sliderRef}>
                    <Slider
                        getAriaLabel={() => 'Minimum distance'}
                        value={value}
                        onChange={handleChange}
                        valueLabelDisplay="on"
                        getAriaValueText={valuetext}
                        valueLabelFormat={valuetext}
                        disableSwap
                        min={min}
                        max={max}
                        sx={{

                            '& .MuiSlider-thumb': {
                                width: 12,
                                height: 12,
                                backgroundColor: '#000',
                                '&:hover': {
                                    backgroundColor: '#000',
                                    boxShadow: 'none',
                                },
                                '&.Mui-active': {
                                    backgroundColor: '#000',
                                },
                            },
                            '& .MuiSlider-track': {
                                color: "#000",
                                backgroundColor: '#000'
                            },
                            '& .MuiSlider-thumb[data-index="0"] .MuiSlider-valueLabel': {
                                top: 1,
                                transform: 'translateY(-100%)',
                                background: "transparent",
                                color: "#000",
                                fontSize: ".7rem"
                            },
                            '& .MuiSlider-thumb[data-index="1"] .MuiSlider-valueLabel': {
                                top: -10,
                                transform: 'translateY(100%)',
                                background: "transparent",
                                color: "#000",
                                fontSize: ".7rem",
                                '&:before': { display: 'none' },
                            },
                        }}
                    />
                </Box>
            </Box> :
            <></>
    );
}
