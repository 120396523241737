import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalContainer from '../../../components/ModalContainer';
import DeleteModal from '../../../components/DeleteModal';
import { useTranslation } from 'react-i18next';
import { API_FILE_URL } from '../../../config/APIs';
import { useMediaQuery } from 'react-responsive';
import { editIcon } from '../../../config/variables';
import { hasPermission } from '../../../utils/HelperFunctions';

const DistributorTableContainer = ({ tableHeaders, data, noDataMessage, setRefetchData, lessColumns }: any) => {
    const navigate = useNavigate()
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const { t, i18n } = useTranslation()
    const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });

    return (
        <div className={`table_container ${lessColumns && isDesktop && "lessColumns"} hasPagination`}>
            <div className="table_header">
                {tableHeaders?.map((header: any, index: number) => (
                    <span className={`head ${header.customClass}`} key={index}>{header.label}</span>
                ))}
            </div>
            <div className={`table_data ${(!data || data?.length == 0) && "no_data"}`}>
                {data?.length > 0 ?
                    <>
                        {data?.map((item: any, index: number) => (
                            <div className="item clickable has_logo" key={index} onClick={() => {
                                const contentContainer = document.querySelector(".layout_content");
                                contentContainer?.scrollTo({ top: 0, behavior: "smooth" });
                                navigate(`/distributors/${item.id}`)
                            }}>
                                <div className="column logo">
                                    <div className='logo-container'>
                                        {item?.logo && <img src={`${API_FILE_URL}/${item?.logo.image_path}${item?.logo.name}`} alt="" />}
                                    </div>
                                    <div className="capitalize">{item?.distributorName || "-"}</div>
                                </div>
                                <div className="column" >{item?.address ? item?.address.city + ", " + item?.address.street : "-"}</div>
                                <div className="column" >{item?.phone || "-"}</div>
                                <div className="column status_col capitalize"><div className={`${item?.is_active ? "active" : ""}`}>{`${item?.is_active ? t("active") : t("inactive")}`}</div></div>
                                {hasPermission(['distributors.edit']) && <div className="column actions actions_col">
                                    <div className="action_btn edit" onClick={(e) => {
                                        const contentContainer = document.querySelector(".layout_content");
                                        contentContainer?.scrollTo({ top: 0, behavior: "smooth" }); e.stopPropagation(); navigate(`/distributors/create-distributor/${item.id}`)
                                    }}>
                                        {editIcon}
                                    </div>
                                </div>
                                }
                            </div>
                        ))}
                    </>
                    :
                    <h6>{noDataMessage}</h6>
                }
            </div>
            {/* {deleteModalOpen && (
                <ModalContainer small>
                    <DeleteModal id={deleteModalOpen} setModal={setDeleteModalOpen} setRefetchData={setRefetchData} route={"/service-providers"} successMsg={t("deletedServiceProvider")} warningMsg={t("deleteConfirmationMessageServiceProvider")} />
                </ModalContainer>
            )} */}
        </div>
    );
}

export default DistributorTableContainer;