import { Dispatch } from "react";
import { TOptions } from "../../../types/types";

const DurationTabs = ({ activeTab, setActiveTab, tabs, setOpenModal, duration, setDateRange, t }: {
    tabs: TOptions[],
    activeTab: string,
    setActiveTab: Dispatch<React.SetStateAction<string>>,
    setOpenModal?: Dispatch<React.SetStateAction<boolean>>,
    duration?: boolean,
    setDateRange?: any,
    t?: any
}) => {

    return (
        <ul className="duration-tabs">
            {tabs?.map((item: TOptions, index) => (
                <li onClick={() => {
                    if (index == 3 && duration) {
                        setOpenModal && setOpenModal(true)
                    } else {
                        setActiveTab(item.value!)
                        setDateRange(null)
                    }
                }} key={item.value} className={`${activeTab === item?.value ? "active" : ""}`}>{t ? t(`dashboardCharts.${item.label}`) : item.label}</li>
            ))}
        </ul>
    );
}

export default DurationTabs;