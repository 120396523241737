import { Dispatch, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseButton from '../../../../components/buttons/CloseButton';
import { useDispatch, useSelector } from 'react-redux';
import PriceSlider from './PriceSlider';
import { Form, Formik } from 'formik';
import FilterCheckBox from './FilterCheckBox';
import Button from '../../../../components/buttons/Button';
import { generalGet } from '../../../../API/api';
import { setSearchResult, setSelectedProducts } from '../../store/redux';
import { toast } from 'react-toastify';

const FilterModal = ({ open, setOpen }: {
    open: boolean,
    setOpen: Dispatch<React.SetStateAction<boolean>>
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [reset, setReset] = useState(false)
    const el = useRef<HTMLDivElement>(null);
    const { filterAvg, searchData } = useSelector((store: any) => store.findDeal)
    const handleOutsideClick = (e: MouseEvent) => {
        if (!loading && el.current && !el.current.contains(e.target as Node)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleForm = async (values: any) => {
        setLoading(true)
        const data = Object.entries(values.filters)
            .flatMap(([key, value]) => {
                if (value && typeof value === "object") {
                    return Object.entries(value).map(([innerKey, innerValue]) => `filters[${key}][${innerKey}]=${innerValue}`);
                } else {
                    return `filters[${key}]=${value}`;
                }
            })
            .join('&');
        const search = new URLSearchParams(searchData as {}).toString()

        generalGet(`/search/products?${search}&${data}`)
            .then(res => {
                const results: any = []
                res.data.data.products.map((item: any) => {
                    item.yearly_profit_rates.map((rate: any, index: number) => {
                        results.push({
                            ...item, yearly_profit_rates: null, ...rate, duration_to: index + 1,
                            total_price: item.total_price[index],
                            monthly_installments: item.total_price.monthly_installments[index],
                        })
                    })

                })
                dispatch(setSearchResult(results))
                dispatch(setSelectedProducts([]))
                setLoading(false)
                setOpen(false)
            })
            .catch(error => {
                toast.error(t("somethingWrong"))
                setLoading(false)
            })
    };

    const initialValues = {
        filters: {
            down_payment_range: {
                min: filterAvg?.down_payment_range.min,
                max: filterAvg?.down_payment_range.max
            },
            balloon_payment: {
                min: filterAvg?.balloon_payment_range.min,
                max: filterAvg?.balloon_payment_range.max
            },
            total_price_range: {
                min: filterAvg?.total_price_range.min,
                max: filterAvg?.total_price_range.max
            },
            monthly_installments_range: {
                min: filterAvg?.monthly_installments_range.min,
                max: filterAvg?.monthly_installments_range.max
            },
            admin_fee: [],
            insurance_fee: []

        },
    }

    return (
        <div className={`comments-side ${open ? "open" : ""}`} ref={el}>
            <div className="comments-side-header">
                <h4>{t("filter")}</h4>
                <CloseButton handleClose={() => { !loading && setOpen(false) }} />
            </div>
            <Formik
                enableReinitialize
                validateOnMount
                initialValues={initialValues}
                onSubmit={(values) => handleForm(values)}
            >

                {(formik) => (
                    <Form>
                        <div className="comments-side-categories">
                            <PriceSlider
                                label='SAR'
                                min={filterAvg?.total_price_range.min}
                                max={filterAvg?.total_price_range.max}
                                header={`${t('total_amount')} (${t('sar')})`}
                                minDistance={100}
                                key={`cost-${filterAvg?.total_price_range.max + filterAvg?.total_price_range.min}`}
                                name="filters.total_price_range"
                                formik={formik}
                                reset={reset}
                            />
                            <PriceSlider
                                label='SAR'
                                min={filterAvg?.monthly_installments_range.min}
                                max={filterAvg?.monthly_installments_range.max}
                                header={`${t('monthly_install')} (${t('sar')})`}
                                minDistance={100}
                                key={`monthly-${filterAvg?.monthly_installments_range.max + filterAvg?.monthly_installments_range.min}`}
                                name="filters.monthly_installments_range"
                                formik={formik}
                                reset={reset}
                            />
                            <PriceSlider
                                label='%'
                                min={filterAvg?.down_payment_range.min}
                                max={filterAvg?.down_payment_range.max}
                                header={`${t('down_payment')} %`}
                                minDistance={2}
                                key={`down-${filterAvg?.down_payment_range.max + filterAvg?.down_payment_range.min}`}
                                name="filters.down_payment_range"
                                formik={formik}
                                reset={reset}
                            />
                            <PriceSlider
                                label='%'
                                min={filterAvg?.balloon_payment_range.min}
                                max={filterAvg?.balloon_payment_range.max}
                                header='Ballon payment %'
                                minDistance={5}
                                key={`ballon-${filterAvg?.balloon_payment_range.max + filterAvg?.balloon_payment_range.min}`}
                                name="filters.balloon_payment"
                                formik={formik}
                                reset={reset}
                            />
                            <FilterCheckBox
                                filterAvg={filterAvg}
                                objKey="admin_fee"
                                formik={formik}
                                header={t('admin_fee')}
                            />
                            <FilterCheckBox
                                filterAvg={filterAvg}
                                objKey="insurance_fee"
                                formik={formik}
                                header={t('insurance_fee')}
                            />
                            <div className="form_button">
                                <span onClick={() => {
                                    formik.resetForm()
                                    setReset(true)
                                    const timeOut = setTimeout(() => { setReset(false) }, 100)
                                    return () => clearTimeout(timeOut)
                                }} className='clear'>Clear</span>
                                <Button
                                    text={t('submit')}
                                    type={'submit'}
                                    disabled={loading}
                                    loading={loading}
                                ></Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

        </div>
    );
}
export default FilterModal
