import { useDispatch } from 'react-redux';
import { setBreadCrumbsData } from '../../../store/redux/breadCrumbsData';
import { useTranslation } from 'react-i18next';
import FormSteps from '../../../components/FormSteps';
import { useContext, useEffect, useState } from 'react';
import SearchForDeal from '../../../modules/findDeal/components/SearchForDealStep/SearchForDeal';
import SearchResults from '../../../modules/findDeal/components/SearchForDealStep/SearchResults';
import CustomerInfoForm from '../../../modules/findDeal/components/SearchForDealStep/CustomerInfoForm';
import { useQuery } from '@tanstack/react-query';
import { generalGet } from '../../../API/api';
import { authContext } from '../../../store/context/authContext';
import { TOptions } from '../../../types/types';
import { IDistributor } from '../../../modules/distributors/types/interfaces';
import { resetFormData, setCount, setSearchData, setSearchResult, setSelectedProducts } from '../../../modules/findDeal/store/redux';

const FindDeal = () => {
  const { catchError } = useContext(authContext);
  const { t, i18n } = useTranslation();
  const [activeStep, setActiveStep] = useState<number>(0);
  const steps = [t('search_for_deals'), t('financial_products'), t('customers_info')];
  const dispatch = useDispatch();

  dispatch(
    setBreadCrumbsData({
      page_title: t('find_deal')
    })
  );

  const [distributors, setDistributors] = useState<TOptions[]>([]);
  const { data, isSuccess, isLoading, error } = useQuery({
    queryKey: ['distributors', i18n],
    queryFn: () => generalGet('/distributors?limit=50'),
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    error && catchError(error);
  }, [error]);

  useEffect(() => {
    const reqData = data?.data?.data;
    isSuccess && setDistributors(reqData?.map((item: IDistributor) => ({ value: item.id, label: item.name.en })));
  }, [isSuccess, data]);

  useEffect(() => {
    return () => {
      dispatch(setSearchData({}));
      dispatch(setSearchResult([]));
      dispatch(setSelectedProducts([]));
      dispatch(resetFormData({}));
      dispatch(setCount(null));
    };
  }, []);

  return (
    <div className="page_content">
      <div className="form-steps-content">
        <FormSteps steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
        <div className={`form ${activeStep == 2 && 'customer'}`}>
          {activeStep == 0 && <SearchForDeal setActiveStep={setActiveStep} />}
          {activeStep == 1 && <SearchResults setActiveStep={setActiveStep} />}
          {activeStep == 2 && <CustomerInfoForm setActiveStep={setActiveStep} distributors={distributors} />}
        </div>
      </div>
    </div>
  );
};

export default FindDeal;
