import { Form, Formik } from 'formik';
import { IStepData } from '../types/interfaces';
import SingleEntity from './SingleEntity';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../components/buttons/Button';
import { buildFormData, scrollToError } from '../../../utils/HelperFunctions';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { generalCreate } from '../../../API/api';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { is } from 'date-fns/locale';

interface IProps {
  data: IStepData;
  activeStep: string;
  setActiveStep: React.Dispatch<React.SetStateAction<string>>;
  isCurrentStep: boolean;
  openModal: React.Dispatch<React.SetStateAction<boolean>>;
  nextStepId: string;
  appId: string;
  setUnauthorized: React.Dispatch<React.SetStateAction<boolean>>;
  isApplicationAccepted: boolean;
  setRefetchAfterSubmit: React.Dispatch<React.SetStateAction<Date | null>>
}

const SingleStep = ({ data, activeStep, setActiveStep, isCurrentStep, openModal, nextStepId, appId, setUnauthorized, isApplicationAccepted,setRefetchAfterSubmit }: IProps) => {
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [initialValues, setInitialValues] = useState<any>({});
  const navigate = useNavigate();
  const userRoles = localStorage.getItem('user_roles');
  const [canUpdateStatus, setCanUpdateStatus] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    if (userRoles && data) {
      const userRolesArray = JSON.parse(userRoles);
      if (isApplicationAccepted) {
        setCanUpdateStatus(false);
        setCanEdit(false);
      } else {
        setCanUpdateStatus(data?.roles?.some((role: any) => userRolesArray.includes(role.id)) && isCurrentStep);
        setCanEdit(data?.responsibilities?.some((resp: any) => userRolesArray.includes(resp.id)));
      }
    }
  }, [userRoles, data, isApplicationAccepted]);

  useEffect(() => {
    const entityObj: {
      entity: {
        entity_id: string;
        values: { key: string; value: string }[];
      }[];
    } = { entity: [] };

    const extractedData = data?.entities?.map((entity) => {
      const entity_id = entity?.id;
      const values = entity?.fields?.map((field) => {
        const key = field?.name;
        const value = field?.value || '';
        return { key, value };
      });
      entityObj.entity.push({ entity_id, values });
    });
    setInitialValues(entityObj);
  }, [data]);

  const validationSchema = Yup.object().shape({
    entity: Yup.array().of(
      Yup.object().shape({
        entity_id: Yup.string().required(t('required')),
        values: Yup.array().of(
          Yup.object().shape({
            key: Yup.string().required(t('required')),
            value: Yup.string().required(t('required'))
          })
        )
      })
    )
  });

  
  return (
    <div ref={formRef} style={{ scrollMarginTop: '12rem' }}>
      <Formik
        enableReinitialize
        validateOnMount
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values) => {
          setLoading(true);
          let value = { ...values, _method: 'POST' };
          let route = `/application-products/${id}/entities`;
          const formData = new FormData();
          buildFormData(formData, values, '');
          generalCreate({ route, values: formData })
          
          .then((res) => {
              console.log(values, "stepFormValues");
              setLoading(false);
              toast.success(`${t('step')} ${t('updated')} ${t('successfully')}`);
              // navigate(`/applications/${appId}`);
              setRefetchAfterSubmit(new Date());
            })
            .catch((error) => {
              const obj = error?.response?.data?.data;
              const errType = typeof obj;
              if (errType === 'object') {
                Object?.keys(obj)?.map((item: any, index: number) => toast.error(obj[item][0]));
              } else {
                toast.error(obj);
              }
              setLoading(false);
            });
        }}
      >
        {(formik) => (
          <>
            <Form>
              <div className="" style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '2rem' }}>

              {(!canEdit || !isCurrentStep) && <div className="" style={{ color: isApplicationAccepted ? 'green' : 'red' }}><p>{isApplicationAccepted ? t('applicationAccepted') : t('unAuthorizedEdit')}</p></div>}
              {canUpdateStatus && (
                <div className="updateStep">
                  <Button
                    onClick={() => {
                      openModal(true);
                    }}
                    text={`${t('updateStep')}`}
                    type={'button'}
                  />
                </div>
              )}
              </div>
              <div>
                {data?.entities?.map((item: any, index: number) => (
                  <SingleEntity data={item} key={index} formik={formik} index={index} isCurrentStep={isCurrentStep} canEdit={canEdit} setUnauthorized={setUnauthorized} />
                ))}
              </div>
              <div className={`form_button reverse ${isCurrentStep && isApplicationAccepted ? 'hidden' : ''}`}>
                <Button
                  loading={loading}
                  disabled={loading || !formik.isValid || (!canEdit && isCurrentStep) || (formik.values === initialValues)}
                  onClick={() => {
                    if (isCurrentStep) {
                      scrollToError(!formik.isValid, formRef);
                    } else {
                      setActiveStep(nextStepId);
                    }
                  }}
                  text={`${isCurrentStep ? t('submit') : t('next')}`}
                  type={isCurrentStep ? 'submit' : 'button'}
                />
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default SingleStep;
