import { useContext, useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import 'react-phone-number-input/style.css';
import { toast } from 'react-toastify';
import FieldWrapper from '../../../components/formInputs/FieldWrapper';
import Button from '../../../components/buttons/Button';
import { generalCreate, generalGet } from '../../../API/api';
import { authContext } from '../../../store/context/authContext';
import { useTranslation } from 'react-i18next';
import { ISales } from '../types/interfaces';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import FormSkeleton from '../../../components/loaders/FormSkeleton';
import SectionHeader from '../../../components/SectionHeader';
import { scrollToError } from '../../../utils/HelperFunctions';

const CreateSalesForm = () => {
  const [loading, setLoading] = useState(false);
  const [loadingVerification, setLoadingVerification] = useState(false);
  const [userData, setUserData] = useState<ISales | any>();
  const { id } = useParams();
  const formRef = useRef(null);
  const { catchError } = useContext(authContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleResendVerification = async () => {
    setLoadingVerification(true);
    generalCreate({
      route: `/resend-verification/${userData?.email}`
    })
      .then((res) => {
        setLoadingVerification(false);
        toast.success(res?.data?.message);
      })
      .catch((error) => {
        setLoadingVerification(false);
        catchError(error, setLoading);
      });
  };

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .required(t('required'))
      .matches(/^[^ ]\S*/, t('remove_space')),
    last_name: Yup.string()
      .required(t('required'))
      .matches(/^[^ ]\S*/, t('remove_space')),
    street: Yup.string()
      .required(t('required'))
      .matches(/^[^ ]\S*/, t('remove_space')),
    city: Yup.string()
      .required(t('required'))
      .matches(/^[^ ]\S*/, t('remove_space')),
    email: Yup.string()
      .email(t('email_format'))
      .required(t('required'))
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t('email_format')),
    mobile: Yup.string()
      .required(t('required'))
      .matches(/^05[0-9]{8}$/, t('wrong_number'))
    // ...(id == null ? {
    //   password: Yup.string()
    //     .required(t("required"))
    //     .matches(/^[^ ]\S*/, t("remove_space"))
    //     .min(8, t("password_must")),
    //   password_confirmation: Yup.string()
    //     .required(t("required"))
    //     .oneOf([Yup.ref("password")], t("passwords_mismatch")),
    // } : {}),
  });

  const { data, isSuccess, isLoading, error } = useQuery({
    queryKey: ['sales data', id],
    queryFn: () => generalGet(`/admin/users/${id}?role_name=mynm-sales`),
    enabled: !!id
  });

  useEffect(() => {
    const reqData = data?.data.data;
    if (isSuccess) {
      setUserData(reqData);
    }
  }, [isSuccess, data, id]);

  useEffect(() => {
    if (error) {
      catchError(error);
    }
  }, [error]);

  useEffect(() => {
    !id && setUserData(null);
  }, [id]);

  if (isLoading) return <FormSkeleton featuredSections={1} offer />;

  return (
    <div className={'form_section'} ref={formRef}>
      <Formik
        enableReinitialize
        validateOnMount
        validationSchema={validationSchema}
        initialValues={{
          first_name: userData?.first_name || '',
          last_name: userData?.last_name || '',
          email: userData?.email || '',
          mobile: userData?.mobile || '',
          password: '',
          password_confirmation: '',
          city: userData?.address?.city || '',
          street: userData?.address?.street || '',
          role_name: userData?.role_name || 'mynm-sales'
        }}
        onSubmit={(values) => {
          setLoading(true);
          let createValues = { ...values, mobile: values?.mobile.toString() };
          if (id && values.password === '') {
            delete (values as Partial<ISales>).password;
            delete (values as Partial<ISales>).password_confirmation;
          }
          let updateValues = { ...values, mobile: values?.mobile.toString(), _method: 'PUT' };
          let route = !id ? `/admin/users/sales/store` : `/admin/users/sales/${id}/update`;
          generalCreate({
            route: route,
            values: id ? { ...updateValues } : { ...createValues }
          })
            .then((res) => {
              setLoading(false);
              setUserData(null);
              toast.success(`${!id ? t('created') : t('updated')} ${t('successfully')}`);
              navigate('/sales');
            })
            .catch((error) => {
              catchError(error, setLoading);
            });
        }}
      >
        {(formik) => (
          <>
            <Form>
              <div className="form_inputs">
                <SectionHeader customStyle="last" title={t('SalesExecutiveDetails')}>
                  <div className="inputs_group">
                    <FieldWrapper
                      title={t('first_name')}
                      inputName={'first_name'}
                      inputPlaceholder={t('first_name')}
                      inputError={formik.errors.first_name as string}
                      inputTouched={formik.touched.first_name as boolean}
                      input
                    />
                    <FieldWrapper
                      title={t('last_name')}
                      inputName={'last_name'}
                      inputPlaceholder={t('last_name')}
                      inputError={formik.errors.last_name as string}
                      inputTouched={formik.touched.last_name as boolean}
                      input
                    />
                  </div>
                  <div className="inputs_group">
                    <FieldWrapper
                      title={t('email')}
                      inputName={'email'}
                      inputPlaceholder={t('email')}
                      inputError={formik.errors.email as string}
                      inputTouched={formik.touched.email as boolean}
                      input
                    />
                    <FieldWrapper
                      title={t('phone')}
                      inputName={'mobile'}
                      inputPlaceholder={`${t('ex')}: 0511111111`}
                      inputError={formik.errors.mobile as string}
                      inputTouched={formik.touched.mobile as boolean}
                      input
                    />
                  </div>
                  {/* <div className="inputs_group">
                    <FieldWrapper
                      title={t("password")}
                      inputName={"password"}
                      inputPlaceholder={t("password")}
                      inputError={formik.errors.password as string}
                      inputTouched={formik.touched.password as boolean}
                      type="password"
                      input
                      showPassword
                      desc={t("password_schema")}
                      tooltip
                      tooltipText={
                        <ul>
                          <li>{t("password_must")}.</li>
                          <li>{t("must_contain_uppercase")}.</li>
                          <li>{t("must_contain_lowercase")}.</li>
                          <li>{t("must_contain_number")}.</li>
                          <li>{t("must_contain_special_char")}.</li>
                        </ul>
                      }
                    />
                    <FieldWrapper
                      title={t("confirmPassword")}
                      inputName={"password_confirmation"}
                      inputPlaceholder={t("confirmPassword")}
                      inputError={formik.errors.password_confirmation as string}
                      inputTouched={formik.touched.password_confirmation as boolean}
                      type="password"
                      input
                      desc={t("confirm_password_must_match")}
                      showPassword
                    />
                  </div> */}
                  <div className="inputs_group">
                    <FieldWrapper
                      title={t('role')}
                      inputName={'role_name'}
                      // inputPlaceholder={userData?.role_name}
                      input
                      disabled
                    />
                    <FieldWrapper noPadding />
                    <FieldWrapper noPadding />
                  </div>
                  <div className="inputs_group">
                    <FieldWrapper
                      title={t('city')}
                      inputName={'city'}
                      inputPlaceholder={t('city')}
                      inputError={formik.errors.city as string}
                      inputTouched={formik.touched.city as boolean}
                      input
                      noPadding
                    />
                    <FieldWrapper
                      title={t('street')}
                      inputName={'street'}
                      inputPlaceholder={t('street')}
                      inputError={formik.errors.street as string}
                      inputTouched={formik.touched.street as boolean}
                      input
                      noPadding
                    />
                  </div>
                </SectionHeader>
              </div>
              <div className="form_button">
                <Button
                  type={loading ? 'button' : 'submit'}
                  loading={loading}
                  onClick={() => {
                    scrollToError(!formik.isValid, formRef);
                  }}
                >
                  <span className="bold">{t('save')}</span>
                </Button>
                {userData && !userData?.email_verified_at && (
                  <Button type={'button'} loading={loadingVerification} onClick={handleResendVerification}>
                    <span className="bold">{t('resendVerification')}</span>
                  </Button>
                )}
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default CreateSalesForm;
