import { Form, Formik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import Button from '../../../components/buttons/Button';
import { scrollToError } from '../../../utils/HelperFunctions';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { generalCreate, generalGet } from '../../../API/api';
import { toast } from 'react-toastify';
import { authContext } from '../../../store/context/authContext';
import { min } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import { IStepsData } from '../types/interfaces';
import * as Yup from 'yup';
import SectionHeader from '../../../components/SectionHeader';
import FieldWrapper from '../../../components/formInputs/FieldWrapper';
import StepInputs from './StepInputs';

const StepsCreationForm = () => {
  const formRef = useRef(null);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { catchError } = useContext(authContext);
  const [stepData, setStepData] = useState<IStepsData>();

  const { data, isSuccess, isLoading } = useQuery({
    queryKey: ['single step', id],
    queryFn: () => generalGet(`/application-products/step/${id}`),
    enabled: !!id
  });

  useEffect(() => {
    if (isSuccess) {
      const reqData = data?.data?.data;
      if (reqData) {
        const updatedRoles = reqData?.roles?.map((role: { name: string; id: string }) => role?.id);
        const updatedResponsibilities = reqData?.responsibilities?.map((role: { name: string; id: string }) => role?.id);
        reqData.roles = updatedRoles;
        reqData.responsibilities = updatedResponsibilities;

        setStepData(reqData);
      }
    }
  }, [isSuccess, data, id]);

  const initialValues = (id && stepData) || {
    name: {
      ar: '',
      en: ''
    },
    message: {
      ar: '',
      en: ''
    },
    order: '',
    entities: [
      {
        name: {
          ar: '',
          en: ''
        },
        fields: [
          {
            name: '',
            type: '',
            label: {
              ar: '',
              en: ''
            },
            validation: [] as string[],
            min: '',
            max: ''
          }
        ]
      }
    ],
    roles: [],
    responsibilities: []
  };
  const validationSchema = Yup.object().shape({
    name: Yup.object().shape({
      ar: Yup.string().required('Name in Arabic is required'),
      en: Yup.string().required('Name in English is required')
    }),
    message: Yup.object().shape({
      ar: Yup.string().required('Message in Arabic is required'),
      en: Yup.string().required('Message in English is required')
    }),
    order: Yup.string().required('Order is required'),

    entities: Yup.array().of(
      Yup.object().shape({
        name: Yup.object().shape({
          ar: Yup.string().required('Name in Arabic is required'),
          en: Yup.string().required('Name in English is required')
        }),
        fields: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required('Field name is required'),
            type: Yup.string().required('Field type is required'),
            label: Yup.object().shape({
              ar: Yup.string().required('Label in Arabic is required'),
              en: Yup.string().required('Label in English is required')
            }),
            validation: Yup.array().of(Yup.string())
          })
        )
      })
    ),
    roles: Yup.array().of(Yup.string()).min(1, 'At least one role is required'),
    responsibilities: Yup.array().of(Yup.string()).min(1, 'At least one responsibility is required')
  });

  return (
    <div className="basic_info creation_step" ref={formRef}>
      <Formik
        enableReinitialize
        validateOnMount
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values) => {
          values.entities.forEach((entity) => {
            entity.fields.forEach((field) => {
              const filteredArr = field?.validation?.filter((item: string) => !item.includes('min:') && !item.includes('max:'));
              field.validation = filteredArr;
              field.min && field.min != null && field.validation.push(`min:${field.min}`);
              field.max && field.max != null && field.validation.push(`max:${field.max}`);
            });
          });

          setLoading(true);
          let value = { ...values, _method: 'POST' };
          if (id) {
            value = { ...value, _method: 'PUT' };
          }
          let route = !id ? `/application-products/step` : `/application-products/step/${id}`;
          generalCreate({
            route: route,
            values: { ...value }
          })
            .then((res) => {
              setLoading(false);
              toast.success(`${t('step')} ${!id ? t('created') : t('updated')} ${t('successfully')}`);
              navigate(`/steps`);
            })
            .catch((error: any) => {
              catchError(error, setLoading);
            });
        }}
      >
        {(formik) => (
          <>
            <Form>
              <SectionHeader title={t('stepInfo')} customStyle="noBorder noMargin">
                <FieldWrapper>
                  <div className={`input-wrapper start-times`}>
                    <StepInputs formik={formik} />
                  </div>
                </FieldWrapper>
              </SectionHeader>

              <div className="form_button reverse">
                <Button
                  onClick={() => {
                    scrollToError(!formik.isValid, formRef);
                  }}
                  text={t('submit')}
                  type={'submit'}
                  disabled={loading}
                  loading={loading}
                ></Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default StepsCreationForm;
