import React, { useState } from 'react';
import CloseButton from '../../../components/buttons/CloseButton';
import { ICommentsCategory } from '../types/interfaces';
import ApplicationCommentsItem from './ApplicationCommentsItem';
import ApplicationAddComment from './ApplicationAddComment';
import { useTranslation } from 'react-i18next';
import CommentSkeleton from '../../../components/loaders/CommentSkeleton';
import RemoveCommentAndReplayPopup from './RemoveCommentAndReplayPopup';

export default function ApplicationCommentsSidebar({
  sideRef,
  comments,
  closeCommentsSide,
  entity_name,
  skeleton_loader,
  commentEntityId
}: {
  sideRef: React.RefObject<HTMLDivElement>;
  comments: ICommentsCategory[];
  closeCommentsSide: () => void;
  entity_name: string;
  skeleton_loader: boolean;
  commentEntityId: number;
}) {
  const { t } = useTranslation();
  const [activeDelete, setActiveDelete] = useState<boolean>(false);
  const [FieldId, setFieldId] = useState<number>(0);

  return (
    <div className="comments-overlay">
      <RemoveCommentAndReplayPopup activeDelete={activeDelete} setActiveDelete={setActiveDelete} FieldId={FieldId} />
      <div className="comments-side" ref={sideRef}>
        <div className="comments-side-header">
          <h4>{t('comments')}</h4>
          <CloseButton handleClose={closeCommentsSide} />
        </div>
        <div className="comments-side-categories">
          <div key={`comment-category`} className="comment-category">
            <h4 className="comment-category-title">{entity_name}</h4>
            <ApplicationAddComment entity_id={commentEntityId} />
            <div className="comments-list">
              {comments?.length === 0 && (
                <div className="comment-empty">
                  <h4>{t('no_comments')}</h4>
                </div>
              )}
              {skeleton_loader ? (
                <CommentSkeleton count={comments?.length} />
              ) : (
                comments?.map((comment) => <ApplicationCommentsItem key={comment.id} comment={comment} setActiveDelete={setActiveDelete} setFieldId={setFieldId} />)
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
