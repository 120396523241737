import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalContainer from '../../../components/ModalContainer';
import DeleteModal from '../../../components/DeleteModal';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { editIcon } from '../../../config/variables';
import { hasPermission } from '../../../utils/HelperFunctions';

const ProductsTableContainer = ({ tableHeaders, data, noDataMessage, setRefetchData, service, lessColumns }: any) => {
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });

  return (
    <div className={`table_container ${lessColumns && isDesktop && 'lessColumns'} hasPagination`}>
      <div className="table_header">
        {tableHeaders?.map((header: any, index: number) => (
          <span className={`head ${header.customClass}`} key={index}>
            {header.label}
          </span>
        ))}
      </div>
      <div className={`table_data ${(!data || data?.length == 0) && 'no_data'}`}>
        {data?.length > 0 ? (
          <>
            {data?.map((item: any, index: number) => (
              <>
                <div
                  className="item clickable has_logo"
                  key={index}
                  onClick={() => {
                    const contentContainer = document.querySelector('.layout_content');
                    contentContainer?.scrollTo({ top: 0, behavior: 'smooth' });
                    navigate(`/products/${item.id}`);
                  }}
                >
                  {/* <div className="column pro_id_col">{item?.id}</div>
                                 <div className="column logo pro_logo_col" style={{marginInlineStart: "-0.5rem", marginInlineEnd: "0.5rem"}}>
                                     <div>
                                         {item?.service_provider?.logo_url && <img src={item?.logo_url} alt="" />}
                                     </div>
                                 </div> */}
                  {!service && <div className="column capitalize text-center">{item?.service_provider?.name[i18n.language] || '-'}</div>}
                  <div className="column registration_date_col text-center">
                    {item?.duration_to ? t('upTo') + ' ' + item?.duration_to + ' ' + (item?.duration_to > 1 ? t('years') : t('year')) : '-'}
                  </div>
                  <div className="column registration_date_col text-center profit">
                    {item?.profit_rate?.length > 0
                      ? item?.profit_rate?.map((rate: number, index: number) => `${index + 1}= ${rate}%${index === item?.profit_rate?.length - 1 ? '' : ','} `)
                      : '-'}
                  </div>
                  <div className="column registration_date_col text-center">{Math.trunc(item?.admin_fee)}%</div>
                  {/* <div className="column status_col capitalize"><div className={`${item?.is_active ? "active" : ""}`}>{`${item?.is_active ? t("active"): t("inactive")}`}</div></div> */}
                  {hasPermission(['products.edit']) && (
                    <div className="column actions actions_col ">
                      <div
                        className="action_btn edit"
                        onClick={(e) => {
                          const contentContainer = document.querySelector('.layout_content');
                          contentContainer?.scrollTo({ top: 0, behavior: 'smooth' });
                          e.stopPropagation();
                          navigate(`/products/create-product/${item.id}`);
                        }}
                      >
                        {editIcon}{' '}
                      </div>
                      <div
                        className="action_btn delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteModalOpen(item.id);
                        }}
                      >
                        <svg
                          width="18"
                          height="20"
                          clipRule="evenodd"
                          fillRule="evenodd"
                          strokeLinejoin="round"
                          strokeMiterlimit="2"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill="currentColor"
                            d="m4.015 5.494h-.253c-.413 0-.747-.335-.747-.747s.334-.747.747-.747h5.253v-1c0-.535.474-1 1-1h4c.526 0 1 .465 1 1v1h5.254c.412 0 .746.335.746.747s-.334.747-.746.747h-.254v15.435c0 .591-.448 1.071-1 1.071-2.873 0-11.127 0-14 0-.552 0-1-.48-1-1.071zm14.5 0h-13v15.006h13zm-4.25 2.506c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm-4.5 0c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm3.75-4v-.5h-3v.5z"
                            fillRule="nonzero"
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ))}
          </>
        ) : (
          <h6>{noDataMessage}</h6>
        )}
      </div>
      {deleteModalOpen && (
        <ModalContainer small>
          <DeleteModal
            id={deleteModalOpen}
            setModal={setDeleteModalOpen}
            setRefetchData={setRefetchData}
            route={'/admin/products'}
            successMsg={t('deletedProductSuccess')}
            warningMsg={t('deletedProduct')}
          />
        </ModalContainer>
      )}
    </div>
  );
};

export default ProductsTableContainer;
