import { IServiceInfo } from "../../serviceProvider/types/interfaces";

const TopData = ({ header, data, setSelectedProvider, selectedProvider }: any) => {

    return (
        <div className={`top-data ${setSelectedProvider ? "provider" : ""}`}>
            <h4>{header}</h4>
            <div className="cards">

                {data?.map((item: IServiceInfo, index: number) => (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            if (setSelectedProvider) setSelectedProvider(item)
                        }}
                        className={`card ${selectedProvider?.id == item.id ? "active" : ""}`} key={index}>
                        <h6>{item.title || item.name}</h6>
                        <p>{item.number || item.count}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TopData;