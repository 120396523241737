import { ReactNode } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

function ChartDirection({ children }: { children: ReactNode }) {

    const { i18n } = useTranslation()
    const theme = createTheme({
        direction: i18n.language == "ar" ? 'rtl' : "ltr"
    });

    return (
        <ThemeProvider theme={theme}>
            <div dir={theme.direction}>
                {children}
            </div>
        </ThemeProvider>
    );
}

export default ChartDirection;
