import { useEffect, useState, useTransition } from 'react';
import TableSkeleton from '../../../components/loaders/TableSkeleton';
import { generalGet } from '../../../API/api';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setBreadCrumbsData } from '../../../store/redux/breadCrumbsData';
import { useQuery } from '@tanstack/react-query';
import { IStepsData } from '../../../modules/steps/types/interfaces';
import ListHeader from '../../../components/ListHeader';
import StepsTable from '../../../modules/steps/components/StepsTable';

const Steps = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  dispatch(
    setBreadCrumbsData({
      links: [{ label: t('steps'), path: '/steps' }],
      page_title: t('steps')
    })
  );
  const [isPending, startTransition] = useTransition();

  const [refetch, setRefetch] = useState(false);
  const [steps, setSteps] = useState<IStepsData[]>([]);
  const { data, isSuccess, isLoading } = useQuery({
    queryKey: ['steps', refetch],
    queryFn: () => generalGet('/application-products/step'),
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    const reqData = data?.data.data;
    isSuccess && setSteps(reqData);
  }, [isSuccess, data]);

  const tableHeaders = [
    { label: t('id'), customClass: 'text-center' },
    { label: t('name'), customClass: 'text-center' },
    { label: t('order'), customClass: 'text-center' },
    { label: t('actions'), customClass: 'text-center' }
  ];

  if (isLoading) return <TableSkeleton columns={6} withoutButton />;
  return (
    <div className="services-page-container">
      <StepsTable tableHeaders={tableHeaders} data={steps} noDataMessage={t('No_products_found')} setRefetchData={setRefetch} lessColumns />
    </div>
  );
};

export default Steps;
