import { useTranslation } from 'react-i18next';

const WelcomePage = () => {
  const { t } = useTranslation();
  return <div className='text-container'>
    <div className='card'>
      <h1>
        {t('welcome')}
      </h1>
    </div>
  </div>;
};

export default WelcomePage;
